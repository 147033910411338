import React from "react"

import '../../css/Header.css';
import * as DataActions from "../actions/DataActions"
import DataStore from "../stores/DataStore";
import buttonClose from '../../gfx/button_close.png';

var Constants = require('../Constants')

export default class Message extends React.Component{

    constructor(){
        super();
       
        this.state = { message: DataStore.getMessage() }
       
     
    }
    


   onCloseClick(e){
    DataActions.setMessage(null);

    }

    componentDidMount() {
    
        DataStore.on(Constants.MESSAGE_CHANGED, () => { this.setState({message: DataStore.getMessage() })})
        
    }

    render(){
        
        
        return (

            
            <div className={ this.state.message ? "message messageVisible" : "message messageHidden" }  onClick={this.onCloseClick.bind(this)}>
                <div className='close'>
                    <img src={buttonClose} className='closeButton'  />
                </div>
                
                <h2> {this.state.message}</h2>
                
                
              
               
            </div>

        )
    }
}