import React from "react"
import DataStore from "../stores/DataStore";
import '../../css/Help.css';


var Constants = require('../Constants')

export default class Help extends React.Component{

    constructor(){
        super();
        this.state = { 
            startModel: DataStore.getStartModel(),
        }
    }

    componentDidMount() {
    
        DataStore.on(Constants.START_MODEL_CHANGED, () => { 
            this.setState({startModel: DataStore.getStartModel() })

        })

        
    }

    render(){
       return (
       <div>
        { this.state.startModel ? 
       <div className='help' dangerouslySetInnerHTML={{__html: this.state.startModel.boove.manual}}></div> : null }
       
       </div>
       )
    }
}