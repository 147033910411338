import React, { useState, useEffect } from 'react'


//import styled from 'styled-components'

import placeHolder from '../../gfx/missing.jpg'; 

//const placeHolder = "https://i.picsum.photos/id/700/400/600.jpg"

export const ProductImage = ({ src, alt, className, css }) => {
  const [imageSrc, setImageSrc] = useState(placeHolder)
  const [imageRef, setImageRef] = useState()
  //const [imageCss, setImageCss] = useState()

  useEffect(() => {
    let observer
    let didCancel = false

    //alert(imageRef);

    if (imageRef && imageSrc === placeHolder) {

        try{
            if (IntersectionObserver) {
                observer = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {

                    // when image is visible in the viewport + rootMargin
                    if ( !didCancel && (entry.intersectionRatio > 0 && entry.isIntersecting) ) {
                        setImageSrc(src);
                    }
                    })
                },
                {
                    threshold: 0.01,
                    rootMargin: '75%',
                }
                )
                observer.observe(imageRef)
            } else {
                setImageSrc(src)
            }
        }catch(error){
            setImageSrc(src);
        }

    }
    return () => {
      didCancel = true
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef)
      }
    }
  })

  return  (<div className='imgbox' ><img ref={setImageRef} src={imageSrc} alt={alt} title={imageSrc} className={className} /></div>)

  /*return (<div className='imgbox' style={css} title={imageSrc} >
  <div>
      <ProductImage className='productImage' src={imageSrc} title={imageSrc}   />
  </div>
  
</div>)*/
}