import React from "react"

import ConnectedProduct from "./ConnectedProduct";
import DataStore from "../stores/DataStore";
import ProductExtraInformationMc from "./ProductExtraInformationMc";
import ProductExtraInformationCategory from "./ProductExtraInformationCategory";
import buttonRight from '../../gfx/button_right.png';
import buttonCopy from '../../gfx/icon-copy.png';


export default class ProductExtraInformation extends React.Component {

	constructor(props) {
		super(props);
		this.onCloseCallback = this.onCloseCallback.bind(this);
	}

	onCloseCallback() {
		if (this.props.onCloseCallback) {
			this.props.onCloseCallback();
		}
	}

	onCopyClick(e) {

		//this.setState({display: !this.state.display})
		//DataActions.selectBrand(this.props.brand);

		const productUrl = "https://www.boove.se/" + DataStore.getProductTypeString() + "/list/" + this.props.product.id

		navigator.clipboard.writeText(productUrl);
		alert("Addressen är kopierad!");
	}

	render() {

		const productUrl = "https://www.boove.se/" + DataStore.getProductTypeString() + "/list/" + this.props.product.id

		const css = { marginTop: "-5px" }

		const hasSpecification = this.props.product.spec && this.props.product.spec.length > 0;
		return (
			<div className='productExtraInformation'>

				{this.props.product.desc && this.props.product.desc.length > 0 ?
					<div className='description'>
						<h3>Beskrivning</h3>
						{this.props.product.desc}
					</div> : null}

				{hasSpecification || this.props.product.ean || this.props.product.weight ?
					<div className='specification'>
						<h3>Specifikation</h3>

						{hasSpecification ? <div className='html' dangerouslySetInnerHTML={{ __html: this.props.product.spec }}></div> : null}

						<table class="specification" style={hasSpecification ? css : null}>
							<tbody>


								{this.props.product.ean && this.props.product.ean.length > 0 ? <tr>
									<td class="col1">EAN</td>
									<td class="col2">{this.props.product.ean}</td>
								</tr> : null}

								{this.props.product.weight && this.props.product.weight.length > 0 ? <tr>
									<td class="col1">Vikt</td>
									<td class="col2">{this.props.product.weight}</td>
								</tr> : null}


								{this.props.product.color && this.props.product.color.length > 0 ? <tr>
									<td class="col1">Färg</td>
									<td class="col2">{this.props.product.color}</td>
								</tr> : null}
							</tbody>
						</table>

					</div> : null}


				{this.props.product.connectedProducts && this.props.product.connectedProducts.length > 0 ?
					<div className='connectedProductList'>
						<h3>Tillbehör</h3>
						{this.props.product.connectedProducts && this.props.product.connectedProducts.map((item, index) => (<ConnectedProduct connectedProduct={item} key={item.id} />))}
					</div> : null}

				{this.props.product.mcList && this.props.product.mcList.length > 0 ?
					<div className='mcList'>
						<h3>Passar till</h3>
						{this.props.product.mcList && this.props.product.mcList.map((item, index) => (<ProductExtraInformationMc mc={item} onCloseCallback={this.onCloseCallback} key={item.id} />))}
					</div> : null}

				{this.props.product.categoryList && this.props.product.categoryList.length > 0 ?
					<div className='categoryList'>
						<h3>Kategorier</h3>
						{this.props.product.categoryList && this.props.product.categoryList.map((item, index) => (<ProductExtraInformationCategory category={item} onCloseCallback={this.onCloseCallback} key={item.id} />))}
					</div> : null}

				{this.props.product.itemList && this.props.product.itemList.length > 0 ?
					<div className='itemList'>
						<h3>Filer och länkar</h3>
						{this.props.product.itemList && this.props.product.itemList.map((item, index) => (<div className='item'><a href={item.url} alt={item.url} target='_blank'><img src={buttonRight} />{item.urlLabel} </a></div>))}
					</div> : null}

				<div className='itemList'>
					<h3 class='header'>Direktlänk till produkten</h3> <img class='copy pointer' alt='Kopiera adressen' src={buttonCopy} onClick={this.onCopyClick.bind(this)} />
					<div className='item'>
						<a href={productUrl} alt={productUrl} target='_blank'><img src={buttonRight} /> </a>
						<a href={productUrl} alt={productUrl} target='_blank'>{productUrl} </a>
					</div>

				</div>
			</div>

		)
	}
}