import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";


export default class McListYear extends React.Component{

    constructor(){
        super();
    }

    onClick(event){
        //alert("Year clicked: " + this.props.mc.modellName + " " + this.props.year);

        DataActions.selectMc(this.props.mc);
        DataActions.selectYear(this.props.year);
        
        

        if (DataStore.getFilteredProductList().length > 0){
            DataActions.sideMenuMcVisibility(false);
            DataActions.dropMenuMcVisibility(false);
        }
        
        event.stopPropagation();
    
    }
      
    render(){
        
        //console.log("FP", this.props.year);
    
        return (
            <div className="mcListYear" onClick={this.onClick.bind(this)} title={this.props.year.year}>&nbsp;{this.props.year.year}  </div>

        )
    }
}