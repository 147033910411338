import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import CategoryListNode from "./CategoryListNode";
var Constants = require('../Constants')



export default class CategoryList extends React.Component{

    
    constructor(){
        super();
        this.state = {categoryList: DataStore.getCategoryList()}

    }
    

    componentDidMount(){
    
        DataStore.on(Constants.CATEGORY_LIST_CHANGED, () => {
            
            this.setState({categoryList: DataStore.getCategoryList()})
            console.log("FP: LIST", DataStore.getCategoryList());
            console.log("FP: LIST PT:", DataStore.getProductType());
        })
    }

    render(){



        return (
            <div className='categoryList'>
           
            { this.state.categoryList.map((item, index) => ( <CategoryListNode category={item} /> )) }

            
            <div className="clearBoth"></div>
           
        </div>

        )
    }
}
