import React from "react"

import buttonClose from '../../gfx/button_close.png'; 
import buttonUp from '../../gfx/button_up.png'; 
import buttonDown from '../../gfx/button_down.png'; 

import '../../css/TopMenu.css';

export default class FilterMcYear extends React.Component{

    constructor(props){
        super(props);
        this.state = {open: true}
    }

    onClick(event){
        this.props.onYearClick(this.props.year);
        event.stopPropagation(); 

    }


    render(){

        return (
            <div className='year' onClick={this.onClick.bind(this)}>{this.props.year.year}</div>
        );
    }

}