import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import McTreeBrand from "./McTreeBrand"
var Constants = require('../Constants');

export default class McTree extends React.Component{

    
    constructor(){
        super();
        this.state = {mcTree: DataStore.getMcTree()}
        
    }

    componentDidMount() {
    
        DataStore.on(Constants.MC_TREE_CHANGED, () => {
            this.setState({mcTree: DataStore.getMcTree()})
        })
    }
    
    render(){

        

        return (
            <div className='mcTree'>
            
            { this.state.mcTree.map((item, index) => ( <div><McTreeBrand brand={item} /> </div> )) }
            
        </div>

        )
    }
}