import React from "react"


import '../../css/TopMenu.css';
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import buttonClose from '../../gfx/button_close.png'; 
import buttonDown from '../../gfx/button_down.png'; 
import buttonUp from '../../gfx/button_up.png'; 

import FilterMc from "./FilterMc";

var Constants = require('../Constants')

export default class TopMenu extends React.Component{

    constructor(){
        super();

        this.state = {
          brand: DataStore.getBrand(), 
          mc: DataStore.getMc(),  
          year: DataStore.getYear(), 
          category: DataStore.getCategory(),
          manufactor: DataStore.getManufactor(),
          bikeType: DataStore.getBikeType(),
          productListCount: DataStore.getFilteredProductList().length,
          productType: DataStore.getProductType(),
          dropMenuCategoryVisible: DataStore.isDropmenuCategoryVisible(),
          dropMenuManufactorVisible: DataStore.isDropmenuManufactorVisible(),
          dropMenuBikeTypeVisible: DataStore.isDropmenuBikeTypeVisible(),
          dropMenuMcVisible: DataStore.isDropmenuMcVisible()

        }

        this.onClickDeleteBrand = this.onClickDeleteBrand.bind(this);
        this.onClickDeleteMC = this.onClickDeleteMC.bind(this);
        this.onClickDeleteYear = this.onClickDeleteYear.bind(this);
        this.onClickDeleteCategory = this.onClickDeleteCategory.bind(this);

        //this.onClickManufactor = this.onClickManufactor.bind(this);
        this.onClickDeleteManufactor = this.onClickDeleteManufactor.bind(this);
    }
    
   

      onClickDeleteMC(event){
        DataActions.selectMc(null);
        event.stopPropagation(); 
      }


   onClickMC(e){
    //console.log(e);

    //this.props.buttonPressed("MC");
    DataActions.dropMenuMcToggle()
   }
   onClickCategory(e){
    //console.log(e);



    DataActions.dropMenuCategoryToggle()
    //this.props.buttonPressed("Category");
   }

   onClickBike(){

    const visible = DataStore.isDropmenuBikeTypeVisible()
    DataActions.dropMenuBikeTypeVisibility(!visible);
   }

   onClickManufactor(event){
      DataActions.dropMenuManufactorToggle()
      
   }

   onClickDeleteYear(event){
    DataActions.selectYear(null);
    event.stopPropagation(); 
  }

  onClickDeleteBrand(event){
    DataActions.selectYear(null);
    event.stopPropagation(); 
  }

  onClickDeleteCategory(event){
    DataActions.selectCategory(null);
    event.stopPropagation(); 
  }

  onClickDeleteBikeType(event){
    DataActions.selectBikeType(null);
    event.stopPropagation(); 
  }

  onClickDeleteManufactor(event){
    DataActions.selectManufactor(null);
    event.stopPropagation();
  }

    componentDidMount(){
    
        DataStore.on(Constants.MC_CHANGED, () => { this.setup() })
        DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => { this.setup() })
        DataStore.on(Constants.YEAR_CHANGED, () => { this.setup() })
        DataStore.on(Constants.BRAND_SELECTED, () => { this.setup() })
        DataStore.on(Constants.CATEGORY_SELECTED, () => { this.setup() })
        DataStore.on(Constants.MANUFACTOR_SELECTED, () => { this.setup() })
        DataStore.on(Constants.PRODUCT_TYPE_CHANGED, () => { this.setup() })
        DataStore.on(Constants.BIKE_TYPE_SELECTED, () => { this.setup() })
        DataStore.on(Constants.DROP_MENU_VISIBILITY_CHANGED, () => { this.setup() })

    }

    setup(){
      var productListCount = DataStore.getFilteredProductList().length
        this.setState({productListCount: productListCount})
        this.setState({brand: DataStore.getBrand()})
        this.setState({mc: DataStore.getMc()})
        this.setState({year: DataStore.getYear() })
        this.setState({category: DataStore.getCategory()})
        this.setState({manufactor: DataStore.getManufactor() })
        this.setState({productType: DataStore.getProductType()})
        this.setState({bikeType: DataStore.getBikeType() })

        this.setState({dropMenuCategoryVisible: DataStore.isDropmenuCategoryVisible() })
        this.setState({dropMenuManufactorVisible: DataStore.isDropmenuManufactorVisible() })
        this.setState({dropMenuBikeTypeVisible: DataStore.isDropmenuBikeTypeVisible() })
        this.setState({dropMenuMcVisible: DataStore.isDropmenuMcVisible() })


        

        //this.setState({productListCountLabel: "inga"});

        if (productListCount == 0){
          this.setState({productListCountLabel: "INGA PRODUKTER"});

        }else if (productListCount == 1){
          this.setState({productListCountLabel: "EN PRODUKT"});

        }else if(productListCount < DataStore.getProductLimit()){
          this.setState({productListCountLabel: productListCount + " PRODUKTER"});

        }else if(productListCount >= DataStore.getProductLimit()){
          this.setState({productListCountLabel: productListCount + "+ PRODUKTER"});

        }
    }

   




   
    render(){
        
        //console.log(this.props);

        //this.props.changeTitle('HeaderTitle');

        const br = <br/>
        var selectModellLabel = "VÄLJ MC-MODELL\n";

        if (this.state.brand){
          selectModellLabel = "MC-MÄRKE\n";
        }else if (this.state.mc){
          selectModellLabel = "MC-MODELL\n";
        }

        const open = this.state.dropMenuBikeTypeVisible || this.state.dropMenuMcVisible || this.state.dropMenuCategoryVisible || this.state.dropMenuManufactorVisible

        var cssBikeType = open ? "topMenuButton otherOpen" : "topMenuButton"
        var cssMc = open ? "topMenuButton otherOpen" : "topMenuButton"
        var cssCategory = open ? "topMenuButton otherOpen" : "topMenuButton"
        var cssManufactor = open ? "topMenuButton otherOpen" : "topMenuButton"


        if (open){
          
          if (this.state.dropMenuManufactorVisible){ cssManufactor = "topMenuButton open" }
          if (this.state.dropMenuCategoryVisible){ cssCategory = "topMenuButton open" }
          if (this.state.dropMenuMcVisible){ cssMc = "topMenuButton open" }
          if (this.state.dropMenuBikeTypeVisible){ cssBikeType = "topMenuButton open" }

        }

        const titleMc = "Sök efter mc-modeller eller mc-tillverkare";
        const titleBikeType = "Sök efter cykel-modeller";
        const titleCategory = "Sök bland produktkategorier";
        const titleManufactor = "Sök bland varumärken";

        return (



            <div className='topMenu'>
              
              { this.state.productType == 1 ?
               <div className={cssMc} onClick={this.onClickMC.bind(this)} title={titleMc}> 
               {selectModellLabel} 

               {this.state.brand ? <div className='filter' onClick={this.onClickDeleteBrand.bind(this)}><img src={buttonClose} /> {this.state.brand.name}</div>: ""}
               
               {this.state.mc ? <FilterMc mc={this.state.mc} year={this.state.year}/> : null }
               
               {this.state.mc && false ? <div className={this.state.year ? 'filter':'filter filterWide'} onClick={this.onClickDeleteMC.bind(this)} title={this.state.mc.key}>
                <img src={buttonClose} /> {this.state.mc.modellName}  </div>: ""}


                {this.state.year ? <div className='filter filterYear' onClick={this.onClickDeleteYear.bind(this)}>{this.state.year.year} <img src={buttonClose} /></div>: ""}
                
                { !this.state.brand && !this.state.mc && !this.state.year ? <img src={ this.state.dropMenuMcVisible ? buttonUp : buttonDown } /> : ""}
                </div> : null }

                {this.state.productType == 2 ?
               <div className={cssBikeType} onClick={this.onClickBike.bind(this)} title={titleBikeType}> 
                CYKEL<br/>
                {this.state.bikeType ? <div className='filter filterWide' onClick={this.onClickDeleteBikeType.bind(this)} title={this.state.bikeType.name}><img src={buttonClose}  />{this.state.bikeType.name}</div>: <img src={ this.state.dropMenuBikeTypeVisible ? buttonUp : buttonDown } />}
                </div> : null }

               <div className={cssCategory} onClick={this.onClickCategory.bind(this)} title={titleCategory}> 
               KATEGORIER<br/>
               {this.state.category ? <div className='filter right filterWide' onClick={this.onClickDeleteCategory.bind(this)} title={this.state.category.label} title={this.state.category.key}><img src={buttonClose}  />{this.state.category.label}</div>: <img src={  this.state.dropMenuCategoryVisible ? buttonUp : buttonDown } />}
                  
              </div>
              
              <div className={cssManufactor} onClick={this.onClickManufactor.bind(this)} title={titleManufactor}>VARUMÄRKEN<br/> 
              {this.state.manufactor ? <div className='filter filterWide' onClick={this.onClickDeleteManufactor.bind(this)}>{this.state.manufactor.name} <img src={buttonClose} /></div>: <img src={  this.state.dropMenuManufactorVisible ? buttonUp : buttonDown } />}
                
              </div>

              
               
              
               
            </div>

            

        )
    }
}