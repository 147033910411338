import React from "react"

import * as DataActions from "../actions/DataActions";
import ProductOptionRow from "./ProductOptionRow";



export default class ProductOption extends React.Component{

    constructor() {
        super();
        
      }
    
   onClick(e){
    
    
   }

    
    render(){
        
       
        return (
            <div className='productOption'>              
                <h3>{this.props.product.productOption.title}</h3>
                <table>
                    <thead>
                    <tr className='productOptionHeader'>
                
									<td>
										{this.props.product.productOption.headers.artnr}
										{this.props.product.productOption && this.props.product.productOption.hasEan ? 
											<span>/{this.props.product.productOption.headers.ean}</span> : '' }
									</td>
									
                    
                            {this.props.product.productOption.headers && this.props.product.productOption.headers.productOptionHeaders && this.props.product.productOption.headers.productOptionHeaders.map( (item, index) =>  ( <td> {item}  </td>)) }

                        
                        <td align='right'  >{this.props.product.productOption.headers.price}</td>

                        { this.props.isLoggedIn ? <td align='right' className='productStockCell'>Lager</td> : null }
                        { this.props.isLoggedIn ? <td align='right' className='productBuyButtonCell'>Köp</td> : null }
                    </tr>
                    </thead>
                    <tbody>

                    { this.props.product.productOption && this.props.product.productOption.rows && this.props.product.productOption.rows.map( (productOptionRow, index) =>  ( 
									<ProductOptionRow productOptionRow={productOptionRow} hasEan={this.props.product.productOption.hasEan} product={this.props.product} isLoggedIn={this.props.isLoggedIn} />)) 
                    }

                </tbody>

                 </table>
            </div>

        )
    }
}

