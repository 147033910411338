import React from "react"
import DataStore from "../stores/DataStore";
import '../../css/WebPage.css';
import StartNavigation from "./start/StartNavigation";


var Constants = require('../Constants')

export default class WebPage extends React.Component{

    constructor(){
        super();
        this.state = { 
            startModel: DataStore.getStartModel(),
        }
    }

    componentDidMount() {
    
        DataStore.on(Constants.START_MODEL_CHANGED, () => { 
            this.setState({startModel: DataStore.getStartModel() })

        })

        
    }

    render(){

        const pages = this.state.startModel && this.state.startModel.boove.www.filter( page => { return page.key == this.props.pageKey} )

        const page = pages ? pages[0] : null;
        

       return (
        <div className='webPage'> 
    
        <div className='navigation'>
        <StartNavigation />
        </div>
        
       {page ?  <div className='html' dangerouslySetInnerHTML={{__html: page.html}}></div> : null } 
       
       </div>
       )
    }
}