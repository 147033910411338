import React from "react"

import buttonClose from '../../gfx/button_close.png'; 
import buttonUp from '../../gfx/button_up.png'; 
import buttonDown from '../../gfx/button_down.png'; 
import * as DataActions from "../actions/DataActions";

import '../../css/TopMenu.css';
import FilterMcYear from "./FilterMcYear";

export default class FilterMc extends React.Component{

    constructor(props){
        super(props);
        this.state = {open: false}
        this.onYearClick = this.onYearClick.bind(this);
    }

    onClickDeleteMC(event){
        DataActions.selectMc(null);
        event.stopPropagation(); 

    }

    onOpenClick(event){
        this.setState({open: !this.state.open})
        event.stopPropagation(); 
    }

    onMouseLeave(event){
        this.setState({open: false})
        event.stopPropagation(); 
    }

    onYearClick(year){
        DataActions.selectYear(year);
        this.setState({open: false}) 
    }

    render(){

        return (
            <div className={this.props.year ? 'filter mc':'filter mc filterWide'}  title={this.props.mc.key} onClick={this.onOpenClick.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)}>
                { !this.props.dropdownHidden && this.state.open ?  <img src={buttonUp} class='selectYear' /> : null }
                { !this.props.dropdownHidden && !this.state.open ?  <img src={buttonDown} class='selectYear' /> : null }
              
                {this.props.mc.modellName} 
                <img src={buttonClose} onClick={this.onClickDeleteMC.bind(this)} title='Ta bort filter'/>
                

            {this.state.open && !this.props.dropdownHidden && this.props.mc.years && this.props.mc.years.length > 0 ? 
                <div className='filterMcYearView'>
                    {this.props.mc.years.map( (year) => (<FilterMcYear year={year} onYearClick={this.onYearClick} />)  )}
                </div> : null}     
            </div>
        );
    }

}