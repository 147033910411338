import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import McListModell from "./McListModell";




export default class McList extends React.Component{

    
    constructor(){
        super();
        this.state = {mcList: DataStore.getMcList()}

    }
    

    componentDidMount(){
    
        DataStore.on("mcListChanged", () => {
            
            this.setState({mcList: DataStore.getMcList()})
        })
    }

    render(){



        return (
            <div className='mcList'>
           
            { this.state.mcList.map((item, index) => ( <McListModell mc={item} display = {this.state.mcList.length <= 3} key={item.id} /> )) }

            
            <div className="clearBoth"></div>
           
        </div>

        )
    }
}
