import React from "react"
import DataStore from "../../stores/DataStore";
import * as DataActions from "../../actions/DataActions";
import "../../../css/Start.css"
import buttonDown from '../../../gfx/button_down_dark.png';
import buttonUp from '../../../gfx/button_up_dark.png';
var Constants = require('../../Constants');

export default class StartNews extends React.Component{

    constructor(props){
        super(props);
        this.state = { open: this.props.open }
    }

    onClick(e){
        //alert(123);
        this.setState({open: !this.state.open})
    }

    render(){
    
        return (
           
                <div className='startNews' > 

                {this.state.open ? 
                    <div className='open'>
                    <p className='html' dangerouslySetInnerHTML={{ __html: this.props.news.html }}></p>{this.props.news.datef}
                    
                    <div className='clearBoth'></div>
                    <img className='button' src={buttonUp} onClick={this.onClick.bind(this)} />
                    </div>
                    : 
                    <div className='closed' onClick={this.onClick.bind(this)}>

                        <img className='button' src={buttonDown} />
                        <div>
                        <h2 className='header'>{this.props.news.title}</h2>
                        <h4 className='date'>{this.props.news.datef}</h4> 
                        </div>
                    </div>}
                </div>
                
           
        )
    }
}
