import React from "react"
import '../../css/ProductGrid.css';
import Product from "./Product";
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";

var Constants = require('../Constants')

export default class ProductGrid extends React.Component{

    constructor(){
        super();
        this.state = {productList: DataStore.getFilteredProductList(), searchWord: ""}
        
        console.log("FP: URL: ProductGrid()");
    }
    

   componentWillMount(){
    
    
    //DataActions.selectViewType("grid");

        DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => {
            this.setState({productList: DataStore.getFilteredProductList()})
            this.setState({searchWord: DataStore.getSearchMc()})
        })
    }

    render(){

        var currentManufactor = null;

        return (
            <div className='productGrid'>

               {
                    this.state.productList.map((item, index) => {

                        if (item.manufactorName != currentManufactor && DataStore.getGroupBy() === Constants.GROUP_BY_MANUFACTOR && DataStore.getMc()){
                             
                            currentManufactor = item.manufactorName;
                            return (<Product key={item.id} product={item} viewType='grid' isLoggedIn={this.props.isLoggedIn} highlightManufactor={true} />);

                            //return <div >{currentManufactor}</div>;
                        }
                        

                     return (<Product key={item.id} product={item} viewType='grid' isLoggedIn={this.props.isLoggedIn}/>);
                    })
                }

            </div>

        )
    }
}