import React from "react"
import DataStore from "../stores/DataStore";
import * as DataAction from "../actions/DataActions"
import * as AsyncFilter from "../AsyncFilter"
import { ProductImage } from "./ProductImage";


export default class Test extends React.Component {

	constructor() {
		super();

		this.state = {
			running: 1
		}




	}

	onClick() {
		//alert(123);

		document.documentElement.style.setProperty('--color-bg', 'pink');
	}

	componentDidMount() {
		//const list = DataAction.xx([1,2,3,4,5,6]);

		//console.log("FP: async", list);
		/*
					 AsyncFilter.filterAsync([1,2,3,4,5,6], this.compare).then( result => {
		
						this.setState({running: 0}, alert(this.state.running));
		
						//alert(this.runnning);
						 
						});
						alert(this.state.running);
			*/
	}

	compare(item) {
		if (item == 1 || item == 4) {
			return true;
		} else {
			return false;
		}
	}

	/*
			asyncFilter = async (arr, predicate) => {
					const results = await Promise.all(arr.map(predicate));
		  
					return arr.filter((_v, index) => results[index]);
			}
	
			asyncRes = await asyncFilter(arr, async (i) => {
					await sleep(10);
					return i % 2 === 0;
			});
	
	
	
			async  msg() {
					const msg = await this.who();
					const msg2 = await this.what();
	
				  
	
					console.log("FP: Message:", msg);
					alert(list.length);
				}
	
			async componentDidMount(){
					const arr = [1, 2, 3, 4, 5];
					//this.msg();
					console.log("FP:", asyncRes);
	
			}*/

	who() {
		return new Promise(resolve => {
			const list = [1, 2, 3, 4, 5, 6].filter((item) => { return item == 1 || item == 2 })
		});
	}

	what() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve('what');
			}, 1000);
		});
	}

	css = {
		'margin': '20px'

	}

	spinner = {
		'background': 'red',
		'float': 'right'
	}



	render() {
		//return (<div ><button onClick={this.onClick.bind(this)}>KNAPP</button>test...</div>)
		//return (<div>test xx...</div>)
		return (<div>
			Test test
            </div>)


	}
}