import React from "react"
import DataStore from "../../stores/DataStore";
import * as DataActions from "../../actions/DataActions";
import "../../../css/Start.css"
import StartBrand from "./StartBrand";
import StartNews from "./StartNews";
import Product from "../Product";
import circle from '../../../gfx/circle.png';
import StartCategory from "./StartCategory";
import {NavLink} from 'react-router-dom'

import icon_motul from '../../../gfx/icon-motul.png';
import button_right from '../../../gfx/button_right_dark.png';

var Constants = require('../../Constants');

export default class StartCategoryView extends React.Component{

    constructor(props){
        super(props);
        this.state = { visible: this.props.visible }
        this.onCategoryClick = this.onCategoryClick.bind(this);
    }

    

    onClickHeader(event){
        this.setState({visible: !this.state.visible})
    }

    onCategoryClick(categoryKey){
        
        const category = DataStore.findCategory(DataStore.categoryTreeMotul, categoryKey);
       
        DataActions.selectProductType(3);
        DataActions.selectCategory(category);
    }

    render(){

       
    
        return (
           
                <div className={this.state.visible ? 'startCategoryView startBox  pt_motul' : 'startCategoryView startBox closed pt_motul' }> 
                    <div className='startHeader' onClick={this.onClickHeader.bind(this)}><img src={icon_motul} className='icon'/><h1>{this.props.categoryView.header}</h1></div>
                    
                    {this.state.visible ? <div>
                    <div className='startDescription'><h3>{this.props.categoryView.description}</h3></div>


                    {this.props.categoryView.categoryList ? <div className='categoryList'>
                        
                        {this.props.categoryView.categoryList.map( (category, index) => <StartCategory category={category} onClickCallback={this.onCategoryClick} /> )}

                    </div>:null}
                    
                    
                    <NavLink to='/motul/list/'>
                    <div className='startFooter'>
                    
                        <h3 className='startFooterLabel'>{this.props.categoryView.footer}</h3>
                        <img src={button_right} />
                    
                    </div>
                    </NavLink>

                    </div>: null}
                </div>
                
           
        )
    }
}