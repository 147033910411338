import React from "react"

import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";

import buttonRight from '../../gfx/button_right.png'; 

export default class ProductExtraInformationMc extends React.Component{


    constructor(props){
        super(props);
    }
    
    onClick(){

        
        const mc = DataStore.findMcList(this.props.mc.id);

        

        if (mc){
            DataActions.selectMc(mc);
            this.props.onCloseCallback();
        }
    }
  
    
    render(){

        return (    <div className='mc' onClick={this.onClick.bind(this)}> <img src={buttonRight} />
        {this.props.mc.mcLabel}  {this.props.mc.yearLabel} </div>        )
    }
}