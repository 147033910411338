import React from "react"

import '../../css/Header.css';
import * as DataActions from "../actions/DataActions"
import DataStore from "../stores/DataStore";
import buttonShare from '../../gfx/button_share.png';
import buttonClose from '../../gfx/button_close_grey.png';


var Constants = require('../Constants')

export default class FullsizeImage extends React.Component{

    constructor(){
        super();
       
        this.state = { fullsizeImage: DataStore.getFullsizeImage() }
       
     
    }
    
    onPathClick(event){
       
        if (window.confirm("Öppna bild i webbläsaren?")){
            window.open(this.state.fullsizeImage, '');
        }
        event.stopPropagation();
    }

   onCloseClick(e){
    DataActions.setFullsizeImage(null);

    }

    componentDidMount() {
    
        DataStore.on(Constants.FULLSIZE_IMAGE_CHANGED, () => { this.setState({fullsizeImage: DataStore.getFullsizeImage() })})
        
    }

    render(){
        
        const imagePath = this.state.fullsizeImage
        
        return (

            
            <div className={ this.state.fullsizeImage ? "fullsizeImage fullsizeImageVisible pointer" : "fullsizeImage fullsizeImageHidden" } title='Stäng bild' onClick={this.onCloseClick.bind(this)}>
                
                <div class='close'> <img src={buttonClose} title='Stäng bild' className='pointer' /></div>
                {this.state.fullsizeImage ? 
                <img className='image pointer' src={imagePath}  title='Stäng bild' /> : null }
                <br/>
                <div className='share pointer'  onClick={this.onPathClick.bind(this)} title='Öppna i webbläsaren'>
                    <img src={buttonShare} title={"Öppna i webbläsaren (" + imagePath + ")"} />
                    <div>{imagePath}</div>
                    </div><br/>
                
                <br/><br/><br/><br/>
                
              
               
            </div>

        )
    }
}