import { EventEmitter } from "events";
import dispatcher from "../Dispatcher";

//mport * as AsyncFilter from "../AsyncFilter"

var Constants = require('../Constants')

class DataStore extends EventEmitter {
	constructor() {
		super();
		this.defaultProductLimit = 100;
		//this.currentProductLimit = 0;
		this.productLimit = this.defaultProductLimit;

		this.config = { "maxDataAgeSeconds": 100 };
		this.displayProduct = this.displayProduct.bind(this);
		this.requestCount = 0;
		this.orderRequestCount = 0;
		//this.productRequestCount = 0;
		this.productRequestList = [];
		//this.productList = new Array(10000);
		this.productList = [];
		this.currentProductListLength = 0;
		this.dropMenuCategoryVisible = false;
		this.dropMenuManufactorVisible = false;
		this.dropMenuBikeTypeVisible = false;
		this.dropMenuMcVisible = false;
		this.loginVisible = false;
		this.filteredProductList = [];
		this.filteredProductListUnlimited = [];
		this.showAll = false;

		this.loginToken = null;
		this.loginMessage = null;
		this.displayLogoutMessage = false;
		this.errorMessage = null;
		this.expireMinutes = 0;
		this.message = null;
		this.fullsizeImage = null;
		this.customer = null;

		this.searchMc = "";
		this.searchProduct = "";

		this.mcList = [];

		this.filteredMcList = [];

		this.mcTree = [];
		this.brandList = [];
		this.mcTreeSelected = [];
		this.brand = null;
		this.mc = null;
		this.year = null;
		this.product = null;
		this.productType = 0;

		this.sideMenuVisible = false;
		this.searchBoxVisible = true;

		this.categoryTree = [];


		this.categoryList = [];
		this.categoryTreeMC = [];
		this.categoryTreeBike = [];
		this.categoryTreeMotul = [];
		this.filteredCategoryList = [];
		this.searchCategory = "";
		this.category = null;
		this.manufactorList = [];
		this.searchManufactor = "";
		this.manufactor = null;
		this.filteredManufactorList = this.manufactorList;
		this.modellCreated = { "baseModelCreated": null, "productListCreated": null }

		this.searchResultMcList = [];
		this.searchResultCategoryList = [];
		this.searchResultManufactorList = [];

		this.orderId = null;
		this.viewType = "list";
		this.bikeTypeList = []
		this.bikeType = null;
		this.startModel = null;
		this.startProducts = false;
		this.customNewsVisibility = true;
		this.autoNavigateToEntity = null;
		this.autoNavigateToKey = null;
		this.autoNavigateToList = [];
		this.currentProduct = null; // placeholder för produkt till url
		this.historyList = [];
		this.importMessageList = [];
		this.groupBy = null;
		this.sortBy = Constants.SORT_RELEVANCE;
		this.onlyStock = false;
		this.onlyOutlet = false;
		this.reloadProductId = null;

		this.debugMode = false;



	}

	setReloadProductId(productId) {
		this.reloadProductId = productId;
	}

	getReloadProductId() {
		return this.reloadProductId;
	}

	setAutoNavigateToList(list) { // FP: Sätter denna direkt utan någon dispatch
		this.autoNavigateToList = list;
	}

	setViewType(viewType) {  // FP: Sätter denna direkt utan någon dispatch
		this.viewType = viewType;
	}

	setProductType(productType) {
		this.productType = productType;
	}

	isDebug() {
		return this.debugMode;
		//return process.env.NODE_ENV == "development";
	}

	getFilteredProductList() { return this.filteredProductList }

	getFilteredProductListLength() { return this.filteredProductList ? this.filteredProductList.length : 0 }

	getFilteredProductListUnlimitedLength() { return this.filteredProductListUnlimited ? this.filteredProductListUnlimited.length : 0 }

	isProductsLoaded() { return this.mcList && (this.mcList.length > 0) && this.categoryList && this.categoryList.length > 0 }

	isDataLoaded() { // FP: la till startModel 

		if (this.startModel == null) {
			return false;
		}

		return this.mcList && (this.mcList.length > 0) && this.categoryList && this.categoryList.length > 0

	}

	//getProductListCount() { return this.currentProductList.length }

	getMcList() { return this.filteredMcList }

	getBikeTypeList() { return this.bikeTypeList }

	getSearchProduct() { return this.searchProduct }

	getSearchCategory() { return this.searchCategory }

	getSearchMc() { return this.searchMc }

	getRequestCount() { return this.requestCount }

	getOrderRequestCount() { return this.orderRequestCount }

	//getProductRequestCount()  { return this.productRequestCount}

	isProductLoading(productId) {

		//return true;
		if (this.productRequestList.includes(productId)) {
			//console.log("FP: isProductLoading true: " + productId + " ", this.productRequestList);    
			return true
		}

		//console.log("FP: isProductLoading false: " + productId + " ", this.productRequestList);
		return false

	}

	findProduct(productId) {

		return this.productList[productId];

	}

	findMcList(mcId) {


		for (const mc of this.mcList) {

			if (mc.mcId == mcId) {
				return mc;
			}
		}

		return null;


	}


	findCategoryList(categoryId) {


		for (const category of this.categoryList) {

			if (category.categoryId == categoryId) {
				return category;
			}
		}

		return null;


	}

	findCategory(categoryList, key) {

		if (categoryList == null) {
			categoryList = this.categoryList; // FP: La till denna för att man ska kunna klicka på den kategori produkten tillhör
		}

		var stack = [];

		categoryList.forEach(category => stack.push(category));

		//stack.push(categoryList[0]);

		while (stack.length > 0) {

			var node = stack.pop();

			if (node.key == key) {
				//alert("Found it!");
				return node;
			}
			else if (node.categoryList && node.categoryList.length) {

				for (var i = 0; i < node.categoryList.length; i++) {
					stack.push(node.categoryList[i]);
				}
			}

		}

		return null;

	}

	getMcTree() {
		//console.log("FP: xx", this.mcTreeSelected.sort(this.sortListByName));
		//console.log("FP: xx2", this.mcTreeSelected.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

		//return this.mcTreeSelected.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) } // FP: Soterar tillverkare
		return this.mcTreeSelected;
	}

	getBrand() { return this.brand }

	getMc() { return this.mc }

	getYear() { return this.year }

	getCategory() { return this.category }

	getCategoryTree() {

		if (this.productType == 1) {
			return this.categoryTreeMC;

		} else if (this.productType == 2) {
			return this.categoryTreeBike;
		} else if (this.productType == 3) {
			return this.categoryTreeMotul;
		}

		return [];

	}

	getCategoryList() {

		this.filterCategoryList();

		return this.filteredCategoryList;
	}




	findManufactor(id) {

		return this.manufactorList.find((manufactor) => manufactor.id === id)

	}


	getProduct() { return this.product }

	getProductLimit() { return this.productLimit }

	getProductType() { return this.productType }

	getManufactor() { return this.manufactor }

	getBikeType() { return this.bikeType }

	getFilteredManufactorList() { return this.filteredManufactorList }

	//getManufactorList() { return this.manufactorList }

	//getFilteredManufactorList() { return this.filteredManufactorList }

	getSearchManufactor() { return this.searchManufactor }

	isDropmenuMcVisible() { return this.dropMenuMcVisible }

	isDropmenuCategoryVisible() { return this.dropMenuCategoryVisible }

	isDropmenuManufactorVisible() { return this.dropMenuManufactorVisible }

	isDropmenuBikeTypeVisible() { return this.dropMenuBikeTypeVisible }

	isLoginVisible() { return this.loginVisible }

	isSideMenuVisible() { return this.sideMenuVisible }

	isLoggedIn() {
		return (this.loginToken != null) // Constsants.LOGIN_STATUS_CHANGED
	}

	getExpireMinutes() {
		return this.expireMinutes
	}

	getProductTypeString() {

		if (this.productType == 1) {
			return "mc";
		}

		if (this.productType == 2) {
			return "cykel";
		}

		if (this.productType == 3) {
			return "motul";
		}



		return this.productType;
	}


	getSearchBoxVisible() { return this.searchBoxVisible }

	getOrder() {

		if (this.orderId && this.customer && this.customer.orderList) {
			return this.customer.orderList.find(order => order.orderId == this.orderId)
		}

		return null
	}

	getOrderRowCountLabel() {

		const order = this.getOrder();

		if (order && order.orderRowList && order.orderRowList.length > 0) {

			var count = 0;

			order.orderRowList.forEach(orderRow => { count += orderRow.quantity });
			//const count = order.orderRowList.length;

			if (count == 1) {
				return "1 PRODUKT";
			} else {
				return count + " PRODUKTER";
			}

		}

		return "0 PRODUKTER";
	}

	handleLogoutMessage() {

		if (this.displayLogoutMessage) {
			this.displayLogoutMessage = false
			return true
		}

		return false

	}
	getViewType() { return this.viewType }

	//getOrderLength() { return this.order.orderRows.length }

	getSearchResultMcList() { return this.searchResultMcList }

	getSearchResultCategoryList() { return this.searchResultCategoryList }

	getSearchResultManufactorList() { return this.searchResultManufactorList }

	getLoginMessage() { return this.loginMessage }

	getErrorMessage() { return this.errorMessage }

	setLoginMessage(message) { this.loginMessage = message } // FP: Hm kolla upp denna!

	getMessage() { return this.message }

	getFullsizeImage() { return this.fullsizeImage }

	getLoginToken() { return this.loginToken; }

	getCustomer() { return this.customer; }

	getCustomerNewsVisibility() { return this.customNewsVisibility; }

	getStartModel() {
		//console.log("FP: StartModell", this.startModel);
		return this.startModel;
	}

	getPriceTitle(product) {

		if (product == null) {
			return "";
		}

		if (this.isLoggedIn()) {
			return "KÖP " + product.pi;
		} else {
			return "Priset är inklusive moms";
		}
	}

	getStartModelResellerList() {

		if (this.startModel && this.startModel.boove && this.startModel.boove.resellerList) {
			return this.startModel.boove.resellerList
		}

		return null;
	}

	getStartModelBrandList() {

		if (this.startModel && this.startModel.boove && this.startModel.boove.brandList) {
			return this.startModel.boove.brandList
		}

		return null;
	}

	getImageUrl() {

		var url = "https://public.boove.se/w2image/";

		if (process.env.NODE_ENV == "development") {
			//url = "http://public.boove.se.dev2/w2image/";
		}

		return url;


	}

	getHistoryList() {
		return this.historyList;
	}


	hasDataExpired() {

		if (!this.modellCreated || !this.modellCreated.productListCreated) {
			return true;
		}

		const currentDate = Date.parse(this.modellCreated.productListCreated);

		const elapsed = ((new Date() - currentDate) / 1000);

		return (elapsed > this.config.maxDataAgeSeconds);
	}

	isProductListLimited() {

		return (this.currentProductListLength > this.filteredProductList.length);
	}

	getProductListCountLabel() {

		var stockLabel = "";

		if (this.onlyStock) {
			stockLabel = " i lager"
		}

		var outletLabel = "";

		if (this.onlyOutlet) {
			outletLabel = "outlet-"
		}

		if (this.filteredProductList.length == 1) { // om vi valt endast en produkt
			return "en " + outletLabel + "produkt" + stockLabel
		}

		if (this.filteredProductListUnlimited.length == 0) {
			return "inga " + outletLabel + "produkter" + stockLabel
		}

		if (this.filteredProductListUnlimited.length == 1) {
			return "en " + outletLabel + "produkt" + stockLabel
		}



		if (this.filteredProductListUnlimited.length > 1) {

			/* if (this.currentProductListLength > this.filteredProductList.length){
					 return this.filteredProductList.length + "+ produkter" + stockLabel
			 }else{
					 return this.filteredProductList.length + " produkter" + stockLabel
			 }*/

			return this.filteredProductListUnlimited.length + " produkter" + stockLabel


		}

	}

	getProductListCountTitleLabel() {
		return "antal: " + this.filteredProductListUnlimited.length + " produkter";
	}

	getImportMessageList() {
		return this.importMessageList;
	}

	getGroupBy() {
		return this.groupBy;
	}

	getSortBy() {
		return this.sortBy;
	}

	getOnlyStock() {
		return this.onlyStock;
	}

	getOnlyOutlet() {
		return this.onlyOutlet;
	}

	filterProductList(sort) {

		// om inget filter är satt så visa bara utvalda produkter
		/*if (this.productType != 3 && this.bikeType == null && this.mc == null && this.brand == null && this.category == null && this.manufactor == null && this.currentProduct == null &&  (this.searchProduct == null || this.searchProduct == "")){
				this.startProducts = true;
		}else{
				this.startProducts = false;
		}*/


		var currentProductList = this.currentProductList(); // hämtar baslistan med id från "något"
		//console.log("FP: Före listan:", currentProductList);

		this.searchProductF = this.searchProduct
		this.searchProductF = this.searchProductF.toLowerCase();
		this.searchProductF = this.searchProductF.replace(/\s/g, "");

		console.log("FP: Antal produkter före displayProduct: ", currentProductList.length);
		console.log("FP: searchProductF", this.searchProductF);

		var list = currentProductList.filter(this.displayProduct); // går igenom baslistan och kollar om produkten ska visas

		//var list = currentProductList

		if (list.length != this.filteredProductListUnlimited.length) {
			this.productLimit = this.defaultProductLimit;
		}


		this.currentProductListLength = list.length;
		this.filteredProductListUnlimited = list;

		const that = this;

		if (sort || true) {
			list = list.sort((a, b) => {

				if (that.groupBy === Constants.GROUP_BY_MANUFACTOR && that.mc) { // gruppering

					if (a.manufactorName != b.manufactorName) {
						return (a.manufactorName.localeCompare(b.manufactorName, { sensitivity: 'base' }));
						//return (a.manufactorName < b.manufactorName);
					}

				}




				if (this.sortBy == Constants.SORT_RECENT) {
					return (b.id - a.id);

				} else if (this.sortBy == Constants.SORT_PRICE_ASC) {

					if (a.price <= 0 && b.price <= 0) {
						return 0
					}

					if (a.price <= 0) {
						return 1
					}

					if (b.price <= 0) {
						return -1
					}

					return a.price - b.price;


				} else if (this.sortBy == Constants.SORT_PRICE_DESC) {

					if (a.price <= 0 && b.price <= 0) {
						return 0
					}

					if (a.price <= 0) {
						return 1
					}

					if (b.price <= 0) {
						return -1
					}

					return b.price - a.price;

				} else if (this.sortBy == Constants.SORT_ARTNR) {
					return (a.artnr.localeCompare(b.artnr, { sensitivity: 'base' }));

				} else if (this.sortBy == Constants.SORT_NAME) {
					return (a.name.localeCompare(b.name, { sensitivity: 'base' }));

				} else {
					return (a.score - b.score);
				}


				//return that.sortProduct(a, b, that);
			});
		}

		//list = list.reverse();

		/*first = list.find(Boolean);

		if (first) {
			console.log("FP: searchProductF first after-sort: ", first);
		}*/

		// FP: Här kan vi hantera olika limits
		/*if (this.mc){
				this.productLimit = this.mc.productListAll.length;
		}else{
				this.productLimit = this.defaultProductLimit;
		}*/



		//this.productLimit = this.defaultProductLimit;

		//this.productLimit = 1000;
		//alert(this.showAll);

		/*if (!this.showAll){
				this.filteredProductList = list.slice(0, this.productLimit); // limiterar
		}else{
				this.filteredProductList = list
		}*/

		//this.showAll = false;

		this.filteredProductList = list.slice(0, this.productLimit); // limiterar


		this.emit(Constants.PRODUCT_LIST_CHANGED)

		this.filterManufactor();

		/*AsyncFilter.filterAsync(currentProductList, this.displayProduct).then( list => {
				this.currentProductListLength = list.length;
				this.filteredProductListUnlimited = list;
				this.filteredProductList = list.slice(0, this.productLimit); // limiterar
				this.emit(Constants.PRODUCT_LIST_CHANGED)

				this.filterManufactor();
		})*/



		// FP this.currentProductListLength = list.length;



	}
	/*
			sortProduct(a, b, that){
	
					that.sortBy(a, b);
					//if (this.sortBy)
					//return (a.manufactorName > b.manufactorName);
	
	
	
					//return (b.artnr.localeCompare(a.artnr, { sensitivity: 'base' })); // artikelnummer stignade
	
					//return (a.artnr.localeCompare(b.artnr, { sensitivity: 'base' })); // artikelnummer fallande
	
					//return (a.price > b.price); fallande
					//return  (a.score < b.score ) 
			}*/
	/*
			sortBy(a, b){
	
					if (this.sortBy == null){
							return  (a.score < b.score );
					}
	
					if (this.sortBy == Constants.SORT_PRICE_ASC){
							return (a.price < b.price); 
					}
	
					if (this.sortBy == Constants.SORT_PRICE_DESC){
							return (a.price > b.price); 
					}
	
				 // return (a.manufactorName > b.manufactorName);
	
			}*/

	currentProductList() { // 

		var baseList = [];

		// här tar vi bara fram en (vilken som) lista som innehåller ett urval av produkter för att ha mindre att utgå ifrån
		if (this.bikeType != null) {
			baseList = this.bikeType.productList;
			console.log("FP: currentProductList FROM: this.bikeType: ");
		} else if (this.mc != null) {

			baseList = this.mc.productListAll; // tar även med produkter på årsmodeller
			console.log("FP: currentProductList FROM: this.mc: ");

		} else if (this.brand) { // && this.brand.modells && this.brand.modells.length > 0

			baseList = this.brand.productList;
			console.log("FP: currentProductList FROM: this.brand: ", this.brand);

		} else if (this.category != null) {

			baseList = this.category.productList;
			console.log("FP: currentProductList FROM: this.category: ");


			//alert(123);

			/*        }else if(this.manufactor != null){
			
									baseList = this.manufactor.productList;
			*/
		} else {
			//return this.productList; // här ska vi göra en lista av produkter i objektet

			var list = [];
			var mainThis = this;

			Object.keys(this.productList).forEach(function (key, index) {
				// key: the name of the object key
				// index: the ordinal position of the key within the object 
				//alert(obj[key]);
				list.push(mainThis.productList[key]);
			});

			return list;

		}


		// filtera listan på de olika entiterna som kan vara valda 
		console.log("FP: listan att börja med: ", baseList);

		if (this.mc != null) {
			//baseList = this.reduceList(baseList, this.mc.productList);

			//console.log("FP: listan antal efter mc: " + baseList.length);

			baseList = [];

			if (this.mc.productListAll && this.mc.productListAll.length > 0) {
				baseList = this.mc.productListAll;
				//console.log("FP: this.mc.productListAll: ", baseList);
			}

			/* if (this.mc.years && this.mc.years.length > 0){ // åren FP: Ta bort detta eftersom productListAll innehåller alla istället
									 
					 this.mc.years.forEach( function(year) {

							 if (year.productList && year.productList.length > 0){

									 year.productList.forEach( function(productId){
											 
											 if (!baseList.includes(productId)){
													 baseList.push(productId);
											 }
									 });
							 }

					 });

			 }*/

		}

		else if (this.brand != null) {

			//console.log("FP: listan före brand: " + this.brand.productList);
			//baseList = this.reduceList(baseList, this.brand.productList);
			//console.log("FP: listan efter brand: " + baseList);

			baseList = [];

			if (this.brand.productList && this.brand.productList.length > 0) {
				baseList = this.brand.productList;
			}


			if (this.brand.modells && this.brand.modells.length > 0) {

				this.brand.modells.forEach(function (mc) { // modeller

					if (mc.productListAll && mc.productList.length > 0) {

						mc.productListAll.forEach(function (productId) { // modellens produkter

							if (!baseList.includes(productId)) {
								baseList.push(productId);
							}

						});
					}

					/*if (mc.years && mc.years.length > 0){ // åren FP: Åren addad med productListAll
						  
							mc.years.forEach( function(year) {

									if (year.productList && year.productList.length > 0){

											year.productList.forEach( function(productId){
												  
													if (!baseList.includes(productId)){
															baseList.push(productId);
													}
											});
									}

							});

					}*/

				});

			}

			//alert(baseList.length);

		}

		if (this.category != null) {
			//baseList = this.reduceList(baseList, this.category.productList);
			//console.log("FP: listan efter kategory: " + baseList);

			baseList = this.collectProducts(this.category, []); // FP: Fyller på med alla produkter på subnoderna

		}

		if (this.manufactor != null) {

			//baseList = this.reduceList(baseList, this.manufactor.productList);
			//console.log("FP: listan efter manufactor: " + baseList);
		}

		//alert(baseList.length);

		var list = [];
		var mainThis = this;

		// om vi har en baseList så lägg till deras produkt-objekt
		baseList.forEach(function (productId) {

			if (productId != null && productId > 0) {

				//list.push(mainThis.productList[productId]); // FP Här ska vi istället hämta från objektet
				let product = mainThis.productList[productId];

				if (!list.includes(product)) {
					list.push(product);
				}

			}


		});

		//alert("antal produkter:" + list.length);
		console.log("FP: med produkter ", list);
		return list; // lista av product-objekt

	}

	// loopar en category och alla dess under-kategorier och samlar alla produkterna i en lista
	collectProducts(category, productList) {

		var mainThis = this;

		category.productList.forEach(function (productId) {

			if (!productList.includes(productId)) {
				productList.push(productId);
			}

		});

		if (category.categoryList && category.categoryList.length > 0) {
			category.categoryList.forEach(function (category) {
				return mainThis.collectProducts(category, productList);
			});
		}

		return productList;

	}

	reduceList(listA, listB) { // returera de id som finns i BÅDA listorna

		var list = [];

		if (listA.length < listB) {
			listA.forEach(function (id) {
				if (listB.includes(id)) {
					list.push(id);

				}
			});
		} else {

			listB.forEach(function (id) {

				if (listA.includes(id)) {
					list.push(id);
					//alert(id);
				}
			});
		}

		//alert(list.length);
		return list;

	}




	displayProduct(product) { // hanterar om vi ska visa den enskilda produkten

		if (product == null || product.name == null) {
			return false;
		}

		/*if (this.mc != null  && !this.mc.productList.includes(product.id)){ // om mc vald och produkten inte finns i mc-listan
				return false;
		}*/

		//console.log("FP: Product: ", product);

		if (this.onlyStock) {

			if (product.sl && product.sl != "NONE") {

			} else if (product.optionStock && product.optionStock > 0) {

			} else {
				return false;
			}

		}

		if (this.onlyOutlet) {

			if (!product.outlet) {
				return false;
			}
		}

		if (this.year != null && (this.year.productList.includes(product.id) || this.mc.productList.includes(product.id))) {
			//console.log("FP: display: year", product);

		} else if (this.year == null && this.mc != null && this.mc.productListAll.includes(product.id)) {
			//console.log("FP: display: mc", product);

		} else if (this.mc == null) {
			//console.log("FP: display: mc == null");
		} else {
			//console.log("FP: display: else");
			return false;
		}

		if (this.manufactor != null && product.manufactorId != this.manufactor.id) {
			return false;
		}

		if (this.productType != null && product.pt != this.productType && (this.productType == 1 || this.productType == 2)) {
			return false;
		}

		if (this.productType != null && this.productType == 3 && product.manufactorName != "Motul") { // om det är typ 3 så visa bara produkter som tillhör motul
			return false;
		}

		if (this.startProducts === true && product.sort === 0) {
			return false;
		}

		//product.score = 0;

		if (this.searchProduct.length > 0) { // om vi fyllt i ett sökord, denna funkar som en "eller"


			var search = this.searchProductF;


			/*if (product.id && product.id == search){
					product.score = search.length * 100;
					return true;
			}*/ // FP: 2020-09-29 tog bort denna för att inte filtrera på productId. 

			if (product.artnr && product.artnr.toLowerCase().includes(search)) {
				product.score = search.length * 100;
				return true;
			}

			if (product.artnrList && product.artnrList.toLowerCase().includes(search)) {
				product.score = search.length * 50;
				return true;
			}

			// FP: 2021-03 la till denna för att sortera på ean
			if (product.ean && product.ean.toLowerCase().includes(search)) {
				product.score = search.length * 50;
				return true;
			}

			// FP: 2024-08 la till denna för att söka på hepco-becker som har space resp - i artikelnamn
			if (product.artnr.toLowerCase().replace(/\W+/g, "").includes(search.replace(/\W+/g, ""))) { // tar bort alla non-word char
				product.score = search.length * 15;
				return true;
			}

			if (product.name.toLowerCase().replace(/\s/g, "").includes(search)) {
				product.score = search.length * 10;
				return true;
			}



			/*if (product.keywords && product.keywords.includes(search)){
					return true;
			}*/

			/*if (product.keywords && product.keywords.toLowerCase().replace(/\s/g, "").includes(search)){ // FP: Söker även på keywords utan mellanslag
					product.score = search.length * 2;
					return true;
			}*/


			return false

		} else {
			return true; // om vi kommit hit och inte fyllt i något sökord är det träff
		}



	}

	/*
	displayProduct(product){ // hanterar om vi ska visa den enskilda produkten

			if (product == null || product.name == null){
					return false;
			}

		  
		  
		  
			if (this.mc != null && !this.mc.productList.includes(product.id)){
					return false;
			}

			if (this.year != null && !this.year.productList.includes(product.id)){
					return false;
				  
			}

			if (this.manufactor != null && product.manufactorId != this.manufactor.id){
					return false;
			}

			if (this.productType != null && product.pt != this.productType && (this.productType == 1 || this.productType == 2)){
					return false;
			}

			if (this.productType != null && this.productType == 3 && product.manufactorName != "Motul"){ // om det är typ 3 så visa bara produkter som tillhör motul
					return false;
			}

			if (this.startProducts === true && product.sort === 0){
					return false;
			}

			product.score = 0;

			if (this.searchProduct.length > 0){ // om vi fyllt i ett sökord, denna funkar som en "eller"


					var search = this.searchProduct;

					search = search.toLowerCase();

				  

					if (product.id && product.id == this.searchProduct){
							return true;
					}

					if (product.name.toLowerCase().includes(search)){
							return true;
					}

					if (product.artnr && product.artnr.toLowerCase().includes(search)){
							return true;
					}

					if (product.keywords && product.keywords.toLowerCase().includes(search)){
							return true;
					}

					if (product.keywords && product.keywords.toLowerCase().replace(/\s/g, "").includes(search)){ // FP: Söker även på keywords utan mellanslag
							return true;
					}


					return false
				  
			}else{
					return true; // om vi kommit hit och inte fyllt i något sökord är det träff
			}


		  
	}*/

	filterMc() {

		/*var list = this.mcList.filter((e) => 
		e.modellName != null && 
		e.modellName.toLowerCase().includes(this.searchMc.toLowerCase())
	  
		);*/

		//var mcList = this.mcList.concat();

		//console.log("FP: MCLIST l: ", mcList.length);

		/*var words = this.searchMc.toLowerCase().split(" ");

		for (const word of words){

				if (word && word.length > 0){
						mcList = this.filterMcList(word, mcList);
				}

		}*/

		console.log("FP: MCLIST: filterMc()");

		this.filteredMcList = this.filterMcList(this.searchMc);
		//this.filteredMcList = mcList;

		this.emit("mcListChanged")
	}




	filterMcList(search) {

		//console.log("FP: MCLIST l: ", mcList.length);
		//console.log("FP: MCLIST: ", mcList);

		search = search.toLowerCase();
		//search = search.replace(/\s/g, "");
		search = search.replace("-", "");


		var list = this.mcList.concat();

		list.map(mc => (mc.scrore = 0));

		var words = search.split(" ");

		for (const word of words) {

			console.log("FP: MCLIST: word: ", word);

			if (word && word.length > 0) {

				list = list.filter((e) => {

					if (!e.modellName) {
						return false;
					}

					if (e.modellName.toLowerCase().includes(word)) {
						e.score = 20;
						return true;
					}

					if (e.keywords.includes(word)) {
						e.score = 10;
						return true;
					}

					/*
					if (this.stripString2(e.modellPath).includes(word)){
							e.score = 5;
							return true;
					}*/

					return false;
				});

			}
		}



		list = list.sort((a, b) => (a.score < b.score));

		list = list.slice(0, 50);


		return list;

	}

	/*stripString(string){ 

			//string = string.toLowerCase();
			//string = string.replace(/\s/g, ""); // rensar inte mellanslag, prestandan
			string = string.replace("-", "");

			return string;
	}*/

	/*stripString2(string){ 

			//string = string.toLowerCase();
			//string = string.replace(/\s/g, ""); // rensar inte mellanslag, prestandan
			string = string.replace("-", "");

			return string;
	}*/

	/*    filterMcList(search){
				  
					search = search.replace(/\s/g, "");
	
					var list = this.mcList.filter((e) => 
							e.modellName != null && 
							(e.modellName.toLowerCase().replace(/\s/g, "").includes(search) 
							//|| e.keywords.toLowerCase().replace(/\s/g, "").includes(search) 
							|| e.modellPath.toLowerCase().replace(/\s/g, "").includes(search))
	
				  
					); 
	
					return list
				  
			}*/


	filterCategory() {

		/*var list = this.categoryList.filter((e) => 
		e.path != null && 
		e.path.toLowerCase().includes(this.searchCategory.toLowerCase())
	  
		); 

		this.filteredCategoryList = list*/
		this.filteredCategoryList = this.filterCategoryList(this.searchCategory.toLowerCase())

		//this.emit("categoryListChanged")
		this.emit(Constants.CATEGORY_LIST_CHANGED)
	}

	filterCategoryList(search) {

		if (search) {
			search = search.replace(/\s/g, "");
		}

		var list = this.categoryList.filter((e) =>
			e.path != null &&
			e.path.toLowerCase().replace(/\s/g, "").includes(search)
			&& e.pt === this.productType
		);

		return list;
	}

	filterManufactor() {


		/*var list = this.manufactorList.filter((e) => 
				e.name != null && 
				e.name.toLowerCase().includes(this.searchManufactor.toLowerCase())
		);*/

		this.filteredManufactorList = this.filterManufactorList(this.searchManufactor.toLowerCase())

		this.filteredManufactorList = this.filterManufactorListWithProductList(this.filteredManufactorList);
		this.emit(Constants.MANUFACTOR_LIST_CHANGED);
	}

	filterManufactorList(search) {

		search = search.replace(/\s/g, "");

		var list = this.manufactorList.filter((e) =>
			e.name != null &&
			e.name.toLowerCase().replace(/\s/g, "").includes(search) &&
			e.pt === this.productType
		);

		return list;
	}

	filterManufactorListWithProductList(filteredManufactorList) {

		console.log("FP: FILTER: startModel;", this.startProducts)
		// loopa listan med produkter och lägg alla manufactor id i en lista
		var manufactorInProductList = [];

		this.filteredProductListUnlimited.forEach((product) => {

			if (product && product.manufactorId && !manufactorInProductList.includes(product.manufactorId)) {
				manufactorInProductList.push(product.manufactorId);
			}
		})

		console.log("FP: FILTER: ", manufactorInProductList);

		filteredManufactorList.forEach(manufactor => {

			if (this.startProducts == true) { // eftersom motul inte har någon startlista, || this.productType == 3
				manufactor.inProductList = true;

			} else if (this.category == null && this.brand == null && this.mc == null) {
				manufactor.inProductList = true;
			} else if (this.filteredProductList.length == 0) {
				manufactor.inProductList = true;

			} else if (manufactorInProductList.includes(manufactor.id)) {
				manufactor.inProductList = true

			} else {
				manufactor.inProductList = false
			}

		});

		return filteredManufactorList;

	}

	getBrandList() { // FP: Loopiar brandList och lägger in alla brands i mcTree-selected, brand innehåller inga modeller, de kopieras in när man klickar på en brand

		this.mcTreeSelected = [];

		let mainThis = this;

		this.brandList.forEach((brand) => {

			mainThis.mcTreeSelected.push(brand);
			mainThis.emit(Constants.MC_TREE_CHANGED);

		});


		this.emit(Constants.MC_TREE_CHANGED);

	}

	hideAllDropMenu() {
		this.dropMenuCategoryVisible = false
		this.dropMenuMcVisible = false
		this.dropMenuManufactorVisible = false
		this.loginVisible = false
		this.dropMenuBikeTypeVisible = false
		this.loginVisible = false;
	}

	sortListByName(a, b) {

		if (a.name < b.name) {
			return -1;
		}
		if (b.name > a.name) {
			return 1;
		}
		return 0;
	}

	removeAllFilters() {
		console.log("FP: URL: Remove all filters");
		this.searchProduct = "";
		this.mc = null;
		this.year = null;
		this.brand = null;
		this.manufactor = null;
		this.bikeType = null;
		this.category = null;
		this.product = null;
		this.currentProduct = null;

		this.searchCategory = "";
		this.searchManufactor = "";
		this.searchMc = "";
		this.onlyOutlet = false;
		this.onlyStock = false;
	}

	resetSearchProductText() {
		this.searchProduct = "";
		this.currentProduct = null; // FP: Sätter denna till null eftersom vi har klickat på "något annat" än just en produkt
		this.emit(Constants.SEARCH_PRODUCT_TEXT_CHANGED);
	}

	getUrl() {
		var url = "/" + this.getProductTypeString() + "/"

		/* if (this.product == null && this.currentProduct && this.currentProduct.id){
				 url += "grid/"

		 }else{
				 url += this.viewType ? this.viewType + "/" : ""
		 }*/

		url += this.viewType ? this.viewType + "/" : ""

		if (this.onlyOutlet) {
			url += "outlet/";
		}

		url += this.manufactor ? this.manufactor.key + "/" : ""

		url += this.brand ? this.brand.key + "/" : ""

		url += this.mc ? this.mc.key + "/" : ""


		url += this.category ? this.category.key + "/" : ""

		url += this.product ? this.product.id + "/" : ""

		url += this.year ? this.year.year + "/" : ""



		/*if (this.category && (this.category.categoryList == null || this.category.categoryList.length == 0)){ // FP: tar bara med kategorier längst ned
				url += this.category.key + "/"
		}*/


		url += this.product == null && this.currentProduct ? this.currentProduct.id : ""

		return url;
	}

	autoNavigateTo() {

		/*if (this.isProductsLoaded() && this.autoNavigateToEntity && this.autoNavigateToKey){

				switch(this.autoNavigateToEntity){
						case "manufactor":{
								const manufactor = this.getManufactorByKey(this.autoNavigateToKey);
								this.manufactor = manufactor;
								this.emit(Constants.MANUFACTOR_SELECTED);
								break;
						}
				}
			  
				this.autoNavigateToEntity = null;
				this.autoNavigateToKey = null;
		}*/

		if (this.requestCount == 0 && this.isProductsLoaded() && this.autoNavigateToList && this.autoNavigateToList.length > 0) {

			console.log("FP: URL: Autonavigate: ", "")
			this.autoNavigateToList.forEach(key => {

				console.log("FP: Autonavigate: KEY", key);

				key = key.toLowerCase();

				if (key == "list" || key == "grid") {
					return;
				}

				//alert(key);
				if (key) {
					const manufactor = this.manufactorList.find(manufactor => manufactor.key == key)

					if (manufactor) {
						this.selectManufactor(manufactor);
						key = null;
					}
				}

				if (key) {

					const bikeType = this.bikeTypeList.find(bikeType => bikeType.key == key);

					if (bikeType) {
						this.selectBikeType(bikeType);
						key = null;
						/*
						console.log("FP: Autonavigate: foundBikeType:", bikeType);
						this.bikeType = bikeType;
						this.emit(Constants.BIKE_TYPE_SELECTED);
						key = null;*/

					}
				}


				if (key) {
					const brand = this.brandList.find(brand => brand.key == key);

					if (brand) {
						console.log("FP: Autonavigate: brand:", brand);
						this.selectBrand(brand.id);
						key = null;
					}
				}


				if (key) {
					//const category = this.categoryList.find(category => category.key == key)
					var category = null;

					if (this.productType == 1) { category = this.findCategory(this.categoryTreeMC, key); }
					if (this.productType == 2) { category = this.findCategory(this.categoryTreeBike, key); }
					if (this.productType == 3) { category = this.findCategory(this.categoryTreeMotul, key); }


					if (category) {
						this.selectCategory(category);
						key = null;
						/*this.category = category
						this.emit(Constants.CATEGORY_SELECTED);
						key = null;*/
					}
				}

				if (key) {

					const mc = this.mcList.find(mc => mc.key == key)

					if (mc) {

						this.selectMc(mc);
						key = null;
						/*console.log("FP: Autonavigate: MC:", mc)
						this.mc = mc;
						this.emit(Constants.SELECT_MC)
						key = null;*/
					}

				}

				if (key) {

					//const year = Number.parseInt(key);

					if (this.mc && this.mc.years && this.mc.years.length > 0) {

						const year = this.mc.years.find(year => year.year == key)

						if (year) {
							this.selectYear(year);
							key = null;
						}

					}

				}

				if (key) {

					const productId = Number.parseInt(key);

					const product = this.productList[productId];

					if (product) {
						this.filteredProductList = [product];
						this.emit(Constants.PRODUCT_LIST_CHANGED);
						//this.product = product;
						//this.emit("RELOAD_THIS");
						key = null;
					}


				}

				if (key == "outlet") {

					this.onlyOutlet = true
					this.filterProductList();
					key = null

				}

				/*
				if (key){

						this.setSearchProductText(key);
						key = null;
						//const product = this.productList.find(product => product.artnr == key.toLowerCase());

					  
						//if (product){
						//    //this.setSearchProductText(product.artnr);
						//}
				}*/






			})

		}
		//this.filterProductList();
		this.autoNavigateToList = [];
	}

	selectYear(year) {
		this.year = year;
		this.brand = null

		this.resetSearchProductText()

		this.emit(Constants.YEAR_CHANGED);
		this.filterProductList();
	}

	selectBrand(brandId) {

		this.brand = this.mcTree[brandId]; // hämtar mc till en brand ur mcTree (alltså själva "grenen")

		const modells = this.brand.modells; // kopiera listan på modellen så att den kan nollas senare

		//console.log("FP: Brand: brandId:", brandId);
		//console.log("FP: Brand: this.mcTreeSelected.length: ", this.mcTreeSelected.length);

		for (let i = 0; i < this.mcTreeSelected.length; i++) { // Loopa listan av brands och lägg in modeller på den valda, ta bort modeller på resten. 

			var brand = this.mcTreeSelected[i];

			if (brand.id == this.brand.id) {
				brand.modells = modells
				//this.mcTreeSelected[i] = this.brand;
				//console.log("FP: Brand: update:", this.brand.id);
			} else {
				brand.modells = []; // nollar den kopierade listan på modeller
				//console.log("FP: Brand: removeModells:", brand.name);
			}

		}

		this.year = null
		this.mc = null

		this.resetSearchProductText()

		this.emit(Constants.BRAND_SELECTED)
		this.emit(Constants.MC_TREE_CHANGED)
		this.filterProductList();
	}

	setSearchProductText(searchProduct) {
		this.searchProduct = searchProduct;
		this.filterProductList(true);

		this.searchResultMcList = this.filterMcList(this.searchProduct.toLowerCase())
		this.searchResultMcList = this.searchResultMcList.slice(0, 50);

		this.searchResultCategoryList = this.filterCategoryList(this.searchProduct.toLowerCase())
		this.searchResultCategoryList = this.searchResultCategoryList.slice(0, 50);


		this.searchResultManufactorList = this.filterManufactorList(this.searchProduct.toLowerCase())
		this.searchResultManufactorList = this.searchResultManufactorList.slice(0, 50)
		//alert(this.mcList.length);

		this.emit(Constants.SEARCH_RESULT_CHANGED);
		this.emit(Constants.SEARCH_PRODUCT_TEXT_CHANGED);
	}

	selectMc(mc) {
		this.mc = mc
		this.year = null
		this.brand = null

		console.log("FP: Select mc", this.mc);
		this.resetSearchProductText()

		if (this.mc == null) {
			this.searchMc = "";
			this.emit(Constants.SEARCH_MC_TEXT_CHANGED);
		}

		this.emit(Constants.MC_CHANGED);
		this.emit(Constants.YEAR_CHANGED);
		this.filterProductList();
	}

	selectCategory(category) {
		this.category = category
		this.resetSearchProductText()
		this.emit(Constants.CATEGORY_SELECTED)

		if (this.category == null) {
			this.searchCategory = "";
			this.emit(Constants.SEARCH_CATEGORY_TEXT_CHANGED);
		}

		this.filterProductList();
	}

	selectBikeType(bikeType) {
		this.bikeType = bikeType
		this.mc = null
		this.brand = null;
		this.year = null;
		this.resetSearchProductText()
		this.emit(Constants.BIKE_TYPE_SELECTED)
		this.filterProductList();
	}

	selectManufactor(manufactor) {
		this.manufactor = manufactor
		this.resetSearchProductText()
		this.emit(Constants.MANUFACTOR_SELECTED)
		this.filterProductList();

		if (manufactor == null) {
			this.searchManufactor = "";
			this.emit(Constants.SEARCH_MANUFACTOR_TEXT_CHANGED);
		}

	}

	handleActions(action) {
		console.log("action!!", action);

		console.log("FP: URL: ACTION:", action);

		switch (action.type) {


			case Constants.SET_SEARCH_MC_TEXT: {
				this.searchMc = action.text
				this.filterMc();
				this.emit(Constants.SEARCH_MC_TEXT_CHANGED);
				break;
			}

			case Constants.SET_SEARCH_PRODUCT_TEXT: {

				this.setSearchProductText(action.text);

				break;
			}


			case Constants.SET_SEARCH_CATEGORY_TEXT: {
				this.searchCategory = action.text;
				this.filterCategory();
				this.emit(Constants.SEARCH_CATEGORY_TEXT_CHANGED)
				break;
			}



			case Constants.SET_SEARCH_MANUFACTOR_TEXT: {
				this.searchManufactor = action.text;
				this.filterManufactor();
				this.emit(Constants.SEARCH_MANUFACTOR_TEXT_CHANGED);
				break;
			}

			case "GET_PRODUCT_LIST_COMPLETE": {
				//alert("GET_PRODUCT_LIST_COMPLETE" + action.productList)
				//this.productList = action.productList;
				var mainThis = this
				//mainThis.productList = [];


				for (let i = 0; i < action.productList.length; i++) { // Loopa listan och fyll på produkt-objektet

					//console.log(action.productList[i]);

					var product = action.productList[i];

					this.productList[product.id] = product;

				}

				this.filterProductList();

				this.modellCreated.productListCreated = action.log.created;




				break;
			}

			case Constants.GET_PRODUCT_COMPLETE: {

				//alert(action.product);

				if (this.product == null) { // om vi hunnit stänga produkten före den hämtats
					break;
				}

				this.product = action.product;

				// uppdatera även produkten i produktlistan
				if (this.product && this.product.id > 0) {
					this.productList[this.product.id] = this.product;
				}

				//alert(this.productList[this.product.id].generated);

				//this.filterProductList()



				this.emit(Constants.PRODUCT_CHANGED);
				break
			}

			case Constants.GET_PRODUCT_FROM_URL_COMPLETE: {

				this.product = action.product;

				// uppdatera även produkten i produktlistan
				if (this.product && this.product.id > 0) {
					this.productList[this.product.id] = this.product;
				}


				this.emit(Constants.PRODUCT_CHANGED);
				this.emit(Constants.PRODUCT_DETAILS_HIDE_SPINNER);
				break
			}


			case Constants.GET_PRODUCT_READMORE_COMPLETE: {

				this.product = null;
				this.currentProduct = action.product;

				console.log("FP: GET_PRODUCT_READMORE_COMPLETE ", action);

				// uppdatera även produkten i produktlistan
				if (action.product && action.product.id > 0) {
					this.productList[action.product.id] = action.product; // FP: Merga produkter här om det behövs
				}

				//alert(this.productList[this.product.id].generated);

				//this.filterProductList() // FP: Tog bort denna för att inte trassla till sorteringen

				//if (this.filteredProductList && )
				//action.currentProduct = action.product;
				/*alert(action.currentProduct.id);
				action.currentProduct.name = action.product.name;
				action.currentProduct.mcList = action.product.mcList;
				action.currentProduct.images = action.product.images;
				action.currentProduct.generated = action.product.generated;
*/
				var list = [];

				for (const product of this.filteredProductList) { // uppdatera befintliga listan av filtererade produkter

					list.push(this.productList[product.id]);
				}

				this.filteredProductList = list;

				this.emit(Constants.PRODUCT_LIST_CHANGED);
				this.emit(Constants.UPDATE_URL);
				break
			}

			case Constants.GET_BASE_MODEL_COMPLETE: {


				//console.log("FP:", action.data.manufactorList);

				this.manufactorList = action.data.manufactorList.sort(this.sortListByName);



				this.mcList = action.data.mcList;
				this.categoryList = action.data.categoryList;
				this.bikeTypeList = action.data.bikeTypeList;


				//this.mcTree = action.baseModel.mcTree;

				//var mainThis = this

				//alert(action.baseModel.mcTree.length);

				this.mcTree = [];

				// mc-trädet ligger uppdelat på brand
				for (let i = 0; i < action.data.mcTree.length; i++) {

					//console.log(action.productList[i]);

					var brand = action.data.mcTree[i];

					this.mcTree[brand.id] = brand;


				}

				this.brandList = action.data.brandList.sort(this.sortListByName);

				this.getBrandList()

				this.categoryTreeMC = action.data.categoryTreeMC;
				this.categoryTreeBike = action.data.categoryTreeBike;
				this.categoryTreeMotul = action.data.categoryTreeMotul;




				this.filterProductList(); // FP: kör denna så produkterna uppdateras med rätt data

				//ta responset och jacka in mcTree  i modellen
				//this.mcTree = action.baseModel.mcTree;

				this.filterManufactor();
				//this.filterMc();

				//this.emit(Constants.MANUFACTOR_LIST_CHANGED);

				this.modellCreated.baseModelCreated = action.data.log.created;

				this.emit(Constants.CATEGORY_TREE_CHANGED);
				this.emit(Constants.BIKE_TYPE_LIST_CHANGED);

				break;
			}

			case Constants.GET_START_MODEL_COMPLETE: {


				this.startModel = action.startModel;
				this.emit(Constants.START_MODEL_CHANGED);
				break;
			}

			case Constants.LOGIN_REQUEST_COMPLETE: {

				if (action.loginToken == null) {

					if (action.token == null) { // login via knapp
						this.loginMessage = "Kunde inte logga in"
					} else {
						this.loginMessage = null // background login
					}

					this.customer = null;
					this.emit(Constants.LOGIN_FAILED);
					this.emit(Constants.ORDER_CHANGED);
					this.emit(Constants.LOGIN_MESSAGE_CHANGED);
					this.emit(Constants.CUSTOMER_CHANGED);
					this.emit(Constants.LOGIN_STATUS_CHANGED);
				} else {
					//alert("Inloggad");
					this.loginToken = action.loginToken;
					this.loginMessage = null;
					this.loginVisible = false;

					if (action.orderId && action.orderId > 0) {
						this.orderId = action.orderId
					}

					this.customer = action.customer
					this.emit(Constants.LOGIN_SUCCEED);
					this.emit(Constants.ORDER_CHANGED);
					this.emit(Constants.LOGIN_MESSAGE_CHANGED);
					this.emit(Constants.CUSTOMER_CHANGED);
					this.emit(Constants.LOGIN_STATUS_CHANGED);

				}

				break;
			}

			case Constants.CUSTOMER_REQUEST_COMPLETE: {
				this.expireMinutes = 0; // om vi höll på att bli utloggade men appendade
				this.customer = action.customer;
				this.loginToken = action.loginToken;
				this.emit(Constants.CUSTOMER_CHANGED);
				break;
			}

			case Constants.GET_ORDER_COMPLETE: {

				this.customer = action.customer

				if (action.selectOrderId && this.orderId == null) {
					this.orderId = action.selectOrderId
				}

				this.emit(Constants.ORDER_CHANGED);
				this.emit(Constants.CUSTOMER_CHANGED);
				break;
			}

			case "NEW_REQUEST": {

				this.requestCount++;
				this.emit(Constants.REQUEST_COUNT_CHANGED);
				break;
			}

			case Constants.REQUEST_COMPLETE: {
				this.requestCount--;

				if (this.requestCount < 0) {
					this.requestCount = 0;
				}

				this.autoNavigateTo();

				this.emit(Constants.REQUEST_COUNT_CHANGED);
				break;
			}

			case Constants.NEW_ORDER_REQUEST: {

				this.orderRequestCount++;
				this.emit(Constants.REQUEST_COUNT_CHANGED);
				break;
			}

			case Constants.ORDER_REQUEST_COMPLETE: {

				this.orderRequestCount--;


				if (this.orderRequestCount < 0) {
					this.orderRequestCount = 0;
				}

				this.emit(Constants.REQUEST_COUNT_CHANGED);
				break;

			}

			case Constants.NEW_PRODUCT_REQUEST: {

				//this.productRequestCount++;

				if (action.productId && !this.productRequestList.includes(action.productId)) {
					this.productRequestList.push(action.productId);
					console.log("FP: Added productRequest: " + action.productId + "", this.productRequestList);
				}

				this.emit(Constants.REQUEST_COUNT_CHANGED);
				break;
			}

			case Constants.PRODUCT_REQUEST_COMPLETE: {

				const index = this.productRequestList.indexOf(action.productId)

				if (action.productId && index > -1) {


					delete (this.productRequestList[index])
				}

				this.emit(Constants.REQUEST_COUNT_CHANGED);
				break;

			}

			case Constants.SELECT_MC: {


				this.groupBy = Constants.GROUP_BY_MANUFACTOR;
				this.selectMc(action.mc);
				this.emit(Constants.UPDATE_URL);
				break;

			}

			case Constants.SELECT_YEAR: {
				/*this.year = action.year;
				this.brand = null

				this.resetSearchProductText()
			  
				this.emit(Constants.YEAR_CHANGED);
				this.filterProductList();*/
				this.selectYear(action.year);
				this.emit(Constants.UPDATE_URL);
				break;
			}

			case Constants.SELECT_PRODUCT: {
				this.product = action.product;
				this.currentProduct = null;
				this.emit(Constants.PRODUCT_CHANGED);
				this.emit(Constants.UPDATE_URL);

				break;
			}

			case Constants.SELECT_PRODUCT_DONT_UPDATE_URL: {
				this.product = action.product;
				this.currentProduct = null;
				this.emit(Constants.PRODUCT_CHANGED);
				//this.emit(Constants.UPDATE_URL);

				break;
			}

			case Constants.HIDE_PRODUCT: {
				this.product = null;
				this.currentProduct = null;
				this.emit(Constants.PRODUCT_CHANGED);
				break;
			}

			/*case Constants.SELECT_PRODUCT_BY_ID:{

					this.product = this.productList[action.productId]
;
					if(!this.product){
							this.errorMessage = "Kunde inte hitta produkt";
							this.emit(Constants.ERROR_MESSAGE_CHANGED);
					}
					this.emit(Constants.PRODUCT_CHANGED)
					break;
			}*/

			case "SELECT_PRODUCT_TYPE": {
				this.productType = action.productType;
				//this.emit("productTypeChanged");


				/*
				this.searchCategory = "";
				this.searchManufactor = "";
				this.searchProduct = "";
				this.searchMc = "";

				this.bikeType = null;
				this.brand = null;
				this.mc = null;
				this.year = null;
				*/

				this.removeAllFilters();
				this.hideAllDropMenu();

				this.filterManufactor();
				this.emit(Constants.PRODUCT_TYPE_CHANGED)
				this.emit(Constants.PRODUCT_LIST_CHANGED);
				this.emit(Constants.CATEGORY_TREE_CHANGED);
				this.emit(Constants.CATEGORY_LIST_CHANGED)

				this.emit(Constants.MANUFACTOR_LIST_CHANGED);
				this.emit(Constants.MC_TREE_CHANGED);
				this.emit(Constants.BIKE_TYPE_SELECTED);

				this.emit(Constants.SEARCH_CATEGORY_TEXT_CHANGED);
				this.emit(Constants.SEARCH_PRODUCT_TEXT_CHANGED);
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED);

				this.filterCategory();
				this.filterProductList();
				break;
			}

			case Constants.DROP_MENU_MC_VISIBILITY: {
				this.dropMenuMcVisible = action.visible;
				this.dropMenuCategoryVisible = false
				this.dropMenuBikeTypeVisible = false
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED)
				break;
			}

			case Constants.SIDE_MENU_MC_VISIBILITY: {
				this.sideMenuVisible = action.visible;
				this.emit(Constants.SIDE_MENU_MC_VISIBILITY_CHANGED)
				break;
			}

			case Constants.LOGIN_VISIBILITY: {
				this.hideAllDropMenu();
				this.loginVisible = action.visible;
				this.emit(Constants.LOGIN_VISIBILITY_CHANGED)
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED);
				break;
			}

			case Constants.SEARCH_BOX_TOGGLE: {
				this.searchBoxVisible = !this.searchBoxVisible;
				this.emit(Constants.SEARCH_BOX_CHANGED)
				break;
			}

			case Constants.SIDE_MENU_TOGGLE: {
				this.sideMenuVisible = !this.sideMenuVisible;
				this.emit(Constants.SIDE_MENU_MC_VISIBILITY_CHANGED)
				break;
			}

			case Constants.SELECT_BRAND: {

				if (action.brand) {
					this.selectBrand(action.brand.id);
				} else {
					//this.selectBrand(0);
					this.year = null
					this.mc = null
					this.brand = null

					this.resetSearchProductText()

					this.emit(Constants.BRAND_SELECTED)
					this.emit(Constants.MC_TREE_CHANGED)
					this.filterProductList();
				}
				this.emit(Constants.UPDATE_URL);
				break;
			}

			case Constants.SELECT_CATEGORY: {

				this.selectCategory(action.category);

				if (action.category) {
					console.log("CategoryKey: " + action.category.key);
				}

				this.emit(Constants.UPDATE_URL);
				break;
			}

			case Constants.SELECT_CATEGORY_GRID: {



				//const category = this.categoryList.find( (category) => category.categoryId == action.categoryId);

				const category = this.findCategory(this.categoryTreeMC, action.categoryKey);

				//alert(category);
				//updateSearchField

				if (category) {
					//this.selectCategory(category);
					//this.emit(Constants.UPDATE_URL);
					//this.hideAllDropMenu();
					//this.dropMenuCategoryVisible = false;
					//this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED)

					if (action.updateSearchField) {
						this.selectCategory(category);
						this.emit(Constants.UPDATE_URL);
						this.searchCategory = category.name;
						this.filterCategory();
						this.emit(Constants.SEARCH_CATEGORY_TEXT_CHANGED);
					} else {
						this.selectCategory(category);
						this.emit(Constants.UPDATE_URL);
						this.hideAllDropMenu();
						this.dropMenuCategoryVisible = false;
						this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED);

						this.searchCategory = "";
						this.filterCategory();
						this.emit(Constants.SEARCH_CATEGORY_TEXT_CHANGED);
					}
				}

				//this.hideAllDropMenu();
				//this.dropMenuCategoryVisible = action.visible;
				/*this.dropMenuMcVisible = false;
				this.dropMenuManufactorVisible = false;
				this.dropMenuBikeTypeVisible = false*/
				//this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED)

				/*this.selectCategory(category);
				this.emit(Constants.UPDATE_URL);
			 */

				break;
			}

			case Constants.SELECT_MANUFACTOR: {

				this.selectManufactor(action.manufactor)

				this.emit(Constants.UPDATE_URL);
				this.filterManufactor();
				break;
			}

			case Constants.SELECT_BIKE_TYPE: {

				this.selectBikeType(action.bikeType);
				break;
			}

			case Constants.DROP_MENU_MC_TOGGLE: {
				const newVisibility = !this.dropMenuMcVisible
				this.hideAllDropMenu();
				this.dropMenuMcVisible = newVisibility;

				//this.dropMenuCategoryVisible = false
				//this.dropMenuBikeTypeVisible = false
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED)
				break;
			}

			case Constants.DROP_MENU_BIKE_TYPE_VISIBILITY: {
				this.hideAllDropMenu();
				this.dropMenuBikeTypeVisible = action.visible
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED);
				break;
			}

			case Constants.DROP_MENU_CATEGORY_VISIBILITY: {
				this.hideAllDropMenu();
				this.dropMenuCategoryVisible = action.visible;
				/*this.dropMenuMcVisible = false;
				this.dropMenuManufactorVisible = false;
				this.dropMenuBikeTypeVisible = false*/
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED)
				break;
			}

			case Constants.DROP_MENU_CATEGORY_TOGGLE: {
				const newVisibility = !this.dropMenuCategoryVisible;
				this.hideAllDropMenu();
				this.dropMenuCategoryVisible = newVisibility;
				/*
				this.dropMenuMcVisible = false;
				this.dropMenuManufactorVisible = false;*/
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED)
				break;
			}

			case Constants.DROP_MENU_MANUFACTOR_VISIBILITY: {
				this.hideAllDropMenu()
				this.dropMenuManufactorVisible = action.visible;
				this.filterManufactor();
				/*
				this.dropMenuMcVisible = false;
				this.dropMenuCategoryVisible = false;*/
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED)
				break;
			}

			case Constants.DROP_MENU_MANUFACTOR_TOGGLE: {
				const newVisibility = !this.dropMenuManufactorVisible;
				this.hideAllDropMenu();
				this.dropMenuManufactorVisible = newVisibility;

				this.filterManufactor();
				/*this.dropMenuMcVisible = false;
				this.dropMenuCategoryVisible = false;
				this.dropMenuBikeTypeVisible = false
*/
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED)
				break;
			}

			case Constants.SELECT_VIEWTYPE: {
				this.viewType = action.viewType
				this.emit(Constants.VIEWTYPE_CHANGED)
				this.emit(Constants.UPDATE_URL);
				break;
			}

			case Constants.SET_LOGIN_MESSAGE: {
				this.loginMessage = action.message
				this.emit(Constants.LOGIN_MESSAGE_CHANGED)
				break;
			}

			case Constants.SET_ERROR_MESSAGE: {
				this.errorMessage = action.errorMessage;
				this.expireMinutes = 0;
				this.emit(Constants.ERROR_MESSAGE_CHANGED)
				break;
			}

			case Constants.SET_ORDER_ID: {
				this.orderId = action.orderId
				this.emit(Constants.ORDER_CHANGED)
				break;
			}

			case Constants.PASSWORD_CHANGED: {
				this.emit(Constants.PASSWORD_CHANGED);
				break;
			}

			case Constants.LOGOUT: {
				this.loginToken = null
				this.customer = null
				this.displayLogoutMessage = true

				this.emit(Constants.LOGOUT);
				this.emit(Constants.ORDER_CHANGED);
				this.emit(Constants.CUSTOMER_CHANGED);
				break;
			}

			case Constants.SET_FULLSIZE_IMAGE: {
				this.fullsizeImage = action.fullsizeImage
				this.emit(Constants.FULLSIZE_IMAGE_CHANGED)
				break;
			}

			case Constants.SET_MESSAGE: {
				this.message = action.message
				this.emit(Constants.MESSAGE_CHANGED);
				break;
			}

			case Constants.LOGINTOKEN_WILL_EXPIRE: {
				this.errorMessage = action.errorMessage
				this.expireMinutes = action.expireMinutes
				this.emit(Constants.ERROR_MESSAGE_CHANGED);
				break;
			}

			case Constants.LOGINTOKEN_EXPIRED: {
				this.loginToken = null
				this.customer = null
				this.expireMinutes = action.expireMinutess
				this.errorMessage = action.errorMessage
				this.emit(Constants.ERROR_MESSAGE_CHANGED);
				this.emit(Constants.LOGIN_STATUS_CHANGED);
				this.emit(Constants.PRODUCT_LIST_CHANGED);
				this.emit(Constants.CUSTOMER_CHANGED);
				break;
			}

			case Constants.SET_CUSTOMER_NEWS_VISIBILITY: {
				this.customNewsVisibility = action.visibility;
				this.emit(Constants.CUSTOMER_NEWS_VISIBILITY_CHANGED);
				break;
			}

			case Constants.SET_CURRENT_PRODUCT: {
				this.currentProduct = action.product
				break;
			}

			case Constants.SHOW_HISTORY: {

				//alert(this.historyList.length);

				this.filteredProductList = [];


				for (const productId of this.historyList) {

					const product = this.productList[productId];

					if (product) {
						this.filteredProductList.push(product);
					}


				}

				this.filteredProductListUnlimited = this.filteredProductList;
				this.currentProductListLength = this.filteredProductList.length;

				this.emit(Constants.PRODUCT_LIST_CHANGED);

				break;
			}

			case Constants.ADD_TO_HISTORY: {

				const index = this.historyList.indexOf(action.productId);

				if (index > -1) {
					this.historyList.splice(index, 1);
				}


				this.historyList.unshift(action.productId);

				break;
			}

			case Constants.ADD_IMPORT_MESSAGE: {

				/*
				var order = this.customer.orderList.find( (order) => (order.orderId == action.orderId) );

				//alert(order);

				if (this.importMessageList == null){
						order.importMessageList = []
				}

				const importMessage = {message: action.message, messageType: action.messageType}
*/
				const importMessage = { message: action.message, messageType: action.messageType, orderId: action.orderId }
				this.importMessageList.unshift(importMessage);


				this.emit(Constants.IMPORT_MESSAGES_CHANGED);

				break;
			}

			case Constants.REMOVE_IMPORT_MESSAGES: {
				this.importMessageList = [];
				this.emit(Constants.IMPORT_MESSAGES_CHANGED);
				break;
			}

			case Constants.IMPORT_ONE_PRODUCT_COMPLETE: {
				this.emit(Constants.IMPORT_ONE_PRODUCT_COMPLETE);
				break;
			}

			case Constants.SET_SORT_BY: {
				this.sortBy = action.sortBy;
				this.filterProductList();
				this.emit(Constants.SORT_BY_CHANGED);
				break;
			}

			case Constants.SET_GRUOP_BY: {
				this.groupBy = action.groupBy;
				this.filterProductList();
				this.emit(Constants.GROUP_BY_CHANGED);
				break;
			}

			case Constants.SET_ONLY_STOCK: {
				this.onlyStock = action.onlyStock
				this.filterProductList();
				this.emit(Constants.ONLY_STOCK_CHANGED);
				break;
			}

			case Constants.SET_ONLY_OUTLET: {
				this.onlyOutlet = action.onlyOutlet
				this.filterProductList();
				this.emit(Constants.ONLY_OUTLET_CHANGED);
				this.emit(Constants.UPDATE_URL);
				break;
			}

			case Constants.SHOW_ALL_PRODUCTS: {
				this.showAll = true;
				this.filterProductList();
				break;
			}

			case Constants.APPEND_PRODUCT_LIST: {
				this.productLimit = this.productLimit + this.defaultProductLimit;
				this.filterProductList();
				break;
			}

			case Constants.HIDE_ALL_DROPDOWN: {
				this.hideAllDropMenu();
				this.emit(Constants.DROP_MENU_VISIBILITY_CHANGED);
				break;
			}

			case Constants.ENABLE_DEBUGMODE: {
				this.debugMode = true;
				break;
			}

		}
	}
}



const dataStore = new DataStore;

dispatcher.register(dataStore.handleActions.bind(dataStore))
window.dispatcher = dispatcher;
//window.dataStore = dataStore;

export default dataStore;