import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import CategoryTreeNode from "./CategoryTreeNode"
var Constants = require('../Constants');

export default class CategoryTree extends React.Component{

    
    constructor(){
        super();
        this.state = {categoryTree: DataStore.getCategoryTree()}
    }
    
    componentDidMount() {
    
        DataStore.on(Constants.CATEGORY_TREE_CHANGED, () => {
            this.setState({categoryTree: DataStore.getCategoryTree()})
        })
    }

    render(){

        

        return (
            <div className='categoryTree' id='categoryTree'>
             
            { this.state.categoryTree.map((item, index) => (  <div><CategoryTreeNode category={item} level='' key={item.id} /> </div> )) }
            
        </div>

        )
    }
}