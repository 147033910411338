import React from "react"

import buttonRight from '../../../gfx/button_right_dark.png';
import iconMC from '../../../gfx/icon-mc_dark.png';
import iconCykel from '../../../gfx/icon-cykel_dark.png';
import * as DataActions from "../../actions/DataActions";
import DataStore from "../../stores/DataStore";
import { NavLink } from 'react-router-dom'

export default class FindBrand extends React.Component{

    constructor() {
        super();
        
        
      }
    
   
      onClick(){
          DataActions.selectProductType(this.props.brand.type);

          //const manufactor = DataStore.find
          //alert(this.props.brand.id);
      }
    
    
    render(){

        const imagePath = "https://public.boove.se/gfx/logos/" + this.props.brand.id + ".jpg"
        
        const brandId = "brand_" + this.props.brand.id;

        const url = "/mc/list/" + this.props.brand.key;

        return (
            <div className='findBrand' >
                <div id={brandId} className='findBrandAnchor'></div>
                <div className="imageView">
                    <div className='imageWrapper'>
                        <img src={imagePath} />
                    </div>
                </div>
                <div className='brandDetails' >
                    
                    <h1>{this.props.brand.name}</h1>
                    
                    { this.props.brand.type == 1 ?  <img className='type' src={iconMC} /> : null}
                    { this.props.brand.type == 2 ?  <img className='type' src={iconCykel} /> : null}
                   
       
                    <h3>{this.props.brand.description}</h3>

                    { this.props.brand.www  && this.props.brand.www.length > 2 ? 
                    <div className="www">
                        <a href={this.props.brand.www} target='_blank'>{this.props.brand.www}</a>
                        <img src={buttonRight} />
                    </div> : null }

                </div>
                
                <NavLink to={url} onClick={this.onClick.bind(this)} >
                 <div className='footer' >
                    <h1>Visa produkter</h1>
                </div>
                </NavLink>

                
            </div>

        )
    }
}

