import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";

import mcAprilia from '../../gfx/mc-logos/aprilia.png'; 
import mcBMW from '../../gfx/mc-logos/bmw.png'; 
import mcDucati from '../../gfx/mc-logos/ducati.png'; 
import mcHonda from '../../gfx/mc-logos/honda.png'; 
import mcHD from '../../gfx/mc-logos/hd.png'; 
import mcHuskvarna from '../../gfx/mc-logos/huskvarna.png'; 
import mcKawasaki from '../../gfx/mc-logos/kawasaki.png'; 
import mcKTM from '../../gfx/mc-logos/ktm.png'; 
import mcMVAgusta from '../../gfx/mc-logos/mv-agusta.png'; 
import mcSuzuki from '../../gfx/mc-logos/suzuki.png';
import mcTriumph from '../../gfx/mc-logos/triumph.png'; 
import mcYamaha from '../../gfx/mc-logos/yamaha.png'; 


import mc from '../../gfx/mc-logos/mc-marke.png'; 

export default class CategoryGrid extends React.Component{

    
    constructor(){
        super();
        //this.state = {mcList: DataStore.getMcList(), searchMc: DataStore.getSearchMc}
        //this.handleChange = this.handleChange.bind(this)
    }
    

   /*componentDidMount(){
    
        DataStore.on("xx", () => {
            //this.setState({mcList: DataStore.getMcList()})
        })
    }*/


    /*handleChange(event) {
        
        let value = event.target.value;
        this.setState({searchMc: value} );
        DataActions.searchMcTextChanged(value)  
        
      }*/

    onClick(categoryKey, updateSearchField){
        


        DataActions.selectCategoryGrid(categoryKey, updateSearchField);

        //DataActions.dropMenuCategoryVisibility(false);

 
    }




    render(){

        var selectedCategoryId = 0;

        if (DataStore.getCategory()){
            const category = DataStore.getCategory();
            selectedCategoryId = category.id;
        }

        return (
            <div className='categoryGrid'>
                <table>
                    <tbody>
                        <tr>
                            <td  onClick={()=>{this.onClick('5-avgassystem', true)}} className={ selectedCategoryId == 5 ? "selected pointer" : 'pointer'}> <b>Avgassystem</b></td>
                            <td  onClick={()=>{this.onClick('26-kraschpuckar--skydd', true)}}   className={ selectedCategoryId == 26 ? "selected pointer" : 'pointer'}> <b>Kraschpuckar och&nbsp;skydd</b></td>
                            <td  onClick={()=>{this.onClick('32-regskyltshallare', true)}}   className={ selectedCategoryId == 32 ? "selected pointer" : 'pointer'}> <b>Regskyltshållare</b></td>
                            
                        </tr>
                        <tr>
                            <td  onClick={()=>{this.onClick('211-packutrustning', true)}}   className={ selectedCategoryId == 211 ? "selected pointer" : 'pointer'}> <b>Packutrustning</b></td>
                            <td  onClick={()=>{this.onClick('87-kedja--drev', true)}}   className={ selectedCategoryId == 87 ? "selected pointer" : 'pointer'}> <b>Kedja och drev</b></td>
                            <td  onClick={()=>{this.onClick('56-depastod', true)}}   className={ selectedCategoryId == 56 ? "selected pointer" : 'pointer'}> <b>Depåstöd</b></td>
                            
                            
                        </tr>
                        <tr>
                            <td onClick={()=>{this.onClick('263-bluetooth-headset', false)}}   className={ selectedCategoryId == 263 ? "selected pointer" : 'pointer'}> <b>Intercom</b></td>
                            <td onClick={()=>{this.onClick('195-greppsatser', false)}}   className={ selectedCategoryId == 195 ? "selected pointer" : 'pointer'}> <b>Greppsatser</b></td>
                            <td onClick={()=>{this.onClick('154-kapglas--vindrutor', true)}}   className={ selectedCategoryId == 154 ? "selected pointer" : 'pointer'}> <b>Kåpglas</b></td>
                           
                           
                        </tr>
                    </tbody>
                </table>
              
                
            </div>

        )
    }
}
/*
  <div className='brand' onClick={()=>{this.onClick(2044)}}><h1>APRILIA</h1></div>
                <div className='brand' onClick={()=>{this.onClick(4424)}}><img src={mcHonda} /></div>
                <div className='brand' onClick={()=>{this.onClick(4931)}}><h1>YAMAHA</h1></div>
                */