import React from "react"

import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";
import Order from "./Order";
import button_reload from '../../gfx/button_reload_dark.png';
import { NavLink, useParams } from 'react-router-dom'

import '../../css/Customer.css';
import CustomerNews from "./CustomerNews";
var Constants = require('../Constants');

export default class OrderList extends React.Component{

    constructor(props){
        const customer = DataStore.getCustomer()

        super(props);
        this.state = { customer: customer,
                        companyTelephone: customer && customer.company ? customer.company.telephone:"",
                        companyUrl: customer && customer.company ? customer.company.url:"",
                        companyEmail: customer && customer.company ? customer.company.email:"",
                        companyPostaddress: customer && customer.company ? customer.company.postaddress:"",
                        companyPostnumber: customer && customer.company ? customer.company.postnumber:"",
                        companyCity: customer && customer.company ? customer.company.city:"",
                        companyEmailorder: customer && customer.company ? customer.company.emailorder:"",
                        

                        userEmail: customer && customer.company ?customer.userEmail:"",
                        userOldPassword: "",
                        newPassword: "",
                        newPassword2: "", 
                        errorMessage: null, 
                        message: null,
                        order: DataStore.getOrder()
                    }

                    
    }
    
    componentDidMount() {
    
        DataStore.on(Constants.CUSTOMER_CHANGED, () => { 

            const customer = DataStore.getCustomer();
            this.setState({customer: customer});  
            
        })

            
        DataStore.on(Constants.GET_ORDER_COMPLETE, () => { 

            const customer = DataStore.getCustomer();
            this.setState({customer: customer});  
            
        })
       
        DataStore.on(Constants.ORDER_CHANGED, () => {
            this.setState({customer: DataStore.getCustomer()});  
            this.setState({order: DataStore.getOrder()})
        })

        DataStore.on(Constants.LOGOUT, () => {  
            if (DataStore.handleLogoutMessage()){
                alert("Du har blivit utloggad, stäng webbläsaren");
            }
        }) 
        //DataActions.order(DataStore.getLoginToken());
    }

    onCreateOrderClick(){
        
        if (!window.confirm("Vill du skapa en ny order")){
            return;
        }

        DataActions.order(DataStore.getLoginToken(), {"action":"createOrder", "orderId":"0"});
    }

   
    onLogoutClick(){

        if (!window.confirm("Vill du logga ut?")){
            return;
        }

        
        
         

        //document.cookie = "login=;path=/;max-age=1;";
        //alert("Du måste eventuellt rensa inloggningsuppifter från webbläsaren samt starta om den.");

        /*
        if (!window.confirm("Vill du logga ut?")){
            return;
        }

        if (document.cookie.length > 5 && window.confirm("Stäng av automatisk inloggning? ")){
            document.cookie = "login=;path=/;max-age=1;";
            alert("Du måste eventuellt rensa inloggningsuppifter från webbläsaren samt starta om den.");
        }*/


        DataActions.logout(DataStore.getLoginToken())
    }

    onLoginClick(){
        DataActions.loginVisibility(true)
    }


    hideError(){
        this.setState({errorMessage:null})
    }

    render(){

       

        return (
            <div className='customer'>{ this.state.customer ? 
                
                <div>
                <div className='company'>
                <img className='reloadButton pointer' src={button_reload} title='Ladda om orderlista' onClick={() => {DataActions.order(DataStore.getLoginToken(), {"action":"reload"});}}/>
                    <h1> {this.state.customer.company.name} - ORDRAR</h1>
                    
                    
                   
                   
                </div>
                
                <div className='toolbar'>
                    <div className={ this.state.order ?  'buttonCreate pointer' : 'buttonCreate alert pointer' } onClick={this.onCreateOrderClick.bind(this)} >SKAPA NY ORDER</div>
                    <NavLink to="/customer" ><div className='button pointer' title='Visa företagsuppgifter och nyheter' >{this.state.customer.company.name}</div></NavLink>
                    <div className='button pointer' onClick={this.onLogoutClick.bind(this)}>LOGGA UT</div>
                </div>
                
                { this.state.customer.newsList && this.state.customer.newsList.length > 0 ?
                <CustomerNews customer={this.state.customer}/> : null }

                <div className='orderList'>
                <br/>
                <h3>Senaste ordrar</h3>
                { this.state.customer && this.state.customer.orderList.map((item, index) => (
                                
                                <Order key={item.orderId} order={item} 
                                    locked={item && item.orderStatus && item.orderStatus.status > 10} 
                                    resellerType={this.state.customer.company.resellerType} 
                                    selected={item && this.state.order &&  this.state.order.orderId == item.orderId}/>
                            )) 
                }              
                </div>

               

        </div> : <div onClick={this.onLoginClick.bind(this)} className='pointer'>Klicka här för att logga in</div> }

                
            </div> 

        )
    }
}