import React from "react"
import '../../css/ProductList.css';



export default class ProductListSection extends React.Component{



    render(){

        return (<div className='productListSection'><h3>{this.props.manufactorName}</h3></div>)        

    }
}