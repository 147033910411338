import React, { useState, useEffect } from 'react'


//import styled from 'styled-components'

//import placeHolder from '../../gfx/missing.jpg'; 

const placeHolder = {};

export const ProductGridImage = ({ src, alt, className, css }) => {

  const [imageSrc, setImageSrc] = useState(placeHolder)
  const [imageRef, setImageRef] = useState()


  useEffect(() => {
    let observer
    let didCancel = false

    
    //alert(imageRef);

    if (imageRef && imageSrc == placeHolder) {

      //alert("xx");
      try {
        
        if (IntersectionObserver) {
          observer = new IntersectionObserver(
            entries => {
              entries.forEach(entry => {
  
                // when image is visible in the viewport + rootMargin
                if ( !didCancel && (entry.intersectionRatio > 0 && entry.isIntersecting) ) {
                  //alert(1);
                  setImageSrc(css);
                  
                }
              })
            },
            {
              threshold: 0.01,
              rootMargin: '75%',
            }
          )
          observer.observe(imageRef)
        } else {
          // Old browsers fallback
          setImageSrc(css)
          observer = null;
        }

      } catch (error) {
        setImageSrc(css)
      }
      
    }
    return () => {
      didCancel = true
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef)
      }
    }
  })

  return  (<div className='imgbox' style={imageSrc} ref={setImageRef} title={src}></div>)

  //return  (<div className='imgbox' style={css}  ><div><img ref={setImageRef} src={imageSrc} alt={alt} title={alt} className={className} /></div></div>)

  /*return (<div className='imgbox' style={css} title={imageSrc} >
  <div>
      <ProductImage className='productImage' src={imageSrc} title={imageSrc}   />
  </div>
  
</div>)*/
}