import React from "react"
import DataStore from "../../stores/DataStore";
import * as DataActions from "../../actions/DataActions";
import "../../../css/Start.css"
import { NavLink, withRouter } from 'react-router-dom' 
import StartBrand from "./StartBrand";
import StartNews from "./StartNews";
import Product from "../Product";
import icon_mc from '../../../gfx/icon-mc.png';
import icon_cykel from '../../../gfx/icon-cykel.png';
import button_right from '../../../gfx/button_right_dark.png';

var Constants = require('../../Constants');

class StartProductType extends React.Component{

    constructor(props){
        super(props);
        this.state = { visible: this.props.visible }
        this.onProductClick = this.onProductClick.bind(this);
        this.onBrandClick = this.onBrandClick.bind(this);
    }

    

    onClickHeader(event){
        this.setState({visible: !this.state.visible})
    }

    onProductClick(product){
        /*DataActions.setCurrentProduct(null);
        DataActions.selectViewType("grid");
        DataActions.selectProductType(this.props.productType.id);
        */

        DataActions.getProduct(product.id, null, Constants.SELECT_PRODUCT_DONT_UPDATE_URL);

    }

    onBrandClick(clickedBrand){

        DataActions.setCurrentProduct(null);
        DataActions.selectProductType(this.props.productType.id);        
        const brand = DataStore.findManufactor(clickedBrand.id)
        DataActions.selectManufactor(brand);
        
    }

    onOutletClick(event){
        DataActions.setOnlyOutlet(true);
    }

    onFooterClick(event){
        DataActions.setCurrentProduct(null);
        DataActions.selectProductType(this.props.productType.id);        
    }

    render(){
        
        const cssVisible = "startProductType startBox  pt_" + this.props.productType.key;
        const cssHidden = "startProductType startBox closed pt_" + this.props.productType.key;

        const icon = (this.props.productType.key == "mc" ? icon_mc : icon_cykel)
        const productTypeUrl = this.props.productType.key + "/list/";
        //const productTypeOutletUrl = this.props.productType.key + "/list/outlet/";
        var openCount = 0;

        return (
           
                <div className={ this.state.visible ? cssVisible : cssHidden } > 
                    <div className='startHeader'  onClick={this.onClickHeader.bind(this)}><img src={icon} className='icon' /><h1>{this.props.productType.header}</h1></div>
                    
                    {this.state.visible ? <div>
                    <div className='startHtml' dangerouslySetInnerHTML={{__html: this.props.productType.html}}></div>
                    

                    {this.props.productType.newsList ? <div className='newsList'>
                        { this.props.productType.newsList && this.props.productType.newsList.length > 0 ? <h3 className='newsLabel'>Nyheter</h3> : null} 

                        {this.props.productType.newsList.map( (news, index) => { 
                            openCount++;
                            return <StartNews news={news} open={openCount == 1} /> } )}

                    </div>:null}
                    
                    


                    { this.props.productType.productListOutlet && this.props.productType.productListOutlet.length > 0 ? <div><h3 className='productListLabel'>Outlet {this.props.productType.header}</h3> 

                    <div className='productGrid'>
                        { this.props.productType.productListOutlet.map( (product, index) =>  <Product product={product} startPage={true} onClickCallback={this.onProductClick} /> ) }
                       

                    </div>
                    
                    
                    <div className='startFooter pointer' onClick={this.onOutletClick.bind(this)}>
                    
                        <h3 className='startFooterLabel'>{this.props.productType.footerOutlet}</h3> 
                        <img src={button_right} />
                    
                    </div>
                    
                    </div> : null }

                   

                    <h3 className='productListLabel'>Utvalda produkter / Nyheter</h3>

                    { this.props.productType.productList ? <div className='productGrid'>
                        { this.props.productType.productList.map( (product, index) =>  <Product product={product} startPage={true} onClickCallback={this.onProductClick} /> ) }
                    

                    </div> : null }

                    { this.props.productType.brandList ? <div className='brandListWrapper'><div className='brandList'>
                        <h3 className='brandLabel'>Varumärken</h3>
                        {this.props.productType.brandList.map( (brand, index) => <StartBrand brand={brand} onClickCallback={this.onBrandClick}   /> )}
                    
                    
                    </div></div> : null }


                    <NavLink to={productTypeUrl} onClick={this.onFooterClick.bind(this)}>
                    <div className='startFooter'>
                    
                        <h3 className='startFooterLabel'>{this.props.productType.footer}</h3> 
                        <img src={button_right} />
                    
                    </div>
                    </NavLink>

                    </div>: null}
                </div>
                
           
        )
    }
}

export default withRouter(StartProductType)