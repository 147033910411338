import React from "react"
import DataStore from "../../stores/DataStore";
import {NavLink, withRouter} from 'react-router-dom'
import "../../../css/FindReseller.css"
import buttonClose from '../../../gfx/button_close.png'; 
import buttonSearch from '../../../gfx/button_search.png';
import buttonRight from '../../../gfx/button_right_dark.png';
import FindReseller from "./FindReseller";
import icon_type_mc from '../../../gfx/icon-mc.png';
import icon_type_cykel from '../../../gfx/icon-cykel.png';
import icon_type_mc_off from '../../../gfx/icon-mc_off.png';
import icon_type_cykel_off from '../../../gfx/icon-cykel_off.png';
import StartNavigation from "../start/StartNavigation";


var Constants = require('../../Constants');

class FindResellerList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            
            filteredList: [],
            mc: this.props.resellerType != "cykel",
            bike: this.props.resellerType != "mc",
            search: (this.props.resellerType != "mc" && this.props.resellerType != "cykel" &&  this.props.resellerType != null ? this.props.resellerType:""),
            currentLetter: ""
        }

        this.filter()
    }

    componentDidMount(){

        DataStore.on(Constants.START_MODEL_CHANGED, () => { 
            this.filter()
        })

        this.filter()

    }
    onSearchChange(event){
        
        
        this.getUrl(this.state.mc, this.state.bike);
        
        const value = event.target.value
        this.setState({search: value}, this.filter);
    }

    getUrl(mc, bike){

        var url = "/butiker/";

        if (mc === bike){
                
        }else if(mc){
            url = "/butiker/mc";
            
        }else if(bike){

            url = "/butiker/cykel";
        }

        this.props.history.replace({ pathname: url})

    }
    
    filter(list){

        this.setState({currentLetter: ""});

        if (!list){
            list = DataStore.getStartModelResellerList();

            if (list == null){
                return;
            }

        }

        var filteredList = list.filter((company) => {
            


            if (!company){
                return false;
            }

            if ((this.state.bike && company.type.toUpperCase().indexOf("C") > -1) || (this.state.mc && company.type.toUpperCase().indexOf("M") > -1)){
               

            }else {
                return false;
            }

            

            if (this.state.search.length > 0){

                var search = this.state.search.toLowerCase();
                
                if (company.name.toLowerCase().indexOf(search) > -1) {
                    return true;
                }

                if (company.address.toLowerCase().indexOf(search) > -1) {
                    return true;
                }
                
                if (company.key.toLowerCase() == search) {
                    return true;
                }

                if (company.phone && company.phone.length > 3){
                    var phone = company.phone.toLowerCase();
                    phone = phone.replace("-", "")
                    phone = phone.replace(" ", "")

                    search = search.replace("-", "");
                    search = search.replace(" ", "");

                    if (phone.indexOf(search) > -1) {
                        return true;
                    }
                }

                return false;
            }
            
    
            return true;
        });

        filteredList.sort( (a, b) => a.name.toUpperCase() > b.name.toUpperCase())


        this.setState({filteredList})

        
    }

    onBikeClick(e){

        var newState = !this.state.bike;
        var mcState = this.state.mc;

        if (newState == false && this.state.mc == false ){
            mcState = true;
        }

        this.setState({mc: mcState}, this.filter)
        this.setState({bike: newState}, this.filter)
        this.getUrl(mcState, newState);
    }
    
    onMCClick(e){

        var newState = !this.state.mc;
        var bikeState = this.state.bike;

        if (newState == false && this.state.bike == false ){
            bikeState = true;
        }

        this.setState({mc: newState}, this.filter)
        this.setState({bike: bikeState}, this.filter)
        this.getUrl(newState, bikeState);
        
    }

    onClick(e){
        this.filter();

    }

    clearSearchField(){
        this.setState({search: ""}, this.filter)
        this.resetUrl();
    }

    

    

    render(){

        //const imagePath = DataStore.getImageUrl() + this.props.category.image;

        var c = "";

        return (

                <div className='findResellerList'> 
                   
                   <div className='navigation'>
                   <StartNavigation />
                   </div>
                   
                   <div className='findResellerHeader'>
                  
                   <h1>Våra återförsäljare</h1>
                   
                   </div>

                   <div className='bliaf'>
                    <a href='/bli-af/'><h3>Bli återförsäljare av våra produkter</h3><img src={buttonRight} /></a>
                    </div>
                   
                    <div className='searchView'>
                        <div className='searchField'>
                            <input type='text' className='searchBox' value={this.state.search} onChange={this.onSearchChange.bind(this)} placeholder='Sök företagsnamn, stad, postnummer...'/>
                            <img  alt='img' src={this.state.search.length > 0 ? buttonClose : buttonSearch} onClick={this.clearSearchField.bind(this)}/>
                        </div>

                        <div className={this.state.mc ? "button" : "button off"} onClick={this.onMCClick.bind(this) }>
                            <img  alt='img' src={this.state.mc ? icon_type_mc : icon_type_mc_off } />
                            <h3  className={this.state.mc ? null : "off"}>MC</h3>
                        </div>

                        <div className={this.state.bike ? "button" : "button off"} onClick={this.onBikeClick.bind(this) }>
                        <img  alt='img' src={this.state.bike ? icon_type_cykel : icon_type_cykel_off } />
                            <h3>CYKEL</h3>
                        </div>


                         <div className='clearBoth'></div>



                    </div>
                    <div className='clearBoth'></div>

  
                   
                    <div className='findResellerResult'>
                   
                  
                        {this.state.filteredList && this.state.filteredList.map( (reseller, index) => {

                            //var l = null;

                            const resellerLetter = reseller.name.substring(0, 1).toUpperCase();

                            /*if (resellerLetter != c){
                                l = resellerLetter
                                c = resellerLetter
                            }else{

                            }*/


                        return <FindReseller reseller={reseller} letter={resellerLetter} />
                        })}
                    
                    </div>

                </div>
                
           
        )
    }
}

export default withRouter(FindResellerList);