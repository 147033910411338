import React from "react"


import buttonUp from '../../gfx/button_up.png'; 
import buttonDown from '../../gfx/button_down.png'; 


import '../../css/ProductStock.css';



export default class ProductStock extends React.Component{

    constructor(props){
        super(props);
    
    }


    
    render(){

        var cssColor = "";
        var label = "";
        var short = "";

        if (this.props.product.sl == "NONE"){
            cssColor = "productStock stockNONE";
            label = this.props.product.dt && this.props.product.dt.length > 4 ? this.props.product.dt : "0 i lager"
            //short = "Ring"
            short = "0"
        }

        if (this.props.product.sl == "LOW"){
            cssColor = "productStock stockLOW";
            /*label = "< 5 i lager"
            short = "< 5"*/
            // FP: 2020-21
            label = this.props.product.is + " i lager"
            short = this.props.product.is + ""

        }

        if (this.props.product.sl == "OK"){
            cssColor = "productStock stockOK";
            label = "> 5 i lager"
            short = "> 5"
        }

        return (
            <div> 
        { this.props.product.sl && this.props.isLoggedIn ? <div className={cssColor} title={label}>{this.props.short ? short : label}</div> : null }
           
           </div>
        );
    }

}