import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";

import mcAprilia from '../../gfx/mc-logos/aprilia.png'; 
import mcBMW from '../../gfx/mc-logos/bmw.png'; 
import mcDucati from '../../gfx/mc-logos/ducati.png'; 
import mcHonda from '../../gfx/mc-logos/honda.png'; 
import mcHD from '../../gfx/mc-logos/hd.png'; 
import mcHuskvarna from '../../gfx/mc-logos/huskvarna.png'; 
import mcKawasaki from '../../gfx/mc-logos/kawasaki.png'; 
import mcKTM from '../../gfx/mc-logos/ktm.png'; 
import mcMVAgusta from '../../gfx/mc-logos/mv-agusta.png'; 
import mcSuzuki from '../../gfx/mc-logos/suzuki.png';
import mcTriumph from '../../gfx/mc-logos/triumph.png'; 
import mcYamaha from '../../gfx/mc-logos/yamaha.png'; 


import mc from '../../gfx/mc-logos/mc-marke.png'; 

export default class McBrandGrid extends React.Component{

    
    constructor(){
        super();
        //this.state = {mcList: DataStore.getMcList(), searchMc: DataStore.getSearchMc}
        //this.handleChange = this.handleChange.bind(this)
    }
    

   /*componentDidMount(){
    
        DataStore.on("xx", () => {
            //this.setState({mcList: DataStore.getMcList()})
        })
    }*/


    /*handleChange(event) {
        
        let value = event.target.value;
        this.setState({searchMc: value} );
        DataActions.searchMcTextChanged(value)  
        
      }*/

    onClick(brandId){
        
        const element = document.getElementById("brand_" + brandId);
        if (element){ 
            element.scrollIntoView({behavior: "smooth"});
        }

        

        /*
        const id = 'profilePhoto';
        const yOffset = -10; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
*/
    }


    divStyle = {
        color:'yellow'
    }
      

    render(){

        

        return (
            <div className='mcBrandGrid'>
                <table>
                    <tbody>
                        <tr>
                            <td onClick={()=>{this.onClick(2044)}}><img src={mcAprilia} /></td>
                            <td onClick={()=>{this.onClick(2229)}}><img src={mcBMW} /></td>
                            <td onClick={()=>{this.onClick(2550)}}><img src={mcDucati} /></td>
                            <td onClick={()=>{this.onClick(5241)}}><img src={mcHD} /></td>
                        </tr>
                        <tr>
                            <td onClick={()=>{this.onClick(4424)}}><img src={mcHonda} /></td>
                            <td onClick={()=>{this.onClick(3751)}}><img src={mcHuskvarna} /></td>
                            <td onClick={()=>{this.onClick(4241)}}><img src={mcKawasaki} /></td>
                            <td onClick={()=>{this.onClick(4101)}}><img src={mcKTM} /></td>
                            
                        </tr>
                        <tr>
                            <td onClick={()=>{this.onClick(5446)}}><img src={mcMVAgusta} /></td>
                            <td onClick={()=>{this.onClick(4723)}}><img src={mcSuzuki} /></td>
                            <td onClick={()=>{this.onClick(5265)}}><img src={mcTriumph} /></td>
                            <td onClick={()=>{this.onClick(4931)}}><img src={mcYamaha} /></td>
                           
                        </tr>
                    </tbody>
                </table>
              
                
            </div>

        )
    }
}
/*
  <div className='brand' onClick={()=>{this.onClick(2044)}}><h1>APRILIA</h1></div>
                <div className='brand' onClick={()=>{this.onClick(4424)}}><img src={mcHonda} /></div>
                <div className='brand' onClick={()=>{this.onClick(4931)}}><h1>YAMAHA</h1></div>
                */