import React from "react"
import McTreeModell from "./McTreeModell";
//import '../../css/ProductGrid.css';
import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";

export default class McTreeBrand extends React.Component{

    constructor() {
        super();
        this.state = { display: false}
      }
    
   onClick(e){
    
    this.setState({display: !this.state.display})

    //const currentBrand = DataStore.getBrand();

    //alert(currentBrand);

    //console.log("FP: CurrentBRand:", currentBrand);
/*
    if (currentBrand && this.props.brand && currentBrand.id == this.props.brand.id){
        DataActions.selectBrand(null);  
    }else{
        DataActions.selectBrand(this.props.brand);  
    }
*/
    if (this.state.display){
        DataActions.selectMc(null);  
    }else{
        DataActions.selectBrand(this.props.brand);  
    }


    //DataActions.selectBrand(this.props.brand);
    
   }

    
    render(){
    
        const css = {
           
        }

        const debug = this.props.brand.name + " " + this.props.brand.id

        //console.log("FP:", this.props.brand);

        const id = "brand_" + this.props.brand.id 
        
        return (
            <div className='mcTreeBrand' style={css} id={id} title={debug}>
             <h3 onClick={this.onClick.bind(this)}>  {this.props.brand.name}  </h3> 

            <div className={this.state.display? "displayBlock mcTreeModellList": "displayNone mcTreeModellList"}> 
             { this.props.brand.modells.map((item, index) => ( <McTreeModell mc={item} /> )) }
             
            </div>
            </div>

        )
    }
}

// { this.props.mc.modells.map((item, index) => ( <b> xx- {item.modellName}  <br/></b>  )) }