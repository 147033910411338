import React from "react"
import McTreeBrand from "./McTreeBrand";
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import ManufactorList from "./ManufactorList";

import '../../css/DropMenuManufactor.css';

import buttonClose from '../../gfx/button_close.png'; 
import buttonSearch from '../../gfx/button_search.png';
import DropMenuSearchResult from "./DropMenuSearchResult";

var Constants = require('../Constants');

export default class DropMenuManufactor extends React.Component{

    
    constructor(){
        super();
        this.state = {searchManufactor: DataStore.getSearchManufactor() }
        this.handleChange = this.handleChange.bind(this)
        this.clearSearchField = this.clearSearchField.bind(this)
    }

    handleChange(event) {
        
        let value = event.target.value;
    
        this.setState({searchManufactor: value} );
        
    
        DataActions.searchManufactorTextChanged(value)  
        
      }

   /*onClick(e){

        this.props.toggleSideMenu();
   }*/

   onMouseOut(e){ 
    //DataActions.dropMenuManufactorVisibility(false);
   }

   componentDidMount(){
    
    DataStore.on(Constants.MANUFACTOR_LIST_CHANGED, () => {this.setState({searchManufactor: DataStore.getSearchManufactor()}) })
    DataStore.on(Constants.SEARCH_MANUFACTOR_TEXT_CHANGED, () => {this.setState({searchManufactor: DataStore.getSearchManufactor()}) })
}
clearSearchField(){
    DataActions.searchManufactorTextChanged("")  
}

   
    render(){

        var className = this.props.visible ? 'dropMenuManufactor displayBlock' : 'dropMenuManufactor displayNone' ;

        return (
            <div className={className} onMouseLeave={this.onMouseOut.bind(this)}>
                <div className="dropMenuContent">
                <div className='dropMenuHeader'>

                    <div className='searchField'>
                        <input type='text' value={this.state.searchManufactor} onChange={this.handleChange} placeholder='Sök varumärke...' />
                        <img  alt='img' src={this.state.searchManufactor.length > 0 ? buttonClose : buttonSearch} onClick={this.clearSearchField}/>
                    </div>

                    <DropMenuSearchResult />
                    
                </div>
                
                    { <ManufactorList/> }
                
               
                </div>
                
                
            </div>

        )
    }
}