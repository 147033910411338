import React from "react"
import DataStore from "../stores/DataStore";
import '../../css/ManufactorInfo.css';
import StartNavigation from "./start/StartNavigation";


var Constants = require('../Constants')

export default class ManufactorInfo extends React.Component{

    constructor(){
        super();
        this.state = { 
           manufactor: DataStore.getManufactor(),
           
        }
    }

    componentDidMount() {
    
        DataStore.on(Constants.MANUFACTOR_SELECTED, () => { 
            this.setState({manufactor: DataStore.getManufactor() })
        })
        
    }

    render(){

        //const pages = this.state.startModel && this.state.startModel.boove.www.filter( page => { return page.key == this.props.pageKey} )

        //const page = pages ? pages[0] : null;
        var manufactorImageUrl = "";
        if (this.state.manufactor && this.state.manufactor.id){
            manufactorImageUrl  = "https://public.boove.se/gfx/logos/" + this.state.manufactor.id + ".jpg"
        }

       return (
        <div > { this.state.manufactor && this.state.manufactor.webContent && this.state.manufactor.webContent.length > 5 ? 
            <div className='manufactorInfo' >
                <div className='imageWrapper'>
                    <img src={manufactorImageUrl}></img>
                </div>
                <div className='information'>
                    <h3>{this.state.manufactor.name} </h3> <p className='html' dangerouslySetInnerHTML={{__html: this.state.manufactor.webContent}}></p>
                </div>
                <div className='footer' />
            </div>
            
            : null }
        </div>
       )
    }
}