import React from "react"
import '../../css/ProductList.css';
import Product from "./Product";
import ProductDetails from "./ProductDetails";
import DataStore from "../stores/DataStore";
import { Redirect } from 'react-router-dom'
import * as DataActions from "../actions/DataActions";
import ProductListSection from "./ProductListSection";
var Constants = require('../Constants')

export default class ProductList extends React.Component{

    constructor(props){
        super(props);

        this.state = {productList: DataStore.getFilteredProductList(), searchWord: "", product: null}
        //DataActions.getProduct(null);

        console.log("FP: URL: ProductList()");
       
        
    }
    

   componentDidMount(){
    
    //DataActions.selectViewType("list");

        DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => { this.setup(); })
        //DataStore.on(Constants.PRODUCT_CHANGED, () => { this.setup(); })
        //alert("mount")
        
    }

    /*componentWillUpdate(){
        alert("update"  + this.props.productType)
        //DataActions.selectProductType(this.props.productType)
    }*/

    setup(){
        
        this.setState({productList: DataStore.getFilteredProductList()})
        this.setState({searchWord: DataStore.searchProduct})
        //this.setState({product: DataStore.getProduct()})
        //alert("setup: " + this.state.productList.length);
        //alert("setup")
    }


    render(){

        

        /*if (this.state.product != null) {
            
            const url = "/mc/products/product_ " + this.state.product.id;

            return <Redirect push to={url} />
        }*/

        var currentManufactor = null;

        return (
            <div className='productList'>

               {
                    //this.state.product != null ? 
                    //    <ProductDetails product={this.state.product} />
                    //    : 
                   
                    
                        this.state.productList.map((item, index) => {
                           
                           if (item.manufactorName != currentManufactor && DataStore.getGroupBy() === Constants.GROUP_BY_MANUFACTOR && DataStore.getMc()){
                             
                            currentManufactor = item.manufactorName;
                            return (<div>
                                        <ProductListSection manufactorName={item.manufactorName} />
                                        <Product key={item.id} product={item} viewType='list' isLoggedIn={this.props.isLoggedIn} />
                                    </div>);
                           }
                            
                        return (<Product key={item.id} product={item} viewType='list' isLoggedIn={this.props.isLoggedIn} />);
                        })                    
                }

            </div>

        )
    }
}