import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import ManufacatorListNode from "./ManufacatorListNode"
var Constants = require('../Constants')

 

export default class ManufactorList extends React.Component{

    
    constructor(){
        super();
        this.state = {manufactorList: DataStore.getFilteredManufactorList()}

    }
    

    componentDidMount(){
    
        DataStore.on(Constants.MANUFACTOR_LIST_CHANGED, () => {
            
            this.setState({manufactorList: DataStore.getFilteredManufactorList()})
        })
    }

    render(){

        const style = {"padding": "10px"}


        return (
            <div className='manufactorList'>
                <div style={style} >
           
                    { this.state.manufactorList.map((item, index) => ( <ManufacatorListNode manufactor={item} /> )) }

                </div>
            <div className="clearBoth"></div>
           
        </div>

        )
    }
}
