import React from "react"
import McTreeBrand from "./McTreeBrand";
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import McTree from "./McTree";
import McList from "./McList";
import '../../css/DropMenuMc.css';
import McBrandGrid from "./McBrandGrid";
import buttonClose from '../../gfx/button_close.png'; 
import buttonSearch from '../../gfx/button_search.png';
import DropMenuSearchResult from "./DropMenuSearchResult";

var Constants = require('../Constants')

export default class DropMenuMC extends React.Component{

    
    constructor(){
        super();
        this.state = {searchMc: DataStore.getSearchMc}
        this.handleChange = this.handleChange.bind(this)
        this.clearSearchField = this.clearSearchField.bind(this)
    }

    handleChange(event) {
        
        let value = event.target.value;
    
        //this.setState({searchMc: value} );
    
        DataActions.searchMcTextChanged(value)  
        
      }

   /*onClick(e){

        this.props.toggleSideMenu();
   }*/

   onMouseOut(e){
    //setTimeout(function(){ DataActions.dropMenuMcVisibility(false); }, 3000);
    //DataActions.dropMenuMcVisibility(false);
   }

   clearSearchField(){
        DataActions.searchMcTextChanged("")  
   }
   componentDidMount(){
    
    DataStore.on("mcListChanged", () => {

        this.setState({searchMc: DataStore.getSearchMc()})
    })

    DataStore.on(Constants.SEARCH_MC_TEXT_CHANGED, () => {

        this.setState({searchMc: DataStore.getSearchMc()})
    })
}

   
    render(){

        var className = this.props.visible ? 'dropMenuMC displayBlock' : 'dropMenuMC displayNone' ;
    
        return (
            <div className={className} onMouseLeave={this.onMouseOut.bind(this)}>
                <div className="dropMenuContent">
                
                <div className='dropMenuHeader'>
                    <div className='searchField'>
                    <input type='text' value={this.state.searchMc} onChange={this.handleChange} placeholder='Sök mc-modell' />
                    <img  alt='img' src={this.state.searchMc.length > 0 ? buttonClose : buttonSearch} onClick={this.clearSearchField}/>
                    </div>

                    <DropMenuSearchResult />
                </div>
                    
                    { this.state.searchMc.length == 0 ? <McTree />: <McList /> }
                    <McBrandGrid />
               
                </div>
                
                
            </div>

        )
    }
}