import React from "react"
import McTreeModell from "./McTreeModell";
//import '../../css/ProductGrid.css';
import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";


export default class BikeType extends React.Component{

    constructor() {
        super();
       
      }
    
   onClick(e){
    
    const name = this.props.bikeType.name;



    DataActions.dropMenuBikeTypeVisibility(false);

    DataActions.selectBikeType(this.props.bikeType);  

    if (DataStore.getFilteredProductList().length > 0){
        DataActions.sideMenuMcVisibility(false);
        DataActions.dropMenuBikeTypeVisibility(false);
    }
    
   }

    
    render(){
    
        const css = {
           
        }

        //console.log("FP:", this.props.brand);

       
        
        return (
            <div className='bikeType'>
                <h3 onClick={this.onClick.bind(this)}> {this.props.bikeType.name}  </h3> 
            </div>

        )
    }
}

// { this.props.mc.modells.map((item, index) => ( <b> xx- {item.modellName}  <br/></b>  )) }