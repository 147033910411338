import React from "react"


import DataStore from "../stores/DataStore";
import { NavLink, useParams } from 'react-router-dom'
import '../../css/SearchResult.css';
import * as DataActions from "../actions/DataActions";
import buttonUp from '../../gfx/button_up_dark.png'; 
import buttonDown from '../../gfx/button_down_dark.png'; 
import buttonSortAsc from '../../gfx/button_sort_asc.png';
import buttonSortDesc from '../../gfx/button_sort_desc.png'; 
import McListModell from './McListModell';
import buttonList from '../../gfx/button_list.png'; 
import buttonGrid from '../../gfx/button_grid.png';

import buttonCheckOn from '../../gfx/button_check.png';
import buttonCheckOff from '../../gfx/button_check_off.png';

import FilterList from "./FilterList";


var Constants = require('../Constants')

export default class SearchResult extends React.Component{

    constructor(props){
        super(props);

        this.maxLength = 5;


        this.state = {  searchWord: DataStore.getSearchProduct(), 
                        productListCount: DataStore.getFilteredProductList().length,
                        mcList: DataStore.getSearchResultMcList(),
                        categoryList: DataStore.getSearchResultCategoryList(), 
                        manufactorList: DataStore.getSearchResultManufactorList(),
                        productTypeString: DataStore.getProductTypeString(), 
                        productType: DataStore.getProductType(), 
                        allMCButtonVisible: false,
                        mcListShowAll: false,
                        allCategoryButtonVisible: false,
                        categoryListShowAll: false,
                        allManufactorButtonVisible: false,
                        manufactorListShowAll: false,
                        mcListShowYears: false,
                        requestCount: DataStore.getRequestCount(),
                        //isProductListLimited: DataStore.isProductListLimited()
                        productListCountLabel: DataStore.getProductListCountLabel(),
                        productListCountTitleLabel: DataStore.getProductListCountTitleLabel(),
                        viewType: this.props.viewType,
                        sortOpen: false,
                        groupBy: DataStore.getGroupBy(),
                        sortBy: DataStore.getSortBy(),
                        onlyStock: DataStore.getOnlyStock(),
                        onlyOutlet: DataStore.getOnlyOutlet(),
                        isLoggedIn: DataStore.isLoggedIn(),
                        //filteredProductListLength: DataStore.getFilteredProductListLength(),
                        filteredProductListUnlimtedLength: DataStore.getFilteredProductListUnlimitedLength(),


                      }
                      
        
    }
    
   


   onClick(e){
    //console.log(e);

    //"123");
   }

   onClickMC(mc){     
     DataActions.selectMc(mc); 
  }

  onClickCategory(category){
    DataActions.selectCategory(category);
  }

  onClickManufactor(manufactor){
    DataActions.selectManufactor(manufactor);
  }


   onClickAllMC(){
    this.setState({'mcListShowAll': !this.state.mcListShowAll}, () => { this.setup(); })
   }
   
   onClickAllCategory(){
    this.setState({'categoryListShowAll': !this.state.categoryListShowAll}, () => { this.setup(); })
   }

   onClickAllManufactor(){
    this.setState({'manufactorListShowAll': !this.state.manufactorListShowAll}, () => { this.setup(); })
   }

   onClickViewTypeGrid(){
    DataActions.selectViewType("grid");
   }

   onClickViewTypeList(){
    DataActions.selectViewType("list");
   }

    componentDidMount(){
    
        DataStore.on(Constants.SEARCH_PRODUCT_TEXT_CHANGED, () => { this.setup() })
        DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => { this.setup() })
        DataStore.on(Constants.SEARCH_RESULT_CHANGED, () => { this.setup() })
        DataStore.on(Constants.PRODUCT_TYPE_CHANGED, () => { this.setup(); })
        DataStore.on(Constants.REQUEST_COUNT_CHANGED, () => {this.setup(); })
        DataStore.on(Constants.VIEWTYPE_CHANGED, () => { this.setup(); })
        DataStore.on(Constants.GROUP_BY_CHANGED, () => { this.setup(); })
        DataStore.on(Constants.SORT_BY_CHANGED, () => { this.setup(); })
        DataStore.on(Constants.ONLY_STOCK_CHANGED, () => { this.setup();})
        DataStore.on(Constants.ONLY_OUTLET_CHANGED, () => { this.setup();})
        
        DataStore.on(Constants.LOGIN_STATUS_CHANGED, () => { this.setup(); });
        //DataStore.on(Constants.VIEWTYPE_CHANGED, () => { this.setup(); })
       
        
    }

    setup(){

      const mcList = DataStore.getSearchResultMcList();
      const categoryList = DataStore.getSearchResultCategoryList();
      const manufactorList = DataStore.getSearchResultManufactorList();


        this.setState({productListCount: DataStore.getFilteredProductList().length});
        this.setState({searchWord: DataStore.getSearchProduct()});
        this.setState({productTypeString: DataStore.getProductTypeString() });
        this.setState({productType: DataStore.getProductType()});
        
        //this.setState({mcList: DataStore.getSearchResultMcList()})
        //this.setState({categoryList: DataStore.getSearchResultCategoryList()})
        //this.setState({manufactorList: DataStore.getSearchResultManufactorList()})
  
     
        this.setState({"allMCButtonVisible": mcList.length > this.maxLength})
        this.setState({"allCategoryButtonVisible": categoryList.length > this.maxLength})
        this.setState({"allManufactorButtonVisible": manufactorList.length > this.maxLength})

        this.setState({mcListShowYears: mcList.length < 3})

        if (!this.state.mcListShowAll){
            this.setState({'mcList': mcList.slice(0, this.maxLength)})
        }else{
          this.setState({'mcList': mcList})
        }

        if (!this.state.categoryListShowAll){
          this.setState({'categoryList': categoryList.slice(0, this.maxLength)})
        }else{
          this.setState({'categoryList': categoryList})
        }
        
        if (!this.state.manufactorListShowAll){
          this.setState({'manufactorList': manufactorList.slice(0, this.maxLength)})
        }else{
          this.setState({'manufactorList': manufactorList})
        }

        //this.setState({isProductListLimited: DataStore.isProductListLimited()})
        this.setState({productListCountLabel: DataStore.getProductListCountLabel()});

        this.setState({productListCountTitleLabel: DataStore.getProductListCountTitleLabel()});

        this.setState({requestCount: DataStore.getRequestCount()})

        this.setState({viewType: DataStore.getViewType()})

        this.setState({groupBy: DataStore.getGroupBy()})
        
        this.setState({sortBy: DataStore.getSortBy()})

        this.setState({onlyStock: DataStore.getOnlyStock()})

        this.setState({onlyOutlet: DataStore.getOnlyOutlet()})

        this.setState({isLoggedIn: DataStore.isLoggedIn()})

        //this.setState({filteredProductListLength: DataStore.getFilteredProductListLength() })

        this.setState({filteredProductListUnlimtedLength: DataStore.getFilteredProductListUnlimitedLength()})

    }

    onSortClick(){
      this.setState({sortOpen: !this.state.sortOpen})
    }

    onSortOptionRecentClicked(){
      DataActions.setSortBy(Constants.SORT_RECENT);
    }

    onSortOptionRelevanceClicked(){
      DataActions.setSortBy(Constants.SORT_RELEVANCE);
    }

    onSortOptionPriceDescClicked(){
      DataActions.setSortBy(Constants.SORT_PRICE_DESC);
    }

    onSortOptionPriceAscClicked(){
      DataActions.setSortBy(Constants.SORT_PRICE_ASC);
    }

    onSortOptionArtnrClicked(){
      DataActions.setSortBy(Constants.SORT_ARTNR);
    }

    onSortOptionNameClicked(){
      DataActions.setSortBy(Constants.SORT_NAME);
    }

    onOnlyOnlyStockClicked(){

      DataActions.setOnlyStock(!this.state.onlyStock)
    }

    onOnlyOutletClicked(){

      //alert("onOnlyOutletClicked");
      DataActions.setOnlyOutlet(!this.state.onlyOutlet)
    }



    onGroupByManufactorClicked(){

      if (DataStore.getMc() == null){
        alert("Välj en mc-modell först!");
        return;
        //DataActions.setGroupBy(null);

      }

      if (this.state.groupBy){
        DataActions.setGroupBy(null);
      }else{
        DataActions.setGroupBy(Constants.GROUP_BY_MANUFACTOR);
      }
    }

    showAllProducts(){
      DataActions.showAllProducts();
    }


    render(){
        
      const list = "/" + this.state.productTypeString + "/products/list/"
      const grid = "/" + this.state.productTypeString + "/products/grid/"
      
      const viewType = this.props.viewType ? this.props.viewType : "list"
      
      var productResultLabel;

      if (this.state.requestCount > 0){
        productResultLabel = "Hämtar data...";
      } else{
        productResultLabel =  "Hittade " + this.state.productListCountLabel;
      }

      const hit = ( (this.state.mcList && this.state.mcList.length > 0 && this.state.productType == 1) || 
                    (this.state.categoryList && this.state.categoryList.length > 0) ||
                    (this.state.manufactorList && this.state.manufactorList.length > 0 ))
                    
// FP: TODO: Lägg in cykeltyper här...
                    
      const sortIcon = this.state.sortBy === Constants.SORT_PRICE_DESC ?  buttonSortDesc : buttonSortAsc;

      //const title = this.state.productListCountTitleLabel + " count:" + this.state.filteredProductListLength + " unlimited:" + this.state.filteredProductListUnlimtedLength 

      const title =  this.state.filteredProductListLength + "/" + this.state.filteredProductListUnlimtedLength 

        return (

            <div className='searchResult'>
            <h3 className='productResultLabel' title={title}>
              
              {productResultLabel}<br/>

                { /*false && this.state.filteredProductListUnlimtedLength < 2000 && this.state.filteredProductListUnlimtedLength > this.state.filteredProductListLength ?
                <div onClick={this.showAllProducts.bind(this)} className='showAllButton'>Visa alla {this.state.filteredProductListUnlimtedLength}</div>
                : null */ }
                
                
              </h3>
              
                <div className={  this.state.searchWord.length > 0 && hit ? 'searchResultBoxes': 'displayNone' } >

                 { this.state.productType == 1 && this.state.mcList && this.state.mcList.length > 0 ?
                  <div className='searchResultMc searchResultBox'>
                    <h3>Motorcyklar </h3>
                  
                    
                    {
                      this.state.mcList.map((item, index) => (
                       
                         <McListModell mc={item} display={this.state.mcListShowYears}/>  
                    ))    
                    }

                    <div className={this.state.allMCButtonVisible ? 'showAll':'displayNone'} onClick={this.onClickAllMC.bind(this)}>
                      {this.state.mcList.length <= this.maxLength ? <img src={buttonDown} className='showAllResult' />:<img src={buttonUp} /> }
                    </div>
                  </div>: null}

                
                  { this.state.categoryList && this.state.categoryList.length > 0 ? <div className='searchResultCategory searchResultBox'>
                    <h3>Kategori</h3>
                    {
                      this.state.categoryList.map((item, index) => (
                          
                        <div className='searchResultItem' onClick={this.onClickCategory.bind(this, item)} title={item.path}> { item.name }</div>
                        
                    ))    
                    }
                    <div className={this.state.allCategoryButtonVisible ? 'showAll':'displayNone'} onClick={this.onClickAllCategory.bind(this)}>
                      {this.state.categoryList.length <= this.maxLength ? <img src={buttonDown} className='showAllResult' />:<img src={buttonUp} /> }
                    </div>
                  </div>:null}


                  { this.state.manufactorList && this.state.manufactorList .length > 0 ?
                  <div className='searchResultManufactor searchResultBox'>
                    <h3>Varumärke</h3>
                    {
                
                      this.state.manufactorList.map((item, index) => (
                        <div className='searchResultItem' onClick={this.onClickManufactor.bind(this, item)}> { item.name }</div>
                        
                    ))    
                    }
                    <div className={this.state.allManufactorButtonVisible ? 'showAll':'displayNone'} onClick={this.onClickAllManufactor.bind(this)}>
                      {this.state.manufactorList.length <= this.maxLength ? <img src={buttonDown} className='showAllResult'/>:<img src={buttonUp} /> }
                    </div>
                  </div>:null}

                  <div className='clearBoth'></div>
                </div>


              <div className='toolbar'>
              
              <img  alt='img' src={buttonList}  className={this.state.viewType == "list" ? "active pointer" : 'pointer'} onClick={this.onClickViewTypeList.bind(this)} title='Visa en produkt per rad'/>
              <img  alt='img' src={buttonGrid} className={this.state.viewType == "grid" ? "active pointer" : 'pointer'} onClick={this.onClickViewTypeGrid.bind(this)} title='Visa produkter i kolumner'/>


              </div>



              <div className={this.state.sortOpen ? 'sort open' : 'sort closed' } onClick={this.onSortClick.bind(this)}>
              
                    <div className='sortHeader pointer' title='Ändra sortering och inställnigar'>
                    <img  alt='img' src={sortIcon} onClick={this.onClickViewTypeList.bind(this)} />
                    <h3>{this.state.sortBy}</h3>
                    { this.state.onlyStock ? <div class='inStockBadge'><h3>I LAGER</h3></div> : null }
                    { this.state.onlyOutlet ? <div class='outletBadge'><h3>OUTLET</h3></div> : null }
                    </div>


                    {this.state.sortOpen ?
                    <div className='sortOptionList'>
                     
                      <div className={this.state.sortBy === Constants.SORT_RELEVANCE ? 'sortOption selected' : 'sortOption'} onClick={this.onSortOptionRelevanceClicked.bind(this)} title='Visa bästa sökträff överst'><h3>{Constants.SORT_RELEVANCE}</h3></div>
                      <div className={this.state.sortBy === Constants.SORT_RECENT ? 'sortOption selected' : 'sortOption'} onClick={this.onSortOptionRecentClicked.bind(this)} title='Visa nyaste överst'><h3>{Constants.SORT_RECENT}</h3></div>
                      <div className={this.state.sortBy === Constants.SORT_PRICE_DESC ? 'sortOption selected' : 'sortOption'} onClick={this.onSortOptionPriceDescClicked.bind(this)} title='Visa dyraste överst'><h3>{Constants.SORT_PRICE_DESC}</h3></div>
                      <div className={this.state.sortBy === Constants.SORT_PRICE_ASC ? 'sortOption selected' : 'sortOption'} onClick={this.onSortOptionPriceAscClicked.bind(this)} title='Visa billigaste överst'><h3>{Constants.SORT_PRICE_ASC}</h3></div>
                      <div className={this.state.sortBy === Constants.SORT_ARTNR ? 'sortOption selected' : 'sortOption'} onClick={this.onSortOptionArtnrClicked.bind(this)} title='Sortera på artikelnummer'><h3>{Constants.SORT_ARTNR}</h3></div>
                      <div className={this.state.sortBy === Constants.SORT_NAME ? 'sortOption selected' : 'sortOption'} onClick={this.onSortOptionNameClicked.bind(this)} title='Sortera på artikelnamn'><h3>{Constants.SORT_NAME}</h3></div>
                      
                     
                    
                      <div className={this.state.groupBy === Constants.SORT_NAME ? 'sortOption selected' : 'sortOption'} onClick={this.onGroupByManufactorClicked.bind(this)} title='Välj MC-Modell och gruppera artiklar per varumärke'><img  alt='img' src={this.state.groupBy ? buttonCheckOn : buttonCheckOff} /> <h3>Gruppera på varumärke</h3></div> 
                      <div className={this.state.onlyOutlet ? 'sortOption selected' : 'sortOption'} onClick={this.onOnlyOutletClicked.bind(this)} ><img  alt='img' src={this.state.onlyOutlet === true ? buttonCheckOn : buttonCheckOff} title='Visa prissänkta artiklar' /> <h3>OUTLET</h3></div>
                      {this.state.isLoggedIn ? 
                      <div className={this.state.onlyStock ? 'sortOption selected' : 'sortOption'} onClick={this.onOnlyOnlyStockClicked.bind(this)} ><img  alt='img' src={this.state.onlyStock === true ? buttonCheckOn : buttonCheckOff}  title='Visa endast artiklar som finns i lager'/> <h3>Endast i lager</h3></div> : null }
                    </div> : null }
              


              </div>
              
                
              {  this.state.productListCount == 0 && this.state.requestCount == 0 ?  
                <div className='noResult desktop'> 
                  Hitta fler produkter genom att ta bort något filter <FilterList />
                </div>
                : null }
               
            </div>
        )
    }
}