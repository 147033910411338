import React from "react"

import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";

import buttonRight from '../../gfx/button_right.png'; 

export default class sConnectedProduct extends React.Component{

    constructor() {
        super();
        
      }
    
   onClick(){
    
    
    //alert(this.props.connectedProduct.id);

    DataActions.getProduct(this.props.connectedProduct.id);

    //if (mc){
      //  DataActions.get
    //}
    
   }

    
    render(){
        
        const imgUrl = DataStore.getImageUrl() + this.props.connectedProduct.image

        return (
            <div className='connectedProduct' onClick={this.onClick.bind(this)}>
                
                { this.props.connectedProduct.image && this.props.connectedProduct.image.length > 0  ? <img src={imgUrl} className='productImage' /> : null }
                <img src={buttonRight} />
                <div className='information'>
                    <h4>{this.props.connectedProduct.manufactorName}</h4>
                    <h3>{this.props.connectedProduct.name}</h3>
                    <h3>Pris: {this.props.connectedProduct.price2f}</h3>
                    <h3>Artnr: {this.props.connectedProduct.artnr}</h3>
                </div>
               
                <div className='clearBoth'></div>
            </div>

        )
    }
}

