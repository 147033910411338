import React from "react"
import * as DataActions from "../actions/DataActions";

export default class Overlay extends React.Component{

    
    
   onHideSideMenuClick(e){
       //this.props.hide();
       DataActions.sideMenuMcVisibility(false);
   }

   
    render(){


        //this.props.changeTitle('HeaderTitle');

        var className = this.props.visible ? 'overlayVisible' : 'overlayHidden' ;

        return (
            
            <div className={className} onClick={this.onHideSideMenuClick.bind(this)}>

               
                
            </div>

        )
    }
}