import React from "react"
import DataStore from "../../stores/DataStore";
import * as DataActions from "../../actions/DataActions";
import "../../../css/Start.css"
import buttonSearch from '../../../gfx/circle.png';

import {NavLink, withRouter} from 'react-router-dom'

import button_up from '../../../gfx/button_up.png';
import button_down from '../../../gfx/button_down.png';
import button_right from '../../../gfx/button_right.png';
import button_phone from '../../../gfx/button_phone.png';

import icon_type_mc from '../../../gfx/icon-mc_dark.png';
import icon_type_cykel from '../../../gfx/icon-cykel_dark.png';
import icon_type_motul from '../../../gfx/icon-motul_dark.png';


var Constants = require('../../Constants');

class FindReseller extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            open:false
            //resellerList: this.props.resellerList 
        }
    }

    onClick(e){

    }

    onHeaderClick(){

        if (!this.state.open){ // om den ska öppnas
            const url = "/butiker/" + this.props.reseller.key;
            this.props.history.replace({ pathname: url})
        }


        this.setState({open: !this.state.open})


    }
    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.reseller.id != this.props.reseller.id){
            this.setState({open: false});
        }

        return true;

    }

    render(){

        const phoneLink = "tel:" + this.props.reseller.phonef;
        const phoneLink2 = "tel:" + this.props.reseller.phone2f;
        return (

                <div className='findReseller ' > 

                    <div className={this.props.letter ? "letter": "letter hidden"}><h1>{this.props.letter}</h1></div>      

                    <div className='reseller'>
                    <div className='information'>

                  <div className='type'>

                    {this.props.reseller.type.toUpperCase().indexOf("M") > -1 ? <img src={icon_type_mc} /> : null }
                    {this.props.reseller.type.toUpperCase().indexOf("C") > -1 ? <img src={icon_type_cykel} /> : null }
                    {this.props.reseller.type.toUpperCase().indexOf("B") > -1 ? <img src={icon_type_motul} /> : null }
          
                       

                       
                    </div>
                        <div className='resellerHeader' onClick={this.onHeaderClick.bind(this)}>
                            <h1 title={this.props.reseller.id}>{this.props.reseller.name}</h1> 
                            <h3 className='address desktop'>{this.props.reseller.address}</h3>
                            { (this.props.reseller.description && this.props.reseller.description.length > 0) || 
                                this.props.reseller.phone2 ? 
                            <img className='readMore' src={this.state.open ? button_up : button_down} /> : null }
                            
                        </div>

                        <h3 className='address mobile'>{this.props.reseller.address}</h3>

                        { !this.state.open ?
                        <a href={phoneLink} >
                         <div className='contactButton'>
                            <img src={button_phone} />
                            <h3>{this.props.reseller.phone}</h3>
                        </div></a> : null}

                         { !this.state.open && this.props.reseller.www && this.props.reseller.www.length > 2 ?
                         <a href={this.props.reseller.www} target='_blank' alt={this.props.reseller.www}>
                        <div className='contactButton www'>
                           
                            <h3>Webbsida</h3>
                            <img src={button_right} />
                        </div></a> : null}
                  
  
                      
           
                    </div>
                    
                    

                    { this.state.open ?
                    <div className='details'> 
                        {this.props.reseller.description && this.props.reseller.description.length > 0 ? <h3>{this.props.reseller.description}</h3> : null}

                        <a href={phoneLink} >
                       <div className='contactButton'>
                            <img src={button_phone} />
                            <h3>{this.props.reseller.phone}</h3>
                        </div></a>

                        { this.props.reseller.phone2 ? 
                         <a href={phoneLink2} >
                        <div className='contactButton'>
                            <img src={button_phone} />
                            <h3>{this.props.reseller.phone2}</h3>
                        </div></a> : null }

 
                        {this.props.reseller.www && this.props.reseller.www.length > 2 ? 
                        <a href={this.props.reseller.www} target='_blank' alt={this.props.reseller.www}>
                       <div className='contactButton'>
                            <img src={button_right} />
                            <h3>{this.props.reseller.www}</h3>
                        </div>
                        </a>: null}

                        
                    </div> : null }
                    </div>
                </div>
                
                
           
        )
    }
}

export default withRouter(FindReseller)