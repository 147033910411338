import React from "react"
import DataStore from "../stores/DataStore";

import * as DataActions from "../actions/DataActions";

import buttonClose from '../../gfx/button_close.png';

import dataStore from "../stores/DataStore";
var Constants = require('../Constants')

export default class Login extends React.Component {

	constructor() {
		super();

		var data;

		if (document.cookie && document.cookie.length > 10) {

			var values = document.cookie.split("=");

			try {
				data = JSON.parse(values[1]);
			} catch (e) {
				//alert(document.cookie);
			}
			//if (values[1]){
			//data = JSON.parse(values[1]);
			//}

			console.log("Login", data);


		}

		this.state = {
			userName: data && data.userName ? data.userName : "",
			password: data && data.password ? data.password : "",
			token: data && data.token ? data.token : "",
			loginMessage: null,
			visible: DataStore.isLoginVisible(),
			rememberMe: (document.cookie && document.cookie.length > 10 && data && data.userName && data.userName.length > 1),
			maxAgeSeconds: (60 * 60 * 24 * 7),
			//trySessionLogin: true

		}

		//this.setupLogin();

	}

	divStyle = {
		display: 'block',
		clear: 'both'
	}



	setupLogin() {

		if (document.cookie && document.cookie.length > 5) {

			var values = document.cookie.split("=");

			if (values[1]) {
				const data = JSON.parse(values[1]);
				this.setState({ userName: data.userName })
				this.setState({ password: data.password })
			}
		}

	}




	onCloseClick() {
		//alert(123);
		DataActions.loginVisibility(false)


	}

	onClickMessage() {
		DataActions.setLoginMessage(null)
	}

	onLoginClick() {


		if (this.state.userName == "fredde@x-web.se" && this.state.password == "") {
			DataActions.serverLogError("Test-error from webb")
		}


		if (this.state.userName == null || this.state.userName.length < 8) {
			alert("Felaktigt användarnamn: " + this.state.userName);
			return;
		}

		if (this.state.password == null || this.state.password.length < 4) {
			alert("Felaktigt lösenord");
			return;
		}


		DataActions.login(this.state.userName, this.state.password, null);
	}




	handleUserNameChange(event) {

		let value = event.target.value;

		this.setState({ userName: value });
		DataActions.setLoginMessage(null)

	}

	handlePasswordChange(event) {
		let value = event.target.value;
		this.setState({ password: value });
		DataActions.setLoginMessage(null)
	}

	handleCookie(event) {
		this.setState({ rememberMe: event.target.checked });
	}


	componentDidMount() {
		DataStore.on(Constants.LOGIN_MESSAGE_CHANGED, () => { this.setState({ loginMessage: DataStore.getLoginMessage() }) })
		DataStore.on(Constants.LOGIN_VISIBILITY_CHANGED, () => { this.setState({ visible: DataStore.isLoginVisible() }) })
		DataStore.on(Constants.LOGIN_SUCCEED, () => {
			this.setState({ visible: DataStore.isLoginVisible() })

			if (this.state.rememberMe) {
				var data = { 'userName': this.state.userName, 'password': this.state.password, 'token': DataStore.getLoginToken() }
				document.cookie = "login=" + JSON.stringify(data) + ";path=/;max-age=" + this.state.maxAgeSeconds + ";";
			} else {
				var data = { 'userName': '', 'password': '', 'token': DataStore.getLoginToken() }
				document.cookie = "login=" + JSON.stringify(data) + ";path=/;max-age=" + this.state.maxAgeSeconds + ";";
				//alert("delete");
				//document.cookie = "login=;path=/;max-age=1;";
			}
			// FP_LOGIN spara cookie här istället
		})
		DataStore.on(Constants.DROP_MENU_VISIBILITY_CHANGED, () => { this.setState({ visible: DataStore.isLoginVisible() }) })
		DataStore.on(Constants.LOGIN_FAILED, () => {  /*document.cookie = "login=;path=/;max-age=60;"; */ })
		DataStore.on(Constants.LOGOUT, () => { this.setState({ userName: "", password: "" }) })

		/*if (this.state.trySessionLogin){
				this.setState({sesstrySessionLoginonLogin: false})
				DataActions.login(null, null);
		}*/

		//DataActions.login(null, null); // FP-LOGIN skicka med token här. 
		if (this.state.token != null) {
			DataActions.login(null, null, this.state.token); // FP-LOGIN skicka med token här. 
		}

		/*if (dataStore.loginToken == null && this.state.userName && this.state.password && this.state.rememberMe){
				DataActions.login(this.state.userName, this.state.password);
		}*/

		//this.setState({loginToken: DataStore.getLoginToken()})
		//alert(123);
		//this.setState({userName: "xx"});
		//this.setState({password: "xx"});
		//DataActions.loginVisibility(false);


	}

	onEnterPressed(event) {
		if (event.key === 'Enter') {
			this.onLoginClick();
		}
	}

	render() {

		//this.setupLogin();


		const value = document.cookie;


		//const defaultChecked = (document.cookie && document.cookie.length > 5)

		return (
			<div className={this.state.visible ? 'login loginVisible' : 'login loginHidden'}>
				<img className='close' src={buttonClose} onClick={this.onCloseClick.bind(this)} />
				<h1>Login</h1>
				<div className={this.state.loginMessage ? "loginMessage loginMessageVisible" : "loginMessage loginMessageHidden"}
					onClick={this.onClickMessage.bind(this)}>{this.state.loginMessage}</div>
				<input type="text" value={this.state.userName} onChange={this.handleUserNameChange.bind(this)} placeholder='Användarnamn'
					onKeyDown={this.onEnterPressed.bind(this)} /><br />

				<input type="password" value={this.state.password} onChange={this.handlePasswordChange.bind(this)} placeholder='Lösenord'
					onKeyDown={this.onEnterPressed.bind(this)} /><br />
				<div className='cookie'>
					<input type='checkbox' value='on' onChange={this.handleCookie.bind(this)} defaultChecked={this.state.rememberMe} />
					Kom ihåg mig på denna dator i 7 dagar.
                </div>

				<button onClick={this.onLoginClick.bind(this)} className='pointer'>LOGIN</button>
				<div className='info'>Genom att logga in godkänner du att vi sparar en cookie för inloggningen på din dator. </div>


				<div className='clearBoth'>&nbsp;</div>
			</div>

		)
	}
}