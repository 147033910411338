import React from "react"

import '../../css/Header.css';
import * as DataActions from "../actions/DataActions"
import DataStore from "../stores/DataStore";
import buttonClose from '../../gfx/button_close.png';

var Constants = require('../Constants')

export default class ErrorMessage extends React.Component{

    constructor(){
        super();
       
        this.state = {  errorMessage: DataStore.getErrorMessage(), 
                        expireMinutes: DataStore.getExpireMinutes() }
       
     
    }
    


    onCloseClick(e){
        DataActions.setErrorMessage(null);

    }

    onAppendSessionClick(e){
        //DataActions.setErrorMessage(null);
    
        DataActions.order(DataStore.getLoginToken(), {"action":"reload"});
    }

    componentDidMount() {
    
        DataStore.on(Constants.ERROR_MESSAGE_CHANGED, () => { 
            this.setState({errorMessage: DataStore.getErrorMessage() })
            this.setState({expireMinutes: DataStore.getExpireMinutes() })
        })

        DataStore.on(Constants.GET_ORDER_COMPLETE, () => { 
            this.setState({expireMinutes: DataStore.getExpireMinutes() })
        })
        

    }

    render(){
        
        //console.log(this.props);

        //this.props.changeTitle('HeaderTitle');

        return (

            
            <div className={ this.state.errorMessage ? "errorMessage errorMessageVisible" : "errorMessage errorMessageHidden" }  onClick={this.onCloseClick.bind(this)}>
                <div className='close'>
                    <img src={buttonClose} className='closeButton'  />
                </div>
                
                <h2> {this.state.errorMessage} </h2>
              
                {this.state.expireMinutes > 0 && this.state.expireMinutes < 30 ?   <h2 onClick={this.onAppendSessionClick.bind(this)} class='appendSession'>Fortsätt vara inloggad</h2> : null } 
                
                
                
              
               
            </div>

        )
    }
}