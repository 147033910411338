import React from "react"
import '../../css/SearchMenu.css';
import buttonList from '../../gfx/button_list.png';
import buttonHistory from '../../gfx/history.png';
import buttonClose from '../../gfx/button_close_dark.png';
import buttonSearch from '../../gfx/button_search_dark.png';
import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";
import Constants from "../Constants"




export default class SearchMenu extends React.Component {



	constructor() {
		super();
		this.state = {
			searchText: DataStore.getSearchProduct(),
			searchBoxVisible: DataStore.getSearchBoxVisible(),
			historyList: DataStore.getHistoryList()
		}

		this.handleChange = this.handleChange.bind(this);
		this.clearSearchField = this.clearSearchField.bind(this);




	}

	handleChange(event) {

		let value = event.target.value;

		this.setState({ searchText: value });

		DataActions.setSearchProductText(value)

	}


	clearSearchField(event) {
		this.setState({ searchText: "" });
		DataActions.setSearchProductText("");
	}

	onHistoryClick() {
		DataActions.showHistory();
	}

	componentWillMount() {

		//DataStore.on(Constants.PRODUCT_TYPE_CHANGED, () => { this.setup(); })
		DataStore.on(Constants.SEARCH_BOX_CHANGED, () => { this.setup(); })
		DataStore.on(Constants.SEARCH_PRODUCT_TEXT_CHANGED, () => { this.setup(); })
		DataStore.on(Constants.HISTORY_LIST_CHANGED, () => { this.setup(); })


	}

	setup() {
		//this.setState({ productTypeString: DataStore.getProductTypeString() });
		this.setState({ searchBoxVisible: DataStore.getSearchBoxVisible() });
		this.setState({ searchText: DataStore.getSearchProduct() });
		this.setState({ historyList: DataStore.getHistoryList() });

	}

	render() {


		//const list = "/" + this.state.productTypeString + "/products/list/"
		//const grid = "/" + this.state.productTypeString + "/products/grid/"

		//alert(this.state.searchBoxVisible)

		const css = this.state.searchBoxVisible ? "searchMenu displayBlock" : "searchMenu displayNone"
		const searchField = this.state.historyList && this.state.historyList.length > 0 ? "searchField" : "searchField wide"



		return (

			<div className={css} >

				<div className={searchField}>
					<div className='searchFieldLabel'><h4>SÖK</h4></div>
					<input type="text" autoFocus value={this.state.searchText} onChange={this.handleChange} placeholder='Skriv här för att söka bland produkter, kategorier, varumärken...' />

					{this.state.searchText.length > 0 ?
						<img alt='img' src={buttonClose} title='Rensa sökfält' onClick={this.clearSearchField} className='pointer' />
						: <img alt='img' src={buttonSearch} title='' onClick={this.clearSearchField} />
					}

				</div>

				{this.state.historyList && this.state.historyList.length > 0 ?
					<div className='buttonHistory' onClick={this.onHistoryClick.bind(this)}>
						<img src={buttonHistory} />

						<h1>{this.state.historyList.length}</h1>

					</div> : null}


				<div className='clearBoth'></div>
			</div>
		)
	}
}