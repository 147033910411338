import React from "react"
import McTreeModell from "./McTreeModell";
//import '../../css/ProductGrid.css';
import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";


export default class CategoryTreeNode extends React.Component{

    constructor() {
        super();
        this.state = { display: false}
      }
    
   onClick(e){
    
    this.setState({display: !this.state.display})
    //DataActions.selectBrand(this.props.brand);
    DataActions.selectCategory(this.props.category); 

    if (!this.props.category.categoryList || this.props.category.categoryList.length == 0){
        

        if (DataStore.getFilteredProductList().length > 0){
            DataActions.sideMenuMcVisibility(false);
            DataActions.dropMenuCategoryVisibility(false);
        }
    }
    
   }

    
    render(){
    
        const css = {
           
        }

        //console.log("FP:", this.props.brand);

       
        
        return (
            <div className={'categoryTreeNode level_' + this.props.level} style={css}>
             <h3 onClick={this.onClick.bind(this)} > {this.props.category.name}  </h3> 

            <div className={this.state.display? "": "displayNone"} > 
             { this.props.category.categoryList && this.props.category.categoryList.map((item, index) => ( <CategoryTreeNode category={item}  key={item.id} level={ this.props.level + "x" } /> )) }
             
            </div>
            </div>

        )
    }
}

// { this.props.mc.modells.map((item, index) => ( <b> xx- {item.modellName}  <br/></b>  )) }

// { this.props.category.categoryList ? this.props.category.categoryList.length : "?" } visa antal under