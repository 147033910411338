import React from "react"

import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";
import check_off from '../../gfx/button_check_off.png';
import check_on from '../../gfx/button_check.png';
import button_right from '../../gfx/button_right.png';


export default class ProductAdmin extends React.Component{

    constructor() {
        super();
       
      }
    
   onClickStartPage(e){
    
        DataActions.event(this.props.product.id, DataStore.getLoginToken(), {action: "TOGGLE_START", productId: this.props.product.id});
   }

    
    render(){
    
       const cssRight = {"textAlign":"right"}

       const urlAdmin = "http://shop.boove.se/productList.php?view=&filterCategory=&categoryId=&search_artnr=S%D6K&artnr=" + this.props.product.artnr
        
        return (
            <div className='productAdmin'>
                <h3>ADMIN</h3>
                <table>
                    <tbody>
                <tr>
                    <td onClick={this.onClickStartPage.bind(this)}>Visa på startsida</td>
                    <td style={cssRight}><img src={this.props.product.sort == 0 ? check_off : check_on} onClick={this.onClickStartPage.bind(this)}/> </td>
                </tr>
                <tr>
                    <td ><a href={urlAdmin} target='_blank'>Länk till admin</a></td>
                    <td style={cssRight}><a href={urlAdmin} target='_blank'><img src={button_right}></img></a> </td>
                   
                </tr>
                </tbody>
                </table>
            </div>

        )
    }
}
