import React from "react"
import DataStore from "../stores/DataStore";
import buttonRight from '../../gfx/button_right.png';
import buttonClose from '../../gfx/button_close.png';
import * as DataActions from "../actions/DataActions";

var Constants = require('../Constants');

export default class DropMenuSearchResult extends React.Component{

    
    constructor(){
        super();
        this.state = {
            productListCountLabel: DataStore.getProductListCountLabel()

        }
    }

 

    componentDidMount(){

        DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => { this.setup() })
        DataStore.on(Constants.SEARCH_RESULT_CHANGED, () => { this.setup() })
    }

    onClick(){
        DataActions.dropMenuCategoryVisibility(false);
        DataActions.dropMenuManufactorVisibility(false);
    }

    setup(){
        this.setState({productListCountLabel: DataStore.getProductListCountLabel()})
    }
   
    render(){

        var className = this.props.visible ? 'dropMenuManufactor displayBlock' : 'dropMenuManufactor displayBlock' ;

        return (
            
                
            <div className='dropMenuSearchResult' onClick={this.onClick.bind(this)}><h3>{this.state.productListCountLabel} </h3><img src={buttonClose} /></div>

        )
    }
}