import React from "react"
import DataStore from "../../stores/DataStore";
import * as DataActions from "../../actions/DataActions";
import "../../../css/Start.css"
var Constants = require('../../Constants');

export default class StartCategory extends React.Component{

    constructor(){
        super();
        this.state = { }
    }

    onClick(e){
        this.props.onClickCallback(this.props.category.key);
    }

    render(){

        const imagePath = DataStore.getImageUrl() + this.props.category.image;

        return (

                <div className='startCategory' onClick={this.onClick.bind(this)}> 
                    <img src={imagePath} />
                    <h2>{this.props.category.name}</h2>
                </div>
                
           
        )
    }
}
