import React from "react"
import McTreeBrand from "./McTreeBrand";
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import ManufactorList from "./ManufactorList";

import '../../css/DropMenuBikeType.css';

import buttonClose from '../../gfx/button_close.png'; 
import buttonSearch from '../../gfx/button_search.png';
import BikeTypeList from "./BikeTypeList";

var Constants = require('../Constants');

export default class DropMenuBikeType extends React.Component{

    
    constructor(){
        super();
    }

   

   onMouseOut(e){ 
    //DataActions.dropMenuManufactorVisibility(false);
   }

   componentDidMount(){
    
    }

    

   
    render(){

        var className = this.props.visible ? 'dropMenuBikeType displayBlock' : 'dropMenuBikeType displayNone' ;

        return (
            <div className={className} onMouseLeave={this.onMouseOut.bind(this)}>
                <div className="dropMenuContent">
                
                
                    { <BikeTypeList /> }
                
               
                </div>
                
                
            </div>

        )
    }
}