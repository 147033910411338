import React from "react"
import * as DataActions from "../actions/DataActions";
import button_up from '../../gfx/button_up_dark.png';
import button_down from '../../gfx/button_down_dark.png';
import DataStore from "../stores/DataStore";

var Constants = require('../Constants');
export default class CustomerNews extends React.Component{

    constructor(props){
        
        super(props);
        this.state = { open: DataStore.getCustomerNewsVisibility() }
    }

    componentDidMount(){
        DataStore.on(Constants.CUSTOMER_NEWS_VISIBILITY_CHANGED, () => { this.setState({open: DataStore.getCustomerNewsVisibility()}) } )
    }
    onToggle(){

        const open = this.state.open;

        DataActions.setCustomerNewsVisibility(!open);
    }
  
    
    render(){
        
        console.log("FP: ", this.props);
        return (
            <div className='customerNews'>
                    
                <div className='customerNewsHeader' onClick={this.onToggle.bind(this)}>
                    <img src={this.state.open ? button_up : button_down } />
                    <h1>Nyheter</h1>
                </div>

                {this.state.open ?
                <div>

                
                    {this.props.customer && this.props.customer.newsList && this.props.customer.newsList.map((news, index) => {
                        return <div className='news'>
                                     <p className='html' dangerouslySetInnerHTML={{ __html: news.html }}></p>{news.datef}
                    
                    <div className='clearBoth'></div></div>
                    })}
                   
                
                  <div className='buttonHide' onClick={this.onToggle.bind(this)}><h3>DÖLJ NYHETER</h3></div>
                </div> : null }
       

                </div>   

        )
    }
}