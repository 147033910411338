import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import "../../css/DropMenuManufactor.css"
var Constants = require('../Constants');

export default class ManufacatorListNode extends React.Component{

    constructor(){
        super();
        this.state = { 
            
        }
    }

    onClick(e){
        
        DataActions.selectManufactor(this.props.manufactor);  
        
        if (DataStore.getFilteredProductList().length > 0){
            DataActions.sideMenuMcVisibility(false);
            DataActions.dropMenuManufactorVisibility(false);
        }

    }

    render(){
    
        const title = this.props.manufactor.name + " " + this.props.manufactor.desc
        return (
           
                <div className={this.props.manufactor.inProductList ? "manufactorListNode hasProduct" : "manufactorListNode noProduct" } 
                onClick={this.onClick.bind(this)} title={title}> {this.props.manufactor.name}</div>
                
           
        )
    }
}
