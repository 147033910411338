import React from "react"
import button_delete from '../../gfx/button_delete_dark.png';
import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";
var Constants = require('../Constants');
var Colors = require('../Colors');

export default class Order extends React.Component{

    constructor(props){
        super(props);
        
        this.state = { edit:false, quantity: this.props.orderRow.quantity}
        
    }
    
    componentDidMount() {
    
        /*DataStore.on(Constants.ORDER_CHANGED, () => {
            this.setState({order: DataStore.getOrder()})
        })*/
    }

    shouldComponentUpdate(nextProps, nextState){
        if (nextProps.orderRow.quantity != this.state.quantity){
             this.state.quantity = nextProps.orderRow.quantity;
        }
 
        return true;
    }

    onQuantityClick(){

        if(this.props.locked){
            return;
        }
        if (this.state.edit){
            return;
        }

        const edit = this.state.edit;

        this.setState({edit: !edit})
    }

    onQuantityLeave(){
        this.setState({edit: false})
    }

    onClickUpdateQuantity(){

        if(this.props.locked){
            return;
        }

        if (this.state.quantity == 0 && !window.confirm("Vill du radera orderraden?")){
            return;
        }

        //alert("update " + this.props.orderRow.productId + " orderId" + this.props.orderId + " antal:" +  this.state.quantity);
        this.setState({edit: false});
        DataActions.order(DataStore.getLoginToken(), {action:'orderRow', orderId:this.props.orderId, orderRowId: this.props.orderRow.orderRowId, quantity:this.state.quantity})
    }

    onClickDelete(){
        
        if(this.props.locked){
            return;
        }

        if (!window.confirm("Vill du radera?")){
            return;
        }

        DataActions.order(DataStore.getLoginToken(), {action:'orderRow', orderId:this.props.orderId, orderRowId: this.props.orderRow.orderRowId, quantity:0})
    }

    onQuantityChange(event){
        this.setState({quantity: event.target.value})
    }

    onProductClick(){
        //DataActions.selectProductId(this.props.orderRow.productId)
        DataActions.getProduct(this.props.orderRow.productId, DataStore.getLoginToken(), Constants.SELECT_PRODUCT_DONT_UPDATE_URL);
        //DataActions.getProductRequest(this.props.orderRow.productId, DataStore.getLoginToken(), Constants.PRODUCT_REQUEST_COMPLETE);
    }

    render(){
        
        const css = {"color":Colors.RED}


        var stock_color = {"background":Colors.GREEN}
        var stock_label = "> 5" //this.props.orderRow.stock
        var stock_css = "stock stockOK"

        if (this.props.orderRow.stock == 0){
            stock_color = {"background":Colors.RED}
            //stock_label = "RING"
            stock_label = "0"
            stock_css = "stock stockNONE"
        }else if(this.props.orderRow.stock < 5){
            stock_color = {"background":Colors.ORANGE}
            //stock_label = "< 5"
            stock_label = this.props.orderRow.stock
            stock_css = "stock stockLOW"
        }


        const deliveryTimeLabel = this.props.orderRow.stock == 0 && this.props.orderRow.deliveryTime ? "Leveranstid: " + this.props.orderRow.deliveryTime : "Finns i lager";

        return (
            <div className='orderRow'>

                <div className='artnr pointer' onClick={this.onProductClick.bind(this)} title='Klicka för att visa produkt'>{this.props.orderRow.artnr}</div>
                <div className='product pointer' onClick={this.onProductClick.bind(this)} title='Klicka för att visa produkt'>
                    {this.props.orderRow.product}
        {this.props.orderRow.stock == 0 && this.props.orderRow.deliveryTime ? <b style={css}><br/> {deliveryTimeLabel}</b> : null}
                </div>
                
                
                <div className={stock_css} title={deliveryTimeLabel}>{stock_label}</div>


                <div className='quantity pointer' onClick={this.onQuantityClick.bind(this)} title='Klicka för att ändra antal'>
                    <div className={this.state.edit ? "edit editVisible":"edit editHidden"} title='Fyll i nytt antal'>
                        <input type='text' value={this.state.quantity} onChange={this.onQuantityChange.bind(this)}/><br/>
                        <button onClick={this.onQuantityLeave.bind(this)} title='Avbryt'>AVBRYT</button>
                        <button onClick={this.onClickUpdateQuantity.bind(this)} title='Spara nytt antal'>SPARA</button>
                        
                    </div>
                    <div className='label'>{this.props.orderRow.quantity}</div>
                </div>
                <div className={this.props.orderRow.outlet ? 'price outlet' : 'price'} title={this.props.orderRow.outlet ? 'OUTLET-PRIS' :''}>{this.props.orderRow.price}</div>
                {!this.props.locked ?
                <div className='buttonDelete pointer' title='Radera orderrad' onClick={this.onClickDelete.bind(this)}>
                    <img src={button_delete} />
                </div>:null}
            </div>

        )
    }
}