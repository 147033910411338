import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import McListYear from "./McListYear";
import "../../css/DropMenuMc.css"

import buttonUp from '../../gfx/button_up.png'; 
import buttonDown from '../../gfx/button_down.png'; 

export default class McListModell extends React.Component{

    constructor(props){
        super(props);
        this.state = { 
            display: this.props.display, 
            yy: this.props.p1
        }
        
    }

    divStyle = {
        display:'block',
        clear:'both'
    }

    onClick(e){
        this.setState({display: !this.state.display});
        DataActions.selectMc(this.props.mc);  
        DataActions.selectYear(null);
    }

    onYearIconClick(){
        this.setState({display: !this.state.display});
    }

    shouldComponentUpdate(){
        
        this.state.display = false
        return true
    }

      
    render(){
    
        return (
            <div style={this.divStyle} className='listModell'>
                { this.props.mc.years && this.props.mc.years.length > 0 ? 
                <img className='showYearButton' src={this.state.display ? buttonUp : buttonDown } onClick={this.onYearIconClick.bind(this)} title='Välj årsmodell'/> : null }
                <div onClick={this.onClick.bind(this)}> {this.props.mc.modellName} </div>
                <div className={this.state.display ? "yearDivStyleBlock": "yearDivStyleNone"}>{ this.props.mc.years.map((year, index) => ( <McListYear mc={this.props.mc} year={year} /> )) }</div>
                <div className='clearBoth'>&nbsp;</div>
            </div>

        )
    }
}
