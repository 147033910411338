import React from "react"

//import buttonUp from '../../gfx/button_box_up.png';
//import buttonDown from '../../gfx/button_box.png';

import buttonUp from '../../gfx/button_up.png';
import buttonDown from '../../gfx/button_down.png';

import '../../css/ProductBuyButton.css';

import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";

var Constants = require('../Constants')

export default class ProductBuyButton extends React.Component{

    constructor() {
        super();
        this.state = {open: false, quantity: 1}
        
      }

    
    
    onBuyClicked(event){

        const selectedProduct = DataStore.getProduct()

  
        const open = this.state.open;

        /*if (this.hasPkg()){
            this.setState({open: !open})
            return;
        }*/

        if ((selectedProduct && this.props.product.id == selectedProduct.id || this.props.selectedProduct)  && this.props.product.optionCount == 0){ // Fullscreen, no options
            if (this.hasPkg()){
                this.setState({open: !open})
            }else{
                this.addProductToOrder(1);
            }
            return;
        }

        if ((selectedProduct && this.props.product.id == selectedProduct.id || this.props.selectedProduct) && this.props.product.optionCount > 0){ // Fullscreen, options
            if (this.hasPkg()){
                this.setState({open: !open})
            }else{
                this.addProductToOrder(1);
            }
            //this.setState({open: !open})
            return;
        }

        const viewType = DataStore.getViewType();

        // grid
        if (viewType == "grid" && this.props.product.optionCount > 0){
            DataActions.getProduct(this.props.product.id);
            return;
        }

        if (viewType == "grid" && this.props.product.optionCount == 0){

            if (this.hasPkg()){
                this.setState({open: !open})
                return
            }else{
                this.addProductToOrder(1);
                return;
            }
        }

        //list
        if (viewType == "list" && this.props.product.optionCount > 0){

            DataActions.getProductReadMore(this.props.product.id);
            return;
            
        }

        if (viewType == "list" && this.props.product.optionCount == 0){

            if (this.hasPkg()){
                this.setState({open: !open})
                return
            }else{
                this.addProductToOrder(1);
                return;
            }
        }
        

        

        //this.setState({open: !open})
    
    }

    addProductToOrder(quantity){

        var order = DataStore.getOrder();

        //order = null;

        if (!order || !order.orderId){
            DataActions.setErrorMessage("Välj order");
            return;
        }

        DataActions.order(DataStore.getLoginToken(), 
                {"orderId": order.orderId, 
                "action":"addProduct", 
                "productId":this.props.product.id, 
                "productOptionSetId":this.props.productOptionSetId,
                "quantity":quantity})
    }

    onPackageSizeClicked(event, count){
        //alert("package: " + count + " ProductId:" + this.props.product.id + " ProductOptionId:" + this.props.productOptionId);
        this.addProductToOrder(count);

        event.stopPropagation();

    }

    

    onQuantityChange(event){
        this.setState({quantity: event.target.value});
    }

    onQuantityClick(){

        if (this.state.quantity > 0){
            this.addProductToOrder(this.state.quantity);
            this.setState({open: false});
        }else{
            alert("Välj antal");
        }
    }

    hasPkg(){
        return true; // FP: vi ska alltid visa välj antal-rutan
        return (this.props.product.pkg && this.props.product.pkg.length > 0)
    }

    /*

    Har tagit bort funktionen för att ha förpackning och använder den istället för antal. 

    */

    
    render(){
        
        return (

            
            <div className='productBuyButton pointer' >
                
                { !this.state.open ? 
                <div className='buyButtonHeader' onClick={this.onBuyClicked.bind(this)} >
                    <h4>KÖP</h4>
                    {this.hasPkg() && false ? <img src={this.state.open ? buttonUp : buttonDown} /> : null }
                </div> : null }

                { this.hasPkg() &&  this.state.open ? 
                <div className='packageSizes'>
                    <input type='text' className='quantity' onChange={this.onQuantityChange.bind(this)} value={this.state.quantity} placeholder='Antal'></input> 
                    <h4 onClick={this.onQuantityClick.bind(this)} className='quantityButton'>OK</h4>
                    {false && this.props.product.pkg.map((size, index) => ( <div className='packageSize' onClick={(event) => this.onPackageSizeClicked(event, size)}>{size}st</div> )) }
                    
                 
                </div> : null }
                
                 
            
            </div>

        )
    }
}

