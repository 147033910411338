import React from "react"

import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";
import Order from "./Order";
import button_reload from '../../gfx/button_reload_dark.png';
import { NavLink, useParams } from 'react-router-dom'

import '../../css/Customer.css';
import CustomerNews from "./CustomerNews";
import dataStore from "../stores/DataStore";
var Constants = require('../Constants');

export default class Customer extends React.Component{

    constructor(props){
        const customer = DataStore.getCustomer()

        super(props);
        this.state = { customer: customer,
                        companyTelephone: customer && customer.company ? customer.company.telephone:"",
                        companyUrl: customer && customer.company ? customer.company.url:"",
                        companyEmail: customer && customer.company ? customer.company.email:"",
                        companyPostaddress: customer && customer.company ? customer.company.postaddress:"",
                        companyPostnumber: customer && customer.company ? customer.company.postnumber:"",
                        companyCity: customer && customer.company ? customer.company.city:"",
                        companyEmailorder: customer && customer.company ? customer.company.emailorder:"",
                        

                        userEmail: customer && customer.company ?customer.userEmail:"",
                        userOldPassword: "",
                        newPassword: "",
                        newPassword2: "", 
                        errorMessage: null, 
                        message: null,
                        order: DataStore.getOrder()
                    }

                    
    }
    
    componentDidMount() {
    
        DataStore.on(Constants.CUSTOMER_CHANGED, () => { 

            const customer = DataStore.getCustomer();

            //alert("Customer Changed:" + customer.company.city)

            const company = customer && customer.company ? customer.company : null

            this.setState({customer: customer});  
            this.setState({companyTelephone: company?company.telephone:""});
            this.setState({companyUrl: company?company.url:""});
            this.setState({companyEmail: company?company.email:""});
            this.setState({companyPostaddress: company?company.postaddress:""});
            this.setState({companyPostnumber: company?company.postnumber:""});
            this.setState({companyCity: company?company.city:""});
            this.setState({companyEmailorder: company?company.emailorder:""});

            this.setState({userEmail: customer?customer.userEmail:""});  
            
        })

        DataStore.on(Constants.PASSWORD_CHANGED, () => {
            this.setState({message: "Lösenordet är uppdaterat"});
            this.setState({oldPassword: ""});
            this.setState({newPassword: ""});
            this.setState({newPassword2: ""});
        })

        DataStore.on(Constants.ORDER_CHANGED, () => {
            this.setState({customer: DataStore.getCustomer()});  
            this.setState({order: DataStore.getOrder()})
        })

        DataStore.on(Constants.LOGOUT, () => {  
            if (DataStore.handleLogoutMessage()){
                alert("Du har blivit utloggad, stäng webbläsaren");
            }
        }) 
        //DataActions.order(DataStore.getLoginToken());
    }


    onSaveCompanyClicked(){

        DataActions.company(DataStore.getLoginToken(), {"action":"updateCompany", 
            companyTelephone :this.state.companyTelephone,
            companyUrl: this.state.companyUrl,
            companyEmail: this.state.companyEmail,
            companyPostaddress: this.state.companyPostaddress,
            companyPostnumber: this.state.companyPostnumber,
            companyCity: this.state.companyCity,
            companyEmailorder: this.state.companyEmailorder
        
        });
    }

    onSaveUserClicked(){

        DataActions.company(DataStore.getLoginToken(), {"action":"updateUser", 
            userEmail:this.state.userEmail
        });
    }

    onChangePasswordClicked(){
        
        if (this.state.oldPassword && this.state.oldPassword.length < 2){
            this.setState({errorMessage: "Felaktigt lösenord"})
            return;
        }

        if (this.state.newPassword.length < 6){
            this.setState({errorMessage: "Nya lösenordet är för kort"})
            return;
        }

        if (this.state.newPassword != this.state.newPassword2){
            this.setState({errorMessage: "Lösenorden matchar inte"})
            return;
        }

        if (this.state.oldPassword == this.state.newPassword2){
            this.setState({errorMessage: "Gamla och nya lösenordet måste vara olika"})
            return;
        }
        
        DataActions.company(DataStore.getLoginToken(), {"action":"updatePassword", oldPassword:this.state.oldPassword, newPassword:this.state.newPassword});
    }

    onLogoutClick(){
        if (!window.confirm("Vill du logga ut? ")){
            return;
        }

        //alert("Du har blivit utloggad, stäng ned webbläsaren");

        //document.cookie = "login=;path=/;max-age=1;";
        //alert("Du måste eventuellt rensa inloggningsuppifter från webbläsaren samt starta om den.");
        
        /*if (document.cookie.length > 5 && window.confirm("Radera ? ")){
            document.cookie = "login=;path=/;max-age=1;";
            alert("Du måste eventuellt rensa inloggningsuppifter från webbläsaren samt starta om den.");
        }*/

        

        DataActions.logout(DataStore.getLoginToken())
    }

    onLoginClick(){
        DataActions.loginVisibility(true)
    }

    onCompanyTelephoneChanged(event){ this.setState({companyTelephone: event.target.value}) }
    onCompanyUrlChanged(event){ this.setState({companyUrl: event.target.value}) }
    onCompanyEmailChanged(event){ this.setState({companyEmail: event.target.value}) }
    onCompanyPostaddressChanged(event){ this.setState({companyPostaddress: event.target.value}) }
    onCompanyPostnumberChanged(event){ this.setState({companyPostnumber: event.target.value}) }
    onCompanyCityChanged(event){ this.setState({companyCity: event.target.value}) }
    onCompanyEmailorderChanged(event){ this.setState({companyEmailorder: event.target.value}) }
    

    onUserEmailChanged(event){ this.setState({userEmail: event.target.value}) }

    onOldPasswordChanged(event){ this.setState({oldPassword: event.target.value}); this.hideError() }
    onNewPasswordChanged(event){ this.setState({newPassword: event.target.value}); this.hideError() }
    onNewPassword2Changed(event){ this.setState({newPassword2: event.target.value}); this.hideError() }

    hideError(){
        this.setState({errorMessage:null})
    }

    render(){

       

        return (
            <div className='customer'>{ this.state.customer ? 
                
                <div>
                <div className='company'>
                <img className='reloadButton pointer' src={button_reload} onClick={() => {DataActions.order(DataStore.getLoginToken(), {"action":"reload"});}}/>
                    <h1> {this.state.customer.company.name} - FÖRETAGSINFORMATION</h1>
                   
                    
                   
                   
                </div>
                
                <div className='toolbar'>
                    
                    <NavLink to="/order" ><div className='button pointer' >ORDRAR</div></NavLink>
                    <div className='button pointer' ><a href='#companyDetails'>FÖRETAGSUPPGIFTER</a></div>
                    <div className='button pointer' ><a href='#userDetails'>INLOGGNINGSUPPGIFTER</a></div>
                    <div className='button pointer' onClick={this.onLogoutClick.bind(this)}>LOGGA UT</div>
                </div>
             
                <CustomerNews customer={this.state.customer}/>

                <div className='companyDetails' id='companyDetails'>
                    <h1>{this.state.customer.company.name}</h1>
                   <h2>Företagsuppgifter</h2>
                   <input type='text' placeholder='Telefonnummer' value={this.state.companyTelephone} onChange={this.onCompanyTelephoneChanged.bind(this)} /><br/>
                   <input type='text' placeholder='Webbsida' value={this.state.companyUrl} onChange={this.onCompanyUrlChanged.bind(this)} /><br/>
                   <input type='text' placeholder='E-post' value={this.state.companyEmail} onChange={this.onCompanyEmailChanged.bind(this)} /><br/>
                   <input type='text' placeholder='E-post Order' value={this.state.companyEmailorder} onChange={this.onCompanyEmailorderChanged.bind(this)} /><br/>
                   <input type='text' placeholder='Postadress' value={this.state.companyPostaddress} onChange={this.onCompanyPostaddressChanged.bind(this)} /><br/>
                   <input type='text' placeholder='Postnummer' value={this.state.companyPostnumber} onChange={this.onCompanyPostnumberChanged.bind(this)} /><br/>
                   <input type='text' placeholder='Stad' value={this.state.companyCity} onChange={this.onCompanyCityChanged.bind(this)} /><br/>
                   
                   <button onClick={this.onSaveCompanyClicked.bind(this)} className='pointer'>SPARA</button>
                </div>


                <div className='userDetails' id='userDetails'>
                <h1>{this.state.customer.userName}</h1>
                   <h2>Dina inloggningsuppgifter</h2>
                   <input type='text' placeholder='E-post' value={this.state.userEmail} onChange={this.onUserEmailChanged.bind(this)} /><br/>
                   <button onClick={this.onSaveUserClicked.bind(this)} className='pointer'>SPARA</button>
                   <br/> <br/>
                   <h2>Skapa nytt lösenord</h2>
                   <input type='password' placeholder='Nuvarande lösenord' value={this.state.oldPassword} onChange={this.onOldPasswordChanged.bind(this)} /><br/>
                   <input type='password' placeholder='Nytt lösenord' value={this.state.newPassword} onChange={this.onNewPasswordChanged.bind(this)} /><br/>
                   <input type='password' placeholder='Upprepa nytt lösenord' value={this.state.newPassword2} onChange={this.onNewPassword2Changed.bind(this)} /><br/>
                   
                   <div className={this.state.errorMessage ? "errorMessage visible":"errorMessage hidden"} onClick={()=> {this.setState({errorMessage:null})}}>
                        <div>{this.state.errorMessage}</div>
                    </div>

                    <div className={this.state.message ? "message visible":"message hidden"} onClick={()=> {this.setState({message:null})}}>
                        <div>{this.state.message}</div>
                    </div>

                   <button onClick={this.onChangePasswordClicked.bind(this)} className='pointer'>UPPDATERA</button>
                </div>
               </div> : <div onClick={this.onLoginClick.bind(this)} className='pointer'>Klicka här för att logga in</div> }

                
            </div> 

        )
    }
}