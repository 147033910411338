import React from "react"
import DataStore from "../stores/DataStore";

import * as DataActions from "../actions/DataActions";
import McListYear from "./McListYear";
import "../../css/DropMenuMc.css"


import buttonUp from '../../gfx/button_up.png'; 
import buttonDown from '../../gfx/button_down.png'; 

export default class McTreeModell extends React.Component{

    constructor(){
        super();
        this.state = { 
            mcListLength: DataStore.getMcList().length, 
            display: false
        }

        
    }

    divStyle = {
        display:'block',
        clear:'both'
    }

    onClick(e){
        this.setState({display: !this.state.display});


        /*const currentMc = DataStore.getMc();

        if (currentMc && this.props.mc && currentMc.id === this.props.mc.id){
            DataActions.selectMc(null);  
        }else{
            DataActions.selectMc(this.props.mc);  
        }*/
        
        DataActions.selectMc(this.props.mc);  

        DataActions.selectYear(null);

        
    }


    onYearIconClick(){
        this.setState({display: !this.state.display});
    }

    
    
    render(){
    
        return (
            <div style={this.divStyle} className="mcTreeModell">
                { this.props.mc.years && this.props.mc.years.length > 0 ? 
                <img className='showYearButton' src={this.state.display ? buttonUp : buttonDown } onClick={this.onYearIconClick.bind(this)}/> : null }
                <div onClick={this.onClick.bind(this)}> {this.props.mc.modellName}   </div>
                <div className={this.state.display? "yearDivStyleBlock": "yearDivStyleNone"}>{ this.props.mc.years.map((year, index) => ( <McListYear mc={this.props.mc} year={year} /> )) }</div>
                <div className='clearBoth'></div>
            </div>

        )
    }
}