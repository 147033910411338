import React from "react"
import '../../css/SideMenu.css';
import McTree from "./McTree";
import McList from "./McList";
import CategoryTree from "./CategoryTree";
import CategoryList from "./CategoryList";
import ManufactorList from "./ManufactorList";
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import buttonClose from '../../gfx/button_close.png'; 
import buttonCloseSearch from '../../gfx/button_close_dark.png'; 
import buttonSearch from '../../gfx/button_search_dark.png'; 
import BikeTypeList from "./BikeTypeList";
import FilterMc from "./FilterMc";


var Constants = require('../Constants')

export default class SideMenu extends React.Component{

    
    constructor(){
        super();
        this.state = {
                searchMc: DataStore.getSearchMc(), 
                brand: DataStore.getBrand(), 
                mc: DataStore.getMc(), 
                year: DataStore.getYear(), 
                category: DataStore.getCategory(),
                productListCount: DataStore.getFilteredProductListUnlimitedLength(),
                searchCategory: DataStore.getSearchCategory(),
                searchManufactor: DataStore.getSearchManufactor(),
                manufactor: DataStore.getManufactor(),
                productType: DataStore.getProductType(),
                bikeType: DataStore.getBikeType()

            }
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleChange3 = this.handleChange3.bind(this)
        this.onClickDeleteMC = this.onClickDeleteMC.bind(this);
        this.onClickDeleteCategory = this.onClickDeleteCategory.bind(this);
        this.onClickDeleteManufactor = this.onClickDeleteManufactor.bind(this);
    }
    

   componentDidMount(){
    
        DataStore.on("mcListChanged", () => { this.setup() })
        DataStore.on("brandChanged", () => { this.setup() })
        DataStore.on(Constants.MC_CHANGED, () => { this.setup() })
        DataStore.on(Constants.PRODUCT_LIST_CHANGED,() => { this.setup() })
        DataStore.on(Constants.YEAR_CHANGED, () => { this.setup() })
        DataStore.on("categoryListChanged", () => { this.setup() })
        DataStore.on(Constants.CATEGORY_LIST_CHANGED, () => { this.setup() })
        DataStore.on(Constants.MC_LIST_CHANGED, () => { this.setup() })
        DataStore.on(Constants.CATEGORY_SELECTED, () => { this.setup() })
        
        DataStore.on(Constants.MANUFACTOR_SELECTED, () => {this.setup() } )
        DataStore.on(Constants.MANUFACTOR_LIST_CHANGED, () => {this.setup() } )
        DataStore.on(Constants.PRODUCT_TYPE_CHANGED, () => {this.setup() })
        DataStore.on(Constants.BIKE_TYPE_SELECTED, () => { this.setup() })
        DataStore.on(Constants.SEARCH_CATEGORY_TEXT_CHANGED, () => { this.setup() })
        DataStore.on(Constants.SEARCH_MANUFACTOR_TEXT_CHANGED, () => { this.setup() })
    }

    

    setup(){
        
        this.setState({productListCount: DataStore.getFilteredProductListUnlimitedLength()})
        this.setState({mc: DataStore.getMc()})
        this.setState({year: DataStore.getYear() })
        this.setState({brand: DataStore.getBrand()})
        this.setState({searchMc: DataStore.getSearchMc()})
        this.setState({searchCategory: DataStore.getSearchCategory()})
        this.setState({category: DataStore.getCategory()})
        this.setState({searchManufactor: DataStore.getSearchManufactor() })
        this.setState({manufactor: DataStore.getManufactor() })
        this.setState({productType: DataStore.getProductType()})
        this.setState({bikeType: DataStore.getBikeType() })
    }


    onClickDeleteMC(event){
        DataActions.selectMc(null);
        event.stopPropagation(); 
      }

    onClickDeleteYear(event){
        DataActions.selectYear(null);
        event.stopPropagation();
    }

    onClickDeleteBrand(event){
        DataActions.selectYear(null);
        event.stopPropagation(); 
      }

    onClickDeleteManufactor(event){
        DataActions.selectManufactor(null);
        event.stopPropagation();
    }

    onClickDeleteBikeType(event){
        DataActions.selectBikeType(null);
        event.stopPropagation();
    }

    onSectionClick(e){
       console.log("onSectionClick");

    //this.props.changeTitle("---");
   }

   onClickDeleteCategory(event){
        DataActions.selectCategory(null);
        event.stopPropagation();
   }

   onShowProductListClick(e){
    //this.props.hide();
    DataActions.sideMenuMcVisibility(false);
   }

   handleChange(event) {
        
    let value = event.target.value;

    this.setState({searchMc: value} );
    //console.log("Value: ", value);

    DataActions.searchMcTextChanged(value)  
    
  }


  handleChange2(event) {
        
    let value = event.target.value;

    this.setState({searchCategory: value} );
    //console.log("Value: ", value);

    DataActions.searchCategoryTextChanged(value)  

    //alert(123);
    
  }


  handleChange3(event) {
        
    let value = event.target.value;

    this.setState({searchManufactor: value} );
    //console.log("Value: ", value);

    DataActions.searchManufactorTextChanged(value)  

    //alert(123);
    
  }
   
  clearSearchFieldMC(event){
    DataActions.searchMcTextChanged("");  
  }

  clearSearchFieldCategory(event){
      DataActions.searchCategoryTextChanged("");
  }

  clearSearchFieldManufactor(event){
    DataActions.searchManufactorTextChanged("");
  }
   
  categoryOnClick(){

    var element = document.getElementById("sideMenu");
    var clientHeight  = document.getElementById('sideMenu').clientHeight

    element.scrollTo({
        top: (clientHeight / 2) - 0,
        left: 0,
        behavior: 'smooth'
    });
  }

  mcOnClick(){
      
    var element = document.getElementById("sideMenu");
    var clientHeight  = document.getElementById('sideMenu').clientHeight

    element.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
  }

    divStyle = {
        color:'yellow'
    }

    
    render(){

        var divStyleA = { top: '0' }
        var divStyleB = { top: '85px' }
        var divStyleC = { top: '155px' }
        var divStyleD = { top: '225px' }


        var selectModellLabel = "MC-Modeller";

        if (this.state.brand){
          selectModellLabel = "";
        }


        var className = this.props.visible ? 'sideMenu sideMenuVisible' : 'sideMenu sideMenuHidden' ;

        return (
            
            <div className={className} id='sideMenu' >

                <div className=''>

                   
                    <div style={divStyleA} className='filterResult' ><div className='showProducts'><div onClick={this.onShowProductListClick.bind(this)}><b> {this.state.productListCount}</b><br/> PRODUKTER</div></div>
                        <div className='filters'>
                            
                            {this.state.brand ? <div className='filter' onClick={this.onClickDeleteBrand.bind(this)}>{this.state.brand.name} <img src={buttonClose} /></div>: ""} 
                            {this.state.mc && false? <div className='filter' onClick={this.onClickDeleteMC.bind(this)}>{this.state.mc.modellName} <img src={buttonClose} /></div>: ""} 
                            {this.state.mc ? <FilterMc mc={this.state.mc} year={this.state.year} dropdownHidden={true}/> : null }

                            {this.state.year ? <div className='filter' onClick={this.onClickDeleteYear.bind(this)}>{this.state.year && this.state.year.year} <img src={buttonClose} /></div>: ""} 
                            {this.state.category ? <div className='filter right' onClick={this.onClickDeleteCategory.bind(this)}> {this.state.category && this.state.category.label} <img src={buttonClose} /></div>: ""} 
                            {this.state.manufactor ? <div className='filter' onClick={this.onClickDeleteManufactor.bind(this)}>{this.state.manufactor && this.state.manufactor.name} <img src={buttonClose} /></div>: ""} 

                            {this.state.bikeType ? <div className='filter' onClick={this.onClickDeleteBikeType.bind(this)}>{this.state.bikeType && this.state.bikeType.name} <img src={buttonClose} /></div>: ""} 
                            
                        </div>
                    </div>

                    
                    
                    
                        {this.state.productType == 1 || this.state.productType == 1 ?
                        <div style={divStyleB} className='stickyHeader' onClick={this.onSectionClick.bind(this)}>
                        <div className="search">   
                            <input type='text' value={this.state.searchMc} onChange={this.handleChange} placeholder='MC-Modeller' onClick={this.mcOnClick.bind(this)} />
                            {this.state.searchMc && this.state.searchMc.length > 0 ? <img className='closeButton' alt='img' src={buttonCloseSearch} onClick={this.clearSearchFieldMC.bind(this)}/> : <img className='searchButton' alt='img' src={buttonSearch} />}
                        </div>
                        </div> : null}

                        {this.state.productType == 2 ?
                        <div style={divStyleB} className='stickyHeader' onClick={this.onSectionClick.bind(this)}>
                        <h3>Cyklar</h3>
                        </div> : null}
                    
                        { (this.state.productType == 1 || this.state.productType == 1) && this.state.searchMc.length == 0 ? <McTree />: null }
                        { (this.state.productType == 1 || this.state.productType == 1) && this.state.searchMc.length != 0 ? <McList />: null }
                        { (this.state.productType == 2) && this.state.searchMc.length == 0 ? <BikeTypeList />: null }


         


                        <div style={this.state.productType == 3 ? divStyleB : divStyleC} className='stickyHeader' onClick={this.onSectionClick.bind(this)}>
                        
                            <div className="search">   
                                    <input type='text' value={this.state.searchCategory} onChange={this.handleChange2} placeholder='Kategorier' onClick={this.categoryOnClick.bind(this)} />
                                    {this.state.searchCategory && this.state.searchCategory.length > 0 ? <img className='closeButton' alt='img' src={buttonCloseSearch} onClick={this.clearSearchFieldCategory.bind(this)}/> : <img className='searchButton' alt='img' src={buttonSearch} />}
                            </div>
                        </div>
                
                    { this.state.searchCategory.length == 0 ? <CategoryTree />: <CategoryList /> }

                   


                    <div style={divStyleD} className='stickyHeader' onClick={this.onSectionClick.bind(this)}>
                    
                        <div className="search">   
                                    <input type='text' value={this.state.searchManufactor} onChange={this.handleChange3} placeholder='Varumärken' />
                                    {this.state.searchManufactor && this.state.searchManufactor.length > 0 ? <img className='closeButton' alt='img' src={buttonCloseSearch} onClick={this.clearSearchFieldManufactor.bind(this)}/> : <img className='searchButton' alt='img' src={buttonSearch} />}
                            </div>
                    </div>

                    
                    { <ManufactorList /> }
                    
                    
                </div>
    
 

                
            </div>

        )
    }
}