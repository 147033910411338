import dispatcher from "../Dispatcher";
import axios from "axios"
import DataStore from "../stores/DataStore";

var Constants = require('../Constants')

var timeout = 1800000;
var timeoutProductList = 30 * 1001;
var productListLoading = false;

var timeoutBaseModel = 20 * 1003;
var baseModelLoading = false;

var timeoutProduct = 10 * 1002;

var timeoutStartModel = 30 * 1004;
var startModelLoading = false;

export function showHistory() {

	dispatcher.dispatch({
		type: Constants.SHOW_HISTORY
	})

}

export function searchMcTextChanged(text) {

	dispatcher.dispatch({
		type: Constants.SET_SEARCH_MC_TEXT,
		text
	})

}

export function searchCategoryTextChanged(text) {

	dispatcher.dispatch({
		type: Constants.SET_SEARCH_CATEGORY_TEXT,
		text
	})

}


export function searchManufactorTextChanged(text) {

	dispatcher.dispatch({
		type: Constants.SET_SEARCH_MANUFACTOR_TEXT,
		text
	})

}


export function setSearchProductText(text) {

	dispatcher.dispatch({
		type: Constants.SET_SEARCH_PRODUCT_TEXT,
		text
	})

}

export function newRequest() {
	dispatcher.dispatch({
		type: "NEW_REQUEST"
	})
}

export function requestComplete() {
	dispatcher.dispatch({
		type: Constants.REQUEST_COMPLETE
	})
}

export function selectMc(mc) {

	dispatcher.dispatch({
		type: Constants.SELECT_MC,
		mc
	})
}

export function selectYear(year) {
	dispatcher.dispatch({
		type: Constants.SELECT_YEAR,
		year
	})
}

export function selectCategory(category) {
	dispatcher.dispatch({
		type: Constants.SELECT_CATEGORY,
		category
	})
}

export function selectCategoryGrid(categoryKey, updateSearchField) {
	dispatcher.dispatch({
		type: Constants.SELECT_CATEGORY_GRID, categoryKey, updateSearchField
	})
}

/*export function selectProduct(product){
    dispatcher.dispatch({
        type: "SELECT_PRODUCT", 
        product
    })
}*/

export function hideProduct() {
	/*
	dispatcher.dispatch({
			type: Constants.HIDE_PRODUCT, 
			product: null
	})*/
}

export function selectProductType(productType) {
	dispatcher.dispatch({
		type: "SELECT_PRODUCT_TYPE", productType
	})
}

function getBaseUrl() {

	var url = "https://api2.boove.se/w2/";

	//var url = "https://api.boove.se/w2/";

	if (process.env.NODE_ENV == "development") {
		url = "http://api2.boove.se.dev2/w2/";

	}

	return url;
}

function getUserName() {
	return "w2";
}

function getPassword() {
	return "w2api";
}


export function getProductList(loginToken, appendSession = true) {


	if (productListLoading) {
		console.log("FP22: productList request already loading");
		return;
	}
	var baseUrl = getBaseUrl();

	newRequest()
	productListLoading = true;

	console.log("FP22: productList request start");
	axios.defaults.timeout = timeoutProductList;
	axios.post(baseUrl + "productList/?cache=yes&appendSession=" + appendSession, { responseType: 'stream', timeout: timeoutProductList, auth: { username: getUserName(), password: getPassword() }, loginToken })

		.then(res => {


			console.log("FP22: productList response done");

			productListLoading = false;

			dispatcher.dispatch({
				type: "GET_PRODUCT_LIST_COMPLETE",
				productList: res.data.productList,
				log: res.data.log
			})

			//console.log("FP22: productList loginToken", DataStore.getLoginToken());

			/*let reloadProductId = DataStore.getReloadProductId();

			if (reloadProductId > 0){
					DataStore.setReloadProductId(null);
					getProduct(reloadProductId, DataStore.getLoginToken());
			}*/
			//getProduct(10, DataStore.getLoginToken());

			requestComplete();

			if (res.data && res.data.status == "OK") {
				serverLogInfo("ProductList loaded");
			} else if (appendSession) {
				serverLogError("ProductList failed, append session");
			} else {
				serverLogError("ProductList failed, dont append session");
			}

		})
		.catch(error => {
			productListLoading = false;
			console.log("FP22: productList response error");

			serverLogError("ProductList failed. Error: " + error);
			//alert("ProductList error: " + error); 
			requestComplete();
			if (window.confirm("ProductList error: " + error + "\nFörsök igen?")) {
				getProductList(loginToken, appendSession);
			}

		}
		)

}




export function getProduct(productId, loginToken, selectProductEventType) {

	if (!selectProductEventType) { // välj eventtyp som ska triggas när man valt produkten ur listan, detta för att kunna styra om urlen ska ändraas eller ej
		selectProductEventType = Constants.SELECT_PRODUCT;
	}

	if (productId == null) {
		dispatcher.dispatch({ type: selectProductEventType, product: null })
		return;
	}

	// leta upp prod i listan och sätt den, 
	const product = DataStore.findProduct(productId);

	if (!product) {
		this.errorMessage = "Kunde inte hitta produkt";
		dispatcher.dispatch({ type: selectProductEventType, product: null })
	} else {
		// FP: Här ersätter vi kompletta produkten med bara data från listan
		dispatcher.dispatch({ type: selectProductEventType, product: product })
		//alert(selectProductEventType);
	}

	getProductRequest(productId, loginToken, Constants.GET_PRODUCT_COMPLETE);
}

export function getProductReadMore(productId, loginToken) {
	getProductRequest(productId, loginToken, Constants.GET_PRODUCT_READMORE_COMPLETE);
}

export function getProductFromUrl(productId, loginToken) {
	console.log("FP22", "getProductFromUrl");
	getProductRequest(productId, loginToken, Constants.GET_PRODUCT_FROM_URL_COMPLETE);
}

export function getProductRequest(productId, loginToken, callback) {

	dispatcher.dispatch({ type: Constants.ADD_TO_HISTORY, productId });

	if (DataStore.isProductLoading(productId)) {
		console.log("FP22: Laddar redan... sparar: " + productId);
		DataStore.setReloadProductId(productId);
		return;
	}


	var baseUrl = getBaseUrl();

	dispatcher.dispatch({ type: Constants.NEW_PRODUCT_REQUEST, productId })

	axios.post(baseUrl + "product/" + productId, { responseType: 'stream', timeout: timeoutProduct, auth: { username: getUserName(), password: getPassword() }, loginToken })

		.then(res => {

			dispatcher.dispatch({ type: Constants.PRODUCT_REQUEST_COMPLETE, productId })


			dispatcher.dispatch({
				type: callback,
				product: res.data.product
			})

			let reloadProductId = DataStore.getReloadProductId();

			if (reloadProductId > 0) { // om produkten requestades igen medans den laddade så hämta igen. 
				console.log("FP22: Laddar igen... loginToken: " + DataStore.getLoginToken());
				DataStore.setReloadProductId(null);
				getProductRequest(productId, DataStore.getLoginToken(), callback);
			}


		})
		.catch(error => {
			alert("Product error: " + error);
			dispatcher.dispatch({ type: Constants.PRODUCT_REQUEST_COMPLETE, productId })
		}
		)

}

export function getBaseModel() {


	if (baseModelLoading) {
		console.log("FP22: BaseModel already loading");
		return;
	}
	var baseUrl = getBaseUrl();



	newRequest()
	baseModelLoading = true;
	console.log("FP22: BaseModel loading");

	axios.get(baseUrl + "baseModel/?cache=yes", { responseType: 'stream', timeout: timeoutBaseModel, auth: { username: getUserName(), password: getPassword() } })

		.then(res => {

			console.log("FP22: BaseModel loading complete");
			baseModelLoading = false;
			//alert("AXIOS:" + res.data.manufactorList.length);
			//console.log("FP:", res.data);

			dispatcher.dispatch({
				type: Constants.GET_BASE_MODEL_COMPLETE,
				data: res.data
			})

			requestComplete();

		})
		.catch(error => {
			baseModelLoading = false;

			console.log("FP22: BaseModel loading failed");
			//alert("BaseModell Error: " + error); 

			requestComplete();

			serverLogError("Ett fel uppstod: " + error);

			if (window.confirm("Ett fel uppstod: " + error + "\nFörsök igen?")) {
				getBaseModel();
			}
		}
		)
}

export function getStartModel(loginToken) {

	if (startModelLoading) {
		console.log("FP22: StartModel already loading");
		return;
	}
	var baseUrl = getBaseUrl();

	newRequest()
	startModelLoading = true;

	axios.get(baseUrl + "startModel/", { responseType: 'stream', timeout: timeoutStartModel, auth: { username: getUserName(), password: getPassword() } })

		.then(res => {

			console.log("FP22: StartModel loading complete");
			startModelLoading = false

			dispatcher.dispatch({
				type: Constants.GET_START_MODEL_COMPLETE,
				startModel: res.data.startModel
			})

			requestComplete();

			getProductList(loginToken);
			getBaseModel();

			if (res.data && res.data.status == "OK") {
				serverLogInfo("Startmodel loaded");
			} else {
				serverLogError("Startmodel failed");
			}


		})
		.catch(error => {
			console.log("FP22: StartModel loading error");
			startModelLoading = false

			serverLogError("Startmodel failed: " + error);

			requestComplete();

			if (window.confirm("Ett fel har inträffat! " + error + "\nFörsök igen?")) {
				getStartModel(loginToken);
			}
		}
		)
}

export function event(productId, loginToken, event) {

	var baseUrl = getBaseUrl();

	newRequest()

	axios.post(baseUrl + "event/", { responseType: 'stream', timeout: 10000, auth: { username: getUserName(), password: getPassword() }, event, loginToken })

		.then(res => {

			if (res.data.status != "OK") {
				if (res.data.status.message) {
					alert(res.data.status.message);
				} else {
					alert("Ett okänt fel har inträffat");
				}
			}
			requestComplete();

			getProduct(productId, loginToken)

		})
		.catch(error => {
			alert(error);
			requestComplete();
		}
		)
}

export function serverLogError(message) {
	serverLog(message, "ERROR");
}

export function serverLogInfo(message) {
	serverLog(message, "INFO");
}

export function serverLog(message, type) {

	var baseUrl = getBaseUrl();

	if (type != "INFO") {
		type = "error";
	} else {
		type = "info";
	}

	axios.post(baseUrl + "log/" + type, { responseType: 'stream', timeout: 10000, auth: { username: getUserName(), password: getPassword() }, message })

		.then(res => {
			console.log("FP: serverLog: Sent log to server. type: " + type + ", ", message);
		})
		.catch(error => {
			alert(error);
		}
		)

}


export function order(loginToken, data) {

	var baseUrl = getBaseUrl();

	dispatcher.dispatch({ type: Constants.NEW_ORDER_REQUEST })

	axios.post(baseUrl + "order/", { responseType: 'stream', timeout: 10000, auth: { username: getUserName(), password: getPassword() }, data, loginToken })
		.then(res => {

			dispatcher.dispatch({ type: Constants.ORDER_REQUEST_COMPLETE })

			if (res.data.status == "OK") {

				//alert(res.data.action);

				dispatcher.dispatch({
					type: Constants.GET_ORDER_COMPLETE,
					loginToken: res.data.loginToken,
					customer: res.data.customer,
					selectOrderId: res.data.message == "createdOrder" && res.data.orderId ? res.data.orderId : false
				})

				dispatcher.dispatch({
					type: Constants.SET_ERROR_MESSAGE,
					errorMessage: null
				})

				if (data.action == "importProduct") {
					dispatcher.dispatch({
						type: Constants.ADD_IMPORT_MESSAGE,
						orderId: data.orderId,
						message: res.data.message,
						messageType: Constants.IMPORT_MESSAGE_OK
					})
				}

				if (data.action == "importOneProduct") {
					dispatcher.dispatch({ type: Constants.IMPORT_ONE_PRODUCT_COMPLETE })
				}

			} else {

				if (data.action == "importProduct") {
					dispatcher.dispatch({
						type: Constants.ADD_IMPORT_MESSAGE,
						orderId: data.orderId,
						message: res.data.message,
						messageType: Constants.IMPORT_MESSAGE_ERROR
					})
				} else {
					dispatcher.dispatch({
						type: Constants.SET_ERROR_MESSAGE,
						errorMessage: (res.data.message ? res.data.message : "Ett fel har uppstått. ")
					})
				}
			}

		})
		.catch(error => {

			dispatcher.dispatch({ type: Constants.ORDER_REQUEST_COMPLETE })

			if (error.response && error.response.status == 403) {

				dispatcher.dispatch({
					type: Constants.SET_ERROR_MESSAGE,
					errorMessage: "Du måste logga in"

				})

			} else {
				alert(error);
			}

		}
		)

}



export function company(loginToken, data) {

	var baseUrl = getBaseUrl();

	dispatcher.dispatch({ type: Constants.NEW_ORDER_REQUEST })

	axios.post(baseUrl + "company/", { responseType: 'stream', timeout: 10000, auth: { username: getUserName(), password: getPassword() }, data, loginToken })
		.then(res => {

			dispatcher.dispatch({ type: Constants.ORDER_REQUEST_COMPLETE })

			if (res.data.status == "OK") {

				//alert(res.data.action);
				if (res.data.message == "updatedPassword") {

					dispatcher.dispatch({
						type: Constants.PASSWORD_CHANGED
					})
				}

				dispatcher.dispatch({
					type: Constants.CUSTOMER_REQUEST_COMPLETE,
					loginToken: res.data.loginToken,
					customer: res.data.customer
				})

				dispatcher.dispatch({
					type: Constants.SET_ERROR_MESSAGE,
					errorMessage: null
				})

			} else {

				dispatcher.dispatch({
					type: Constants.SET_ERROR_MESSAGE,
					errorMessage: (res.data.message ? res.data.message : "Ett fel har uppstått. ")
				})
			}

		})
		.catch(error => {

			dispatcher.dispatch({ type: Constants.ORDER_REQUEST_COMPLETE })

			if (error.response && error.response.status == 403) {

				dispatcher.dispatch({
					type: Constants.SET_ERROR_MESSAGE,
					errorMessage: "Du måste logga in"

				})

			} else {
				alert(error);
			}

		}
		)

}

export function hasLoginTokenExpired(loginToken) {

	//alert(loginToken);

	var baseUrl = getBaseUrl();

	axios.post(baseUrl + "expired/logintoken/", { responseType: 'stream', timeout: 10000, auth: { username: getUserName(), password: getPassword() }, loginToken })

		.then(res => {

			if (res.data.status == "OK") {

				//console.log("FP:", "Token expire in: " + res.data.expire)

				if (res.data.expire <= 0) {

					dispatcher.dispatch({
						type: Constants.LOGINTOKEN_EXPIRED,
						errorMessage: "Du har blivit utloggad",
						expireMinutes: 0
					})

				} else if (res.data.expire < 30) {

					dispatcher.dispatch({
						type: Constants.LOGINTOKEN_WILL_EXPIRE,
						errorMessage: "Du blir snart utloggad",
						expireMinutes: res.data.expire
					})
					//alert("Du blir snart utloggad");
				}

			} else {

				dispatcher.dispatch({
					type: Constants.SET_ERROR_MESSAGE,
					errorMessage: (res.data.message ? res.data.message : "Ett fel har uppstått. ")
				})
			}

		})
		.catch(error => {

			dispatcher.dispatch({ type: Constants.ORDER_REQUEST_COMPLETE })

			if (error.response && error.response.status == 403) {

				dispatcher.dispatch({
					type: Constants.SET_ERROR_MESSAGE,
					errorMessage: "Du måste logga in"

				})

			} else {
				alert(error);
			}

		}
		)
}



export function login(userName, password, token) {

	var baseUrl = getBaseUrl();
	//axios.defaults.withCredentials = true // FP: Nedan testande för att se om sessionen gick att skapa med _session (gick ej pga cors)
	//axios.defaults.headers.post['Content-Type'] = 'application/json';
	//axios.defaults.headers.post['Credentials'] = 'true';
	//axios.defaults.headers.post['Origin'] = 'true';
	//axios.defaults.withCredentials = true;
	//axios.defaults.crossDomain = true;

	/*axios.post(baseUrl + "login/" + new Date().getTime() + "/", {
					responseType: 'stream', timeout: 10000, 
					auth: {username:getUserName(), password: getPassword()}, 
					data:{userName, password},
				  
				  
				  
	}, { //withCredentials: true, 
			headers: {'Origin': '*', 'Content-Type': 'application/json'}
		 
	}
	)*/

	axios.post(baseUrl + "login/" + new Date().getTime() + "/", {
		responseType: 'stream', timeout: 10000,
		auth: { username: getUserName(), password: getPassword() }, // För w2 login 
		data: { userName, password, token },
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
	})
		.then(res => {

			dispatcher.dispatch({
				type: Constants.LOGIN_REQUEST_COMPLETE,
				loginToken: res.data.loginToken,
				customer: res.data.customer,
				orderId: res.data.orderId,
				token: token // FP: token från cookie
			})


			console.log("FP22: login -> getProductList");
			// anropa produktList för att den ska laddas om så vi får rätt priser
			getProductList(res.data.loginToken);


			let reloadProductId = DataStore.getReloadProductId();

			if (reloadProductId > 0) { // om det visas en produkt och vi loggar in så ladda om den så att lagersaldo visas
				console.log("FP22: login -> getProductFromUrl");
				DataStore.setReloadProductId(null);
				getProductFromUrl(reloadProductId, DataStore.getLoginToken());

			} else if (DataStore.getProduct() && DataStore.getProduct().id > 0) {
				console.log("FF22: login -> getCurrentProduct")
				getProduct(DataStore.getProduct().id, DataStore.getLoginToken())
			}


			//requestComplete();
		})
		.catch(error => {
			console.log("FP: ERROR", error);
			console.log("FP: ERROR token", token);

			if (token == null) { // om vi skickade med en token för auto-inloggning så visa inget felmeddelande
				dispatcher.dispatch({
					type: Constants.LOGIN_REQUEST_COMPLETE,
					loginToken: null
				})
			}

		}
		)
}

/*
export function loginWithSession(){
   
    var baseUrl = getBaseUrl();

    axios.post(baseUrl + "login/" + new Date().getTime() + "/", {
            responseType: 'stream', timeout: 10000, 
            auth: {}, 
            data:{},
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .then(res => {

        dispatcher.dispatch({
            type: Constants.LOGIN_REQUEST_COMPLETE,
            loginToken: res.data.loginToken,
            customer: res.data.customer,
            orderId: res.data.orderId
        })

        // anropa produktList för att den ska laddas om så vi får rätt priser
        getProductList(res.data.loginToken);
        //requestComplete();
    })
    .catch(error => { 
        console.log("FP: ERROR", error);
        dispatcher.dispatch({
            type: Constants.LOGIN_REQUEST_COMPLETE,
            loginToken: null
        })

      }
    )

    
}*/


export function dropMenuMcVisibility(visible) {
	dispatcher.dispatch({
		type: Constants.DROP_MENU_MC_VISIBILITY,
		visible
	})
}

export function sideMenuMcVisibility(visible) {
	dispatcher.dispatch({
		type: Constants.SIDE_MENU_MC_VISIBILITY,
		visible
	})
}

export function dropMenuMcToggle() {
	dispatcher.dispatch({
		type: Constants.DROP_MENU_MC_TOGGLE
	})
}

export function dropMenuBikeTypeVisibility(visible) {
	dispatcher.dispatch({
		type: Constants.DROP_MENU_BIKE_TYPE_VISIBILITY, visible
	})
}

export function sideMenuToggle() {
	dispatcher.dispatch({
		type: Constants.SIDE_MENU_TOGGLE
	})
}

export function loginVisibility(visible) {
	dispatcher.dispatch({
		type: Constants.LOGIN_VISIBILITY,
		visible
	})
}


export function searchBoxToggle() {
	dispatcher.dispatch({
		type: Constants.SEARCH_BOX_TOGGLE
	})
}

export function selectBrand(brand) {
	dispatcher.dispatch({
		type: Constants.SELECT_BRAND,
		brand
	})
}

export function dropMenuCategoryVisibility(visible) {
	dispatcher.dispatch({
		type: Constants.DROP_MENU_CATEGORY_VISIBILITY,
		visible
	})
}

export function dropMenuCategoryToggle() {
	dispatcher.dispatch({
		type: Constants.DROP_MENU_CATEGORY_TOGGLE
	})
}

export function dropMenuManufactorVisibility(visible) {
	dispatcher.dispatch({
		type: Constants.DROP_MENU_MANUFACTOR_VISIBILITY,
		visible
	})
}

export function dropMenuManufactorToggle() {
	dispatcher.dispatch({
		type: Constants.DROP_MENU_MANUFACTOR_TOGGLE
	})
}

export function selectManufactor(manufactor) {
	dispatcher.dispatch({
		type: Constants.SELECT_MANUFACTOR,
		manufactor
	})
}

export function selectBikeType(bikeType) {
	dispatcher.dispatch({
		type: Constants.SELECT_BIKE_TYPE,
		bikeType
	})
}

export function selectViewType(viewType) {
	dispatcher.dispatch({
		type: Constants.SELECT_VIEWTYPE,
		viewType
	})

}

export function setLoginMessage(message) {
	dispatcher.dispatch({
		type: Constants.SET_LOGIN_MESSAGE,
		message
	})
}

export function setErrorMessage(errorMessage) {
	dispatcher.dispatch({
		type: Constants.SET_ERROR_MESSAGE,
		errorMessage
	})
}

export function setOrderId(orderId) {

	dispatcher.dispatch({
		type: Constants.SET_ORDER_ID,
		orderId
	})
}

export function setMessage(message) {
	dispatcher.dispatch({ type: Constants.SET_MESSAGE, message })
}

export function setFullsizeImage(fullsizeImage) {
	dispatcher.dispatch({ type: Constants.SET_FULLSIZE_IMAGE, fullsizeImage })
}

export function logout(loginToken) {

	var baseUrl = getBaseUrl();

	newRequest()

	axios.post(baseUrl + "logout/", { responseType: 'stream', timeout: timeout, auth: { username: getUserName(), password: getPassword() }, loginToken })

		.then(res => {

			dispatcher.dispatch({
				type: Constants.LOGOUT
			})
			requestComplete();



		})
		.catch(error => {
			alert(error);
			requestComplete();
		}
		)
}


export function setCustomerNewsVisibility(visibility) {
	dispatcher.dispatch({
		type: Constants.SET_CUSTOMER_NEWS_VISIBILITY,
		visibility
	})
}

export function setCurrentProduct(product) {
	dispatcher.dispatch({
		type: Constants.SET_CURRENT_PRODUCT,
		product
	})
}

export function xx(items) {


	filterAsync([1, 2, 3, 4, 5, 6], (i) => (i == 1 || i == 3)).then(result => {
		alert(result);
	});

	//console.log("FP: Filter: ", list);

}

export function addImportMessage(orderId, message, messageType) {
	dispatcher.dispatch({
		type: Constants.ADD_IMPORT_MESSAGE,
		orderId, message, messageType
	})

}

export function removeImportMessages() {
	dispatcher.dispatch({
		type: Constants.REMOVE_IMPORT_MESSAGES
	})
}

export function setSortBy(sortBy) {
	dispatcher.dispatch({
		type: Constants.SET_SORT_BY, sortBy
	})
}

export function setGroupBy(groupBy) {
	dispatcher.dispatch({
		type: Constants.SET_GRUOP_BY, groupBy
	})
}

export function setOnlyStock(onlyStock) {
	dispatcher.dispatch({
		type: Constants.SET_ONLY_STOCK, onlyStock
	})
}

export function setOnlyOutlet(onlyOutlet) {
	dispatcher.dispatch({
		type: Constants.SET_ONLY_OUTLET, onlyOutlet
	})
}

export function showAllProducts() {
	dispatcher.dispatch({
		type: Constants.SHOW_ALL_PRODUCTS
	})
}

export function appendProducts() {
	dispatcher.dispatch({ type: Constants.APPEND_PRODUCT_LIST })
}

export function hideAllDropdown() {
	dispatcher.dispatch({ type: Constants.HIDE_ALL_DROPDOWN });
}

export function enableDebugMode() {
	dispatcher.dispatch({ type: Constants.ENABLE_DEBUGMODE })
}

function mapAsync(array, callbackfn) {
	return Promise.all(array.map(callbackfn));
}

async function filterAsync(array, callbackfn) {
	const filterMap = await mapAsync(array, callbackfn);
	return array.filter((value, index) => filterMap[index]);
}



