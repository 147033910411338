import React from 'react';
import './App.css';
import Layout from './js/components/Layout.js'
import { BrowserRouter as Router} from 'react-router-dom'
import Route from 'react-router-dom/Route'

export default class App extends React.Component{


  onClick(){
    console.log("app.onClick()");
  }

  render(){
    return (
     
      
      <div className="App" onClick={this.onClick.bind(this)}>
        <Layout />
      </div>

    );
  }

}
/*

function App() {
  return (
    <div className="App" >
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Layout name='Fredde' />
        
  
      </header>
    </div>
  );
}

export default App;
*/

/*

  render(){
    return (
      <Router>
      <div className="App" >


        

        <Route path="/" exact component={News}/>
        
        <Route path="/products/:viewType"   component={Layout}  />

          
          

      </div>
      </Router>
    );
  }

  */