import React from "react"

import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";

import buttonRight from '../../gfx/button_right.png'; 

export default class ProductExtraInformationCategory extends React.Component{


    constructor(props){
        super(props);
    }
    
    onClick(){

        
        const category = DataStore.findCategoryList(this.props.category.id);

        

        if (category){
            DataActions.selectCategory(category);
            this.props.onCloseCallback();
            
        }

    }
  
    
    render(){
        
        return (    <div className='category' onClick={this.onClick.bind(this)}> <img src={buttonRight}  />{this.props.category.categoryLabel}</div>        )
    }
}