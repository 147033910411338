import React from "react"
import McTreeBrand from "./McTreeBrand";
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import CategoryTree from "./CategoryTree";
import CategoryList from "./CategoryList";
import '../../css/DropMenuCategory.css';
import CategoryGrid from "./CategoryGrid";

import buttonClose from '../../gfx/button_close.png'; 
import buttonSearch from '../../gfx/button_search.png';
import DropMenuSearchResult from "./DropMenuSearchResult";

var Constants = require('../Constants');

export default class DropMenuCategory extends React.Component{

    
    constructor(){
        super();
        this.state = {searchCategory: DataStore.getSearchCategory() }
        this.handleChange = this.handleChange.bind(this)
        this.clearSearchField = this.clearSearchField.bind(this)
    }

    handleChange(event) {
        
        let value = event.target.value;
    
        this.setState({searchCategory: value} );
    
        DataActions.searchCategoryTextChanged(value)  
        
      }

   /*onClick(e){

        this.props.toggleSideMenu();
   }*/

   onMouseOut(e){ 
    //DataActions.dropMenuCategoryVisibility(false);
   }

   componentDidMount(){
    
        DataStore.on(Constants.CATEGORY_LIST_CHANGED, () => { this.setup() } )

        DataStore.on(Constants.SEARCH_CATEGORY_TEXT_CHANGED, () => { this.setup() } )

    }

    setup(){
        this.setState({searchCategory: DataStore.getSearchCategory()})
    }

    componentWillUnmount(){
        DataStore.removeListener(Constants.CATEGORY_LIST_CHANGED, () => {} );
    }

    clearSearchField(){
        DataActions.searchCategoryTextChanged("")  
    }

   
    render(){

        var className = this.props.visible ? 'dropMenuCategory ' : 'dropMenuCategory displayNone' ;

        return (
            <div className={className} onMouseLeave={this.onMouseOut.bind(this)}>
                <div className="dropMenuContent">
                <div className='dropMenuHeader'>
                    <div className='searchField'>
                        <input type='text' value={this.state.searchCategory} onChange={this.handleChange} placeholder='Sök kategori...' />
                        <img  alt='img' src={this.state.searchCategory.length > 0 ? buttonClose : buttonSearch} onClick={this.clearSearchField}/>
                    </div>
                    
                    <DropMenuSearchResult />
                    
                </div>
                
                    { this.state.searchCategory.length == 0 ? <CategoryTree />: <CategoryList /> }
                
                    { DataStore.getProductType() == 1 ? <CategoryGrid /> : null  }
               
                </div>
                
                
            </div>

        )
    }
}