import React from "react"

import DataStore from "../stores/DataStore"
import * as DataActions from "../actions/DataActions";
import buttonDown from '../../gfx/button_down_dark.png';
import buttonDownWhite from '../../gfx/button_down.png';
import buttonUp from '../../gfx/button_up_dark.png';
import buttonUpWhite from '../../gfx/button_up.png';
import buttonReload from '../../gfx/button_reload.png';
import buttonReloadDark from '../../gfx/button_reload_dark.png';
import ProductOption from "./ProductOption";

import ProductBuyButton from "./ProductBuyButton";
import ProductExtraInformation from "./ProductExtraInformation";
import circle from '../../gfx/circle_dark.png';
import missingImage from '../../gfx/missing.jpg';
import ProductAdmin from "./ProductAdmin";
import ProductStock from "./ProductStock";
import { ProductImage } from "./ProductImage";
import { ProductGridImage } from "./ProductGridImage";
import dataStore from "../stores/DataStore";


var Constants = require('../Constants')

export default class Product extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showDebug: false,
			showPrice: false,
			showMore: false,
			/*showName: false, */
			didLogin: false,
			isProductLoading: DataStore.isProductLoading(this.props.product.id)
		}


	}


	componentDidMount() {
		DataStore.on(Constants.REQUEST_COUNT_CHANGED, () => { this.setState({ isProductLoading: DataStore.isProductLoading(this.props.product.id) }) });
		DataStore.on(Constants.LOGIN_SUCCEED, () => {
			this.setState({ didLogin: true })
		});
	}




	/*
	onProductNameClick(){
			 var showName = this.state.showName;

			 this.setState(!showName)
	}*/

	/*onHeaderClick(e){
			//alert(123);

			var showName = this.state.showName;

			this.setState({showName: !showName})

			//var showDebug = this.state.showDebug

			//this.setState({showDebug: !showDebug})
	}*/

	onPriceClick(e) {

		if (!this.state.showMore) {
			this.onMoreClick();
		}

		return

		//var showPrice = this.state.showPrice

		//this.setState({showPrice: !showPrice})

		const order = DataStore.getOrder()

		if (!order) {
			DataActions.setErrorMessage("Först måste du välja en order");
			return;
		}

		DataActions.order(DataStore.getLoginToken(), { "orderId": order.orderId, "action": "addProduct", "productId": this.props.product.id, "quantity": 1 })
	}

	onReloadMoreClick(e) {
		DataActions.getProductReadMore(this.props.product.id, DataStore.getLoginToken());
		this.setState({ 'didLogin': false });
	}

	onMoreClick(e) {

		//var showMore = this.state.showMore
		//this.setState({showMore: !showMore})

		if (this.props.startPage) {
			//alert(123);
			this.props.onClickCallback(this.props.product);
			return;
		}

		if (DataStore.getViewType() == "list") {
			var showMore = !this.state.showMore
			this.setState({ showMore: showMore })

			if (showMore) {
				DataActions.getProductReadMore(this.props.product.id, DataStore.getLoginToken());
			} else {
				DataActions.setCurrentProduct(null);
			}
		} else {
			//DataActions.selectProduct(this.props.product);
			DataActions.getProduct(this.props.product.id, DataStore.getLoginToken());
		}

		DataActions.hideAllDropdown();

	}

	onImageClick(e) {

		//alert(e.target.title);
		//return;
		/*var showImage = this.state.showImage

		this.setState({showImage: !showImage})
*/
		//alert(e.target.title);
		DataActions.setFullsizeImage(e.target.title)
	}

	shouldComponentUpdate(nextProps, nextState) {

		if (nextProps.product.id != this.props.product.id) {
			this.state.showMore = false
			this.state.showPrice = false
		}

		return true;
	}

	render() {

		//console.log(this.props);

		//this.props.changeTitle('HeaderTitle');

		//const baseUrl = "http://public.boove.se/w2image/"

		const baseUrl = DataStore.getImageUrl();


		const show = { 'display': 'block' }
		const hide = { 'display': 'none' }
		const showInline = { 'display': 'inline-block' }

		//const fullSize = {'height':'auto'}



		var imagePath = "";
		var css = null

		var cssProductDetails = this.props.isLoggedIn ? "productDetails buy" : "productDetails";
		if (this.props.product.image) {
			//imagePath = 'http://public.boove.se/w2image/' + this.props.product.image
			imagePath = baseUrl + this.props.product.image;

			css = { "background": "url(" + imagePath + ") center center no-repeat", "backgroundSize": "" }
		} else {
			imagePath = missingImage;
			css = { "background": "url(" + imagePath + ") center center no-repeat", "backgroundSize": "" }
		}

		const debug = this.props.product.id + "-" + this.props.product.artnr + " sc:" + this.props.product.score

		if (this.props.viewType == "list") {
			css = null;
		}

		/*

		<div className='imgbox' style={css} title={imagePath} onClick={this.onImageClick.bind(this)}>
						<div>
								<ProductImage className='productImage' src={imagePath} title={imagePath} onClick={this.onImageClick.bind(this)}  />
						</div>
					  
				</div>

		*/

		const priceTitle = dataStore.getPriceTitle(this.props.product)

		const titleMore = 'Klicka för mer info. ' + this.props.product.name;


		return (
			<div className='product' >
				{DataStore.isDebug() ? <div className='debug'>{this.props.product.artnrList} <br />Score: {this.props.product.score}</div> : null}
				<div onClick={this.onImageClick.bind(this)} title={imagePath} className='pointer' >

					{this.props.viewType == "list" ?
						<ProductImage className='productImage' css={css} src={imagePath} title={imagePath} />
						: <ProductGridImage className='productImage' css={css} src={imagePath} title={imagePath} />}


				</div>

				<div className={cssProductDetails}>
					<div className={this.props.highlightManufactor ? 'productGridSection' : ''}>
						<div style={{ 'display': 'flex' }}>
							<b className='brand pointer' style={{ 'flex-grow': '1' }} onClick={this.onMoreClick.bind(this)} title={titleMore} >{this.props.product.manufactorName} </b>
							<div onClick={this.onMoreClick.bind(this)} className='productBrand pointer' title={titleMore}>Mer info</div>
						</div>
					</div>

					<h3 onClick={this.onMoreClick.bind(this)} className='productName pointer' title={titleMore} /*style={this.state.showName ? fullSize: null }*/> {this.props.product.name} </h3>
					<b onClick={this.onMoreClick.bind(this)} className='artnr pointer' title={titleMore} >{this.props.product.artnr}  </b><br />
					<span onClick={this.onMoreClick.bind(this)} title={titleMore} className='pointer'>
						{this.props.product.descShort ? <p className='descShort' >{this.props.product.descShort}<br /></p> : ""}
						{this.props.product.optionCount == 0 ? <ProductStock product={this.props.product} isLoggedIn={this.props.isLoggedIn} /> : null}
					</span>


					<div className='productPriceBuy pointer'>

						{this.props.product.optionCount && this.props.product.optionCount > 0 ?

							<div className={this.props.product.outlet ? 'productOptionPrice outlet' : 'productOptionPrice'} onClick={this.onPriceClick.bind(this)}>


								<h4 className={this.props.product.outlet ? 'priceLabel outlet' : 'priceLabel'} title={priceTitle}>{this.props.product.pricef}</h4>
								<div className='productOptionCountLabel'>{this.props.product.optionCount} varianter</div>


							</div>

							: <div className={this.props.product.outlet ? 'productOnePrice outlet' : 'productOnePrice'} onClick={this.onPriceClick.bind(this)} >
								<h4 className={this.props.product.outlet ? 'priceLabel outlet' : 'priceLabel'} title={priceTitle}>{this.props.product.pricef}</h4>
							</div>}

						{this.props.isLoggedIn ?
							<div className='productBuyButtonWrapper'>
								{this.props.product.optionCount && this.props.product.optionCount > 0 ?
									<div className='productBuyButton' onClick={this.onMoreClick.bind(this)}>
										<div className='buyButtonHeader pointer'><h4 title='KÖP'>KÖP</h4></div></div>
									: <ProductBuyButton product={this.props.product} />}
							</div> : null}




					</div>


					{this.props.viewType == "list" ?
						<div>
							<div className='more' >
								<img src={this.state.showMore ? buttonUpWhite : buttonDownWhite} className='icon pointer' onClick={this.onMoreClick.bind(this)} />
								<div className='label pointer' onClick={this.onMoreClick.bind(this)}>KLICKA FÖR MER INFORMATION</div>
								{this.state.showMore ? <img src={buttonReload} className={this.state.isProductLoading ? 'icon pointer reload spinning' : 'icon pointer reload'} onClick={this.onReloadMoreClick.bind(this)} /> : null}
							</div>

							<div className='moreBox' style={this.state.showMore ? showInline : hide}>
								{this.state.didLogin ? <div className='reloadReadMore' onClick={this.onReloadMoreClick.bind(this)}><h4>Ladda om produkt</h4></div> : null}
								{this.state.isProductLoading && false ? <div className='spinnerProductReadMore'><img src={circle} /></div> : null}
								{this.props.product.productOption ? <ProductOption product={this.props.product} isLoggedIn={this.props.isLoggedIn} /> : null}

								{this.props.product.images && this.props.product.images.length > 0 ?
									<div className='imageListWrapper'>
										<div className='imageList'>

											{this.props.product.images && this.props.product.images.map((item, index) => (
												<div className='imageItem'><img src={baseUrl + item} title={baseUrl + item} onClick={this.onImageClick.bind(this)}></img></div>))}


										</div>
									</div> : null}
								<ProductExtraInformation product={this.props.product} />

							</div>



						</div> : null}
				</div>





				<div className='clearBoth' >&nbsp;</div>
			</div >

		)
	}
}