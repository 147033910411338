import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import "../../css/DropMenuCategory.css"
var Constants = require('../Constants');

export default class CategoryListNode extends React.Component{

    constructor(){
        super();
        this.state = { 
            
            display: false
        }
    }

    divStyle = {
        display:'block',
        clear:'both'
    }

    onClick(e){
        this.setState({display: !this.state.display});
        DataActions.selectCategory(this.props.category);  
        
    }

    
      
    render(){
        
        //console.log("FP", this.props );
    
        return (
            <div style={this.divStyle} className='categoryListNode' onClick={this.onClick.bind(this)}>
               {this.props.category.path} 
                
            </div>

        )
    }
}
