import React from "react"
import '../../css/ProductGrid.css';
import ProductList from "./ProductList";
import ProductGrid from "./ProductGrid";
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import '../../css/ProductResult.css';
import ManufactorInfo from "./ManufactorInfo"
var Constants = require('../Constants')

export default class ProductResult extends React.Component{

    constructor(props){
        super(props);
        this.state = {viewType: this.props.viewType, 
            isLoggedIn: DataStore.isLoggedIn(),
            filteredProductList: DataStore.getFilteredProductListLength(),
            filteredProductListUnlimited: DataStore.getFilteredProductListUnlimitedLength()
            
        }
        
    }
    

   componentWillMount(){
    
    
        //DataActions.selectViewType(this.state.viewType);

        DataStore.on(Constants.VIEWTYPE_CHANGED, () => {
           this.setState({viewType: DataStore.getViewType()})
        })

        DataStore.on(Constants.LOGIN_STATUS_CHANGED, () => { this.setState({isLoggedIn: DataStore.isLoggedIn() })});

        DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => { 
            //this.setState({filteredProductList: DataStore.getFilteredProductListLength() });
            this.setState({filteredProductListUnlimited: DataStore.getFilteredProductListUnlimitedLength() })
        
        });

        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
      }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('productResult');

        if (this.isBottom(wrappedElement) && this.state.filteredProductList < this.state.filteredProductListUnlimited && this.state.filteredProductList > 1) {
            //alert(123);
          DataActions.appendProducts();
          //console.log("appendProducts");
          
        }
      };

      isBottom(el) {
          //console.log(el.getBoundingClientRect().bottom + " " + window.innerHeight);
        return (el.getBoundingClientRect().bottom - 1) <= window.innerHeight; // FP: så man kan vara 1 px från botten
        
      }

    render(){

        return (
            <div className='productResult' id='productResult'>
            
                {this.state.filteredProductListUnlimited && this.state.filteredProductListUnlimited > 0 ? <ManufactorInfo /> : null }
                

                {this.state.viewType == "list" ? <ProductList isLoggedIn={this.state.isLoggedIn} /> : null }
                {this.state.viewType == "grid" ? <ProductGrid  isLoggedIn={this.state.isLoggedIn} /> : null }

                
        { this.state.filteredProductList > 0 && this.state.filteredProductList < this.state.filteredProductListUnlimited ? <div className='productCount'> Hämtar fler produkter... ({this.state.filteredProductList} av {this.state.filteredProductListUnlimited} )</div> : null }
                { this.state.filteredProductList > 0 && this.state.filteredProductList == this.state.filteredProductListUnlimited ? <div className='productCount'> Visar alla produkterna, {this.state.filteredProductListUnlimited} st</div> : null }
                
            </div>

        )
    }
}