import React from "react"
import DataStore from "../../stores/DataStore";
import {NavLink} from 'react-router-dom'
import "../../../css/FindBrand.css"
import buttonClose from '../../../gfx/button_close.png'; 
import buttonSearch from '../../../gfx/button_search.png';
import buttonLeft from '../../../gfx/button_left_dark.png';
import FindBrand from "./FindBrand";

import icon_type_mc from '../../../gfx/icon-mc.png';
import icon_type_cykel from '../../../gfx/icon-cykel.png';
import icon_type_mc_off from '../../../gfx/icon-mc_off.png';
import icon_type_cykel_off from '../../../gfx/icon-cykel_off.png';
import StartNavigation from "../start/StartNavigation";

var Constants = require('../../Constants');

export default class FindBrandGrid extends React.Component{

    constructor(){
        super();
        this.state = {
            
            filteredList: [],
            mc:true,
            bike:true,
            search:"",
            currentLetter: ""
        }

        this.filter()
    }

    componentDidMount(){

        DataStore.on(Constants.START_MODEL_CHANGED, () => { 
            this.filter()
        })

        this.filter()

    }
    onSearchChange(event){

        const value = event.target.value
        this.setState({search: value}, this.filter);
    }

    filter(list){

        this.setState({currentLetter: ""});

        if (!list){
            list = DataStore.getStartModelBrandList();

            if (list == null){
                return;
            }

        }

        var filteredList = list.filter((company) => {
            


            if (!company){
                return false;
            }

            if ((this.state.bike && company.type === 2) || (this.state.mc && company.type === 1)){
               

            }else {
                return false;
            }


            if (this.state.search.length > 0){
                
                if (company.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) {
                    return true;
                }

               
                return false;
            }
            
    
            return true;
        });

        filteredList.sort( (a, b) => a.name > b.name)


        this.setState({filteredList})

        
    }

    onBikeClick(e){

        var newState = !this.state.bike

        if (newState == false && this.state.mc == false ){
            this.setState({mc: true}, this.filter)
        }

        this.setState({bike: newState}, this.filter)
    }
    
    onMCClick(e){
        var newState = !this.state.mc

        if (newState == false && this.state.bike == false ){
            this.setState({bike: true}, this.filter)
        }

        this.setState({mc: newState}, this.filter)
    }

    onClick(e){
        this.filter();
    }

    clearSearchField(){
        this.setState({search: ""}, this.filter)
    }

    onClickBrand(event){
        const brandId = event.target.title
        
        const element = document.getElementById("brand_" + brandId);
        if (element){ 
            element.scrollIntoView({behavior: "smooth"});
            //element.scrollTop -= 100;
        }

    }

    

    render(){

        //const imagePath = DataStore.getImageUrl() + this.props.category.image;

        var c = "";

        return (

                <div className='findBrandList'> 
                   
                   <div className='navigation'>
                   <StartNavigation />
                   </div>
                   <div className='findBrandHeader'>
                   
                   <h1>Våra varumärken</h1>
                   </div>
                   
                    <div className='searchView'>
                        <div className='searchField'>
                            <input type='text' className='searchBox' value={this.state.search} onChange={this.onSearchChange.bind(this)} placeholder='Sök varumärken'/>
                            <img  alt='img' src={this.state.search.length > 0 ? buttonClose : buttonSearch} onClick={this.clearSearchField.bind(this)}/>
                        </div>

                        <div className={this.state.mc ? "button" : "button off"} onClick={this.onMCClick.bind(this) }>
                            <img  alt='img' src={this.state.mc ? icon_type_mc : icon_type_mc_off } />
                            <h3  className={this.state.mc ? null : "off"}>MC</h3>
                        </div>

                        <div className={this.state.bike ? "button" : "button off"} onClick={this.onBikeClick.bind(this) }>
                        <img  alt='img' src={this.state.bike ? icon_type_cykel : icon_type_cykel_off } />
                            <h3>CYKEL</h3>
                        </div>

                        <div className='clearBoth'></div>




                    </div>
                    <div className='clearBoth'></div>
                   
                    <div className='findBrandNameResult'>
                  
                        {this.state.filteredList && this.state.filteredList.map( (brand, index) => {                            
                            const name = "#manufactor_" + brand.id;

                            return <div className='brandName' onClick={this.onClickBrand.bind(this)} title={brand.id}>{brand.name}</div>
                        
                        })}
                    <div className='clearBoth'></div>
                    </div>

                    <div className='findBrandResult'>
                   
                  
                        {this.state.filteredList && this.state.filteredList.map( (brand, index) => {

                           


                        return <FindBrand brand={brand}  />
                        
                        })}
                    
                    </div>

                </div>
                
           
        )
    }
}