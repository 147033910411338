import React from "react"

import DataStore from "../stores/DataStore"
import ProductBuyButton from "./ProductBuyButton";
import ProductStock from "./ProductStock";



var Constants = require('../Constants')

export default class ProductOptionRow extends React.Component {

	constructor() {
		super();
		//this.state = {isLoggedIn: DataStore.isLoggedIn()}

	}


	/*componentDidMount(){
			DataStore.on(Constants.LOGIN_STATUS_CHANGED, () => { this.setState({isLoggedIn: DataStore.isLoggedIn() })});
	}*/

	render() {

		const priceTitle = DataStore.getPriceTitle(this.props.productOptionRow);

		return (
			<tr className='productOptionRow' >

				<td align='left' valign='top'>
					{this.props.productOptionRow.artnr}
					{this.props.hasEan ? <b class='ean'><br />{this.props.productOptionRow.ean}</b> : ''}
				</td>

				{this.props.productOptionRow &&
					this.props.productOptionRow.productOptionValues &&
					this.props.productOptionRow.productOptionValues.map((item, index) => (<td valign='top'> {item}  </td>))}

				<td align='right' valign='top' title={priceTitle} className={this.props.product.outlet ? 'outlet' : ''} >{this.props.productOptionRow.pricef}</td>

				{this.props.isLoggedIn ? <td align='center' className='productStockCell' ><ProductStock product={this.props.productOptionRow} isLoggedIn={this.props.isLoggedIn} short={true} /></td> : null}
				{this.props.isLoggedIn ? <td align='center' className='productBuyButtonCell' ><ProductBuyButton productOptionSetId={this.props.productOptionRow.id} product={this.props.product} selectedProduct={true} /></td> : null}



			</tr>

		)
	}
}

