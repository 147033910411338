import React from "react"
import buttonSideBar from '../../gfx/button_sidebar.png';
import buttonSearch from '../../gfx/button_search.png';
import * as DataActions from "../actions/DataActions";
import DataStore from "../stores/DataStore";
import { NavLink, Link, withRouter } from 'react-router-dom'

import '../../css/Header.css';
import buttonList from '../../gfx/button_list.png';
import buttonReload from '../../gfx/button_reload.png';
import buttonOrder from '../../gfx/button_chart_circle.png';
import buttonUser from '../../gfx/button_user_circle.png';
import logo from '../../gfx/logo.png';
import Login from "./Login";
import ProductDetails from "./ProductDetails"
import ErrorMessage from "./ErrorMessage";
import Message from "./Message";
import FullsizeImage from "./FullsizeImage";

var Constants = require('../Constants')




class Header extends React.Component {

	constructor(props) {

		super(props);

		//const { productType } = useParams();



		var productType = 1

		//alert(this.props.sideMenuButton)
		//const { productType } = useParams();
		//alert(productType);

		if (this.props.productType == "mc") { productType = 1; }
		if (this.props.productType == "cykel") { productType = 2; }
		if (this.props.productType == "motul") { productType = 3; }

		if (this.props.productIdFromUrl != null && this.props.productIdFromUrl > 0) {
			DataActions.getProductFromUrl(this.props.productIdFromUrl, DataStore.getLoginToken());
			DataStore.setReloadProductId(this.props.productIdFromUrl); // FP: Sätter denna och anropas efter att man loggat in. 
		}

		//alert(productType)

		DataActions.selectProductType(productType) // FP: Skickar actions/states vid reload

		this.state = {
			productsLoaded: DataStore.isDataLoaded(),
			requestCount: DataStore.getRequestCount(),
			orderRequestCount: DataStore.getOrderRequestCount(),
			productType: productType,
			loginToken: DataStore.getLoginToken(),
			order: DataStore.getOrder(),
			orderRowCountLabel: DataStore.getOrderRowCountLabel(),
			loginVisibility: DataStore.isLoginVisible(),
			errorMessage: DataStore.getErrorMessage()
		}
		//alert(this.props.sideMenuButton)
		//const { productType } = useParams();
		//alert(productType);

	}

	styleTitle = {
		color: 'white',
		display: 'inline-block',
		height: '40px'

	};


	onClickMotul(event) {
		DataActions.selectProductType(3)
	}

	onClickCykel(event) {
		DataActions.selectProductType(2)
	}


	onClickMC(event) {
		DataActions.selectProductType(1)
	}

	onClick(e) {

		console.log("onClick header");
		//this.props.toggleSideMenu();
		DataActions.sideMenuToggle();
	}

	onLoginClick(e) {


		DataActions.loginVisibility(true);


	}

	onReloadclick(e) {

		if (DataStore.getStartModel() == null) {
			DataActions.getStartModel();
		}

		DataActions.getProductList(DataStore.getLoginToken());
		DataActions.getBaseModel();


	}

	hideProduct() {
		//DataActions.hideProduct();
	}

	onClickSearch(event) {
		//DataActions.selectProductType(2)
		//alert("sök")
		DataActions.searchBoxToggle();
	}

	componentDidMount() {

		DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => { this.setup(); })
		DataStore.on(Constants.REQUEST_COUNT_CHANGED, () => { this.setup(); })
		DataStore.on(Constants.PRODUCT_TYPE_CHANGED, () => { this.setup(); })
		DataStore.on(Constants.ORDER_CHANGED, () => { this.setup(); })
		DataStore.on(Constants.GET_ORDER_COMPLETE, () => { this.setup(); })
		DataStore.on(Constants.LOGIN_VISIBILITY_CHANGED, () => { this.setup(); })
		DataStore.on(Constants.UPDATE_URL, () => { this.updateUrl(); })
	}

	setup() {
		//alert((DataStore.isProductsLoaded()));
		this.setState({ productsLoaded: DataStore.isDataLoaded() });
		this.setState({ requestCount: DataStore.getRequestCount() })
		this.setState({ productType: DataStore.getProductType() })
		this.setState({ productTypeString: DataStore.getProductTypeString() });
		this.setState({ order: DataStore.getOrder() })
		this.setState({ orderRowCountLabel: DataStore.getOrderRowCountLabel() })
		this.setState({ loginVisibility: DataStore.isLoginVisible() })
		this.setState({ loginToken: DataStore.getLoginToken() })
		this.setState({ errorMessage: DataStore.getErrorMessage() })
		this.setState({ orderRequestCount: DataStore.getOrderRequestCount() })



	}
	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.productType != this.state.productType) {
			//this.setState({productType: DataStore.getProductType()})
			this.state.productType = DataStore.getProductType();
		}

		return true;
	}


	updateUrl() {
		//const manufactor = DataStore.getManufactor();

		//const url = "/" + this.state.productTypeString + "/" + (manufactor ? manufactor.key + "/" : "")
		//const url = "/" + new Date().toTimeString();

		var url = DataStore.getUrl();


		//this.props.history.push(url);
		console.log("FP: URL: UPDATE_URL", url);
		this.props.history.replace({ pathname: url })
	}


	render() {

		const list = "/" + this.state.productTypeString + "/products/list/"
		const grid = "/" + this.state.productTypeString + "/products/grid/"

		const viewType = this.props.viewType ? this.props.viewType : "list"

		const mc = "/mc/" + viewType
		const cykel = "/cykel/" + viewType
		const motul = "/motul/" + viewType




		return (
			<div className="header">

				{this.state.requestCount ? <div className='spinner'>HÄMTAR DATA...</div> : ""}

				<div className='navigation'>



					<div className='buttonSideBar'>
						{this.props.sideMenuButton && this.state.productsLoaded ? <img alt='img' src={buttonSideBar} onClick={this.onClick.bind(this)} /> : ""}
					</div>

					<Link to="/" className='logo' ><img alt='img' src={logo} /></Link>


					{this.state.productsLoaded && !this.props.hideMenu ?
						<div className='toolbarProductType'>
							<NavLink to={mc} onClick={this.onClickMC.bind(this)} className={this.state.productType == 1 ? "selected" : null} >MC</NavLink>&nbsp;
                    <NavLink to={cykel} onClick={this.onClickCykel.bind(this)} className={this.state.productType == 2 ? "selected" : null}>CYKEL</NavLink>
							<NavLink to={motul} onClick={this.onClickMotul.bind(this)} className={this.state.productType == 3 ? "selected" : null}>MOTUL</NavLink>
						</div>
						: <div className='toolbarProductType'></div>}
					{this.state.productsLoaded && this.state.loginToken ?

						<NavLink to="/order" onClick={this.hideProduct.bind(this)} ><div className={this.state.order && (this.state.order.orderStatus.status == 10 && this.state.orderRequestCount == 0) ? "headerOrder" : "headerOrder alert"}>
							<img className={this.state.orderRequestCount == 0 ? "buttonOrder" : "buttonOrder"} alt='img' src={buttonOrder} />
							{this.state.loginToken ? <h1>{this.state.order && this.state.order.orderStatus.status == 10 ? <div>{this.state.orderRequestCount == 0 ? this.state.orderRowCountLabel : "SPARAR"}</div> : "VÄLJ ORDER"} </h1> : null}</div>
						</NavLink> : ""}
					<div className='right'>


						{this.state.productsLoaded && this.state.loginToken == null ?
							<div className='headerLogin pointer' onClick={this.onLoginClick.bind(this)}><img className='buttonLogin' alt='img' src={buttonUser} /><h1>LOGIN </h1></div> : ""}

						{!this.state.productsLoaded && this.state.loginToken == null ?
							<div className='headerReload' onClick={this.onReloadclick.bind(this)}><img className='buttonReload' alt='img' src={buttonReload} /><h1>LADDA OM </h1></div> : ""}


					</div>


				</div>






				<Login visible={this.state.loginVisibility} />
				<ProductDetails pageType={this.props.pageType} />
				<ErrorMessage visible={this.state.errorMessage} />
				<Message />
				<FullsizeImage />
			</div>
		)
	}
}

export default withRouter(Header);
