export function themeA(){

    document.documentElement.style.setProperty('--color-bg', '#202020');
    document.documentElement.style.setProperty('--color-bg-main', '#3d3d3d');
    document.documentElement.style.setProperty('--color-bg-product-list', '#202020');
    document.documentElement.style.setProperty('--color-font-one-negative', '#ffffff');
    document.documentElement.style.setProperty('--color-font-two-negative', 'grey');
    document.documentElement.style.setProperty('--color-font-one', '#ffffff');
    document.documentElement.style.setProperty('--color-font-two', 'grey');

}

export function themeB(){
    document.documentElement.style.setProperty('--color-bg', '#e9e9e9');
    document.documentElement.style.setProperty('--color-bg-main', 'white');
    document.documentElement.style.setProperty('--color-bg-product-list', '#efeeee');
    document.documentElement.style.setProperty('--color-font-one-negative', 'white');
    document.documentElement.style.setProperty('--color-font-two-negative', '#6d6c6c');
    document.documentElement.style.setProperty('--color-font-one', '#2b2b2b');
    document.documentElement.style.setProperty('--color-font-two', '#6d6c6c');

}
