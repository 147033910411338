import React from "react"
import DataStore from "../../stores/DataStore";
import * as DataActions from "../../actions/DataActions";
import "../../../css/Start.css"
var Constants = require('../../Constants');

export default class StartBrand extends React.Component{

    constructor(){
        super();
        this.state = { }
    
    }

    onClick(e){
        this.props.onClickCallback(this.props.brand);
    }

    render(){
    
        const url = "https://public.boove.se/gfx/logos/" + this.props.brand.id + ".jpg"

        const css = {"background":"url(" + url + ") center center no-repeat white", "backgroundSize":"contain", "height":"100%"}
        return (

                <div className='startBrand'>
                    <div style={css} onClick={this.onClick.bind(this)}> </div>
                    
                </div>
                
           
        )
    }
}
