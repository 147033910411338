import React from "react"
import DataStore from "../stores/DataStore";
import * as DataActions from "../actions/DataActions";
import McListModell from "./McListModell";
import BikeType from "./BikeType";



var Constants = require('../Constants')

export default class BikeTypeList extends React.Component{

    
    constructor(){
        super();
        this.state = {bikeTypeList: DataStore.getBikeTypeList()}

    }
    

    componentDidMount(){
    
        DataStore.on(Constants.BIKE_TYPE_LIST_CHANGED, () => {
            
            this.setState({bikeTypeList: DataStore.getBikeTypeList()})
        })
    }

    render(){



        return (
            <div className='bikeTypeList'>
           
            { this.state.bikeTypeList.map((item, index) => ( <BikeType bikeType={item} key={item.id} /> )) }
           
        </div>

        )
    }
}
