import React from "react"
import DataStore from "../../stores/DataStore";
import * as DataActions from "../../actions/DataActions";
import "../../../css/Start.css"
import {NavLink, withRouter} from 'react-router-dom'


var Constants = require('../../Constants');

class StartNavigation extends React.Component{

    constructor(){
        super();
        this.state = { requestCount: DataStore.getRequestCount() }
    }



    /*productType = param => e => {
        DataActions.selectProductType(param)
        this.setState({productType: param});
    };*/

    componentDidMount(){    
        DataStore.on(Constants.REQUEST_COUNT_CHANGED, () => {
            this.setState({requestCount: DataStore.getRequestCount()})
        })
    }


    onMcClick(){
        DataActions.selectProductType(1);
        this.props.history.push("/mc/list/");
    }

    onCykelClick(){
        DataActions.selectProductType(2);
        this.props.history.push("/cykel/list/");
    }

    onMotulClick(){
        DataActions.selectProductType(3);
        this.props.history.push("/motul/list/");
    }

    render(){
    
        const css = this.state.requestCount == 0 ? "navigationItem" : "navigationItem loading";
        const loading = this.state.requestCount == 0 ;
        //const loading = true;
        

        return (

            <div className='navigationList'>
                    
            
            <div className={css} onClick={this.onMcClick.bind(this)}>{ loading ? <h3>MC</h3>: <h3>STARTAR...</h3>}</div> 
            <div className='space'>&nbsp;</div>
            <div className={css} onClick={this.onCykelClick.bind(this)}>{ loading ? <h3>CYKEL</h3>: <h3 className='loading'>VÄRMER UPP...</h3>}</div> 
            
            <div className='space'>&nbsp;</div>
            <div className={css} onClick={this.onMotulClick.bind(this)}>{ loading ? <h3>MOTUL</h3>: <h3 className='loading'>FYLLER PÅ...</h3>}</div> 
            
            <div className='space'>&nbsp;</div>
            <div className='navigationItem'><NavLink to='/butiker'><h3>BUTIKER</h3></NavLink></div> 
            <div className='space'>&nbsp;</div>
            <div className='navigationItem'><NavLink to='/varumarken'><h3>VARUMÄRKEN</h3></NavLink></div> 
            <div className='space'>&nbsp;</div>
            <div className='navigationItem'><a href='/#boove'><h3>OM BOOVE</h3></a></div> 
            
        </div>
           
        )
    }
}

export default withRouter(StartNavigation)
/*
<div className={css}><NavLink to='/mc/list'>{ loading ? <h3>MC</h3>: <h3>STARTAR...</h3>}</NavLink></div> 
<div className={css}><NavLink to='/cykel/list'>{ loading ? <h3>CYKEL</h3>:<h3>VÄRMER UPP...</h3>}</NavLink></div> 
<div className={css}><NavLink to='/motul/list'>{ loading ? <h3>MOTUL</h3>: <h3>FYLLER PÅ...</h3>}</NavLink></div> 


*/